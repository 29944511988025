import { useState, useRef, createContext, useEffect } from "react"

import './autocomplete.scss'

const AutocompleteContext = createContext()

export default function AutocompleteProvider({ children }) {

    const ref = useRef(null)

    const [ index, setIndex ] = useState(-1)
    const [ isOpen, setIsOpen ] = useState(false)

    useEffect(() => {
        document.body.addEventListener("click", handleBlur)
        return () => document.body.removeEventListener('click', handleBlur)
        // eslint-disable-next-line
    }, [])

    function handleBlur(e) {
        const element = e.target
        if (ref.current && ref.current.contains(element)) {
            e.preventDefault()
            e.stopPropagation()
        }
        else {
            setIsOpen(false)
        }
    }

    return (
        <AutocompleteContext.Provider        
            value={{
                index,
                setIndex: (index) => setIndex(index),
                isOpen,
                setIsOpen: (nextIsOpen) => {
                    if (nextIsOpen === undefined) return null
                    // if (nextIsOpen === false) return null
                    if (ref.current) return setIsOpen(nextIsOpen)
                    return null
                }
            }}
        >
            <div className='autocompleteref' ref={ref}>{ children }</div>
        </AutocompleteContext.Provider>
    )

}

export function Autocomplete({ children }) {
    return (
        <AutocompleteProvider>
            <AutocompleteContext.Consumer>
                { children }
            </AutocompleteContext.Consumer>
        </AutocompleteProvider>
    )
}
