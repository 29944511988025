import { useTranslation } from 'react-i18next'
import { Stream } from '@cloudflare/stream-react'
import './resources.scss'
import { useCompany } from 'hooks/company'
import { useAuthentication } from 'hooks/useAuthentication'

export default function Resources() {
    const { t, i18n } = useTranslation()

    const company = useCompany()

    const { user } = useAuthentication()

    const resources = {
        NAPA: {
            en: [
                [
                    t('Brochure'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA-2024-Registration-EN-20210928-ARTWORK_Brochure.pdf',
                ],
                [
                    t('Program details'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA/Program%20Details%202024%20-%2011-11-2021.pdf',
                ],
                [
                    t('terms', 'Terms & conditons'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Terms%20and%20Conditions%202022-2023.pdf',
                ],
                [
                    t('Exclusions'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Exclusions%20-%20NAPA%20Privilege%202024.pdf',
                ],
                [
                    t('Cancellation form'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Cancellation%20form-E_Assoc_Corp_2022-2023.pdf',
                ],
                [
                    t('Frequently asked questions'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA/FAQ%20-%2011-11-2021.pdf',
                ],
                [
                    t('NAPA AAG Notice of Modification Form'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA%20AAG%20Notice%20of%20Modification%20form.pdf',
                ],
            ],
            fr: [
                [
                    t('Brochure'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA-2024-Registration-FR-20210928-ARTWORK_Brochure.pdf',
                ],
                [
                    t('Program details'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Details%20du%20programme%202024%20-%2011092021.pdf',
                ],
                [
                    t('terms', 'Terms & conditons'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Modalites%20et%20conditions%202022-2023.pdf',
                ],
                [
                    t('Exclusions'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Exclusions%20-%20NAPA%20Privilege%202024%20FR.pdf',
                ],
                [
                    t('Cancellation form'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Demande%20d%27annulation-F_Assoc_Corp_2022-2023.pdf',
                ],
                [
                    t('Frequently asked questions'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA/FAQ-Foire%20aux%20questions%20-%2011-11-2021.pdf',
                ],
                [
                    t('NAPA AAG Avis De Modification'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA%20AAG%20Notice%20of%20Modification%20form.pdf',
                ],
            ],
        },
        AAG: {
            en: [
                [
                    t('Brochure'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA-2024-Registration-EN-20210928-ARTWORK_Brochure.pdf',
                ],
                [
                    t('Program details'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/AAG/AAG%20Program%20Details%202022-2023.pdf',
                ],
                [
                    t('terms', 'Terms & conditons'),
                    'https://pdf.napaapi.com/?path=https://aag-privilege.nyc3.digitaloceanspaces.com/assets/resources/AAG%20Terms%20and%20Conditions%202022-2023.pdf',
                ],
                [
                    t('Exclusions'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Exclusions%20-%20NAPA%20Privilege%202024.pdf',
                ],
                [
                    t('Cancellation form'),
                    'https://pdf.napaapi.com/?path=https://aag-privilege.nyc3.digitaloceanspaces.com/assets/resources/AAG%20Cancellation%20form-E_2022-2023.pdf',
                ],
                [
                    t('Frequently asked questions'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/AAG/AAG%20-%20FAQ%202022-2023.pdf',
                ],
                [
                    t('NAPA AAG Notice of Modification Form'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA%20AAG%20Notice%20of%20Modification%20form.pdf',
                ],
            ],
            fr: [
                [
                    t('Brochure'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA-2024-Registration-FR-20210928-ARTWORK_Brochure.pdf',
                ],
                [
                    t('Program details'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/AAG/AAG%20D%C3%A9tails%20du%20programme%202022-2023.pdf',
                ],
                [
                    t('terms', 'Terms & conditons'),
                    'https://pdf.napaapi.com/?path=https://aag-privilege.nyc3.digitaloceanspaces.com/assets/resources/AAG%20Modalit%C3%A9s%20et%20conditions%202022-2023.pdf',
                ],
                [
                    t('Exclusions'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Exclusions%20-%20NAPA%20Privil%C3%A8ge%202024.pdf',
                ],
                [
                    t('Cancellation form'),
                    'https://pdf.napaapi.com/?path=https://aag-privilege.nyc3.digitaloceanspaces.com/assets/resources/AAG%20Demande%20d%27annulation-F_2022-2023.pdf',
                ],
                [
                    t('Frequently asked questions'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/AAG/AAG%20-%20FAQ-Foire%20aux%20questions%202022-2023.pdf',
                ],
                [
                    t('NAPA AAG Avis De Modification'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/NAPA%20AAG%20Notice%20of%20Modification%20form.pdf',
                ],
            ],
        },
    }

    const userGuides = {
        NAPA: {
            en: 'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/userGuides/NAPA%20-User%20Guide%20-%20Privilege%202022-2023.pdf',
            fr: 'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/userGuides/NAPA%20-%20Guide%20de%20lutilisateur%20pour%20les%20magasins%20%20-%20Privilege%202022-2023.pdf',
        },
        AAG: {
            en: 'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/userGuides/AAG%20-User%20Guide%20-%20Privilege%202022-2023.pdf',
            fr: 'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/userGuides/AAG%20-%20Guide%20de%20lutilisateur%20pour%20les%20magasins%20%20-%20Privilege%202022-2023.pdf',
        },
    }

    const storeLevelResources = {
        NAPA: {
            en: [
                [
                    t('2024 NAPA Privilege Program'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/2024%20NAPA%20Privilege%20Program_Installer%20version_FINAL.pdf',
                ],
            ],
            fr: [
                [
                    t('Programme NAPA Privilège 2024'),
                    'https://pdf.napaapi.com/?path=https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/resources/2024/Programme%20NAPA%20Privilege%202024_Version%20installateurs_FINAL.pdf',
                ],
            ],
        },
    }

    return (
        <div className="contentContainer">
            <h2>{t('Resources')}</h2>

            <div className="resourceContainer">
                {resources[company][i18n.language].map((resource, i) => (
                    <a
                        key={i}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="resourceButton"
                        href={resource[1]}
                    >
                        <i className="material-icons">description</i>
                        <br />
                        {resource[0]}
                    </a>
                ))}
            </div>

            {user.userType === 'STORE' && (
                <div
                    className="resourceContainer"
                    style={{ marginTop: '2rem' }}
                >
                    {storeLevelResources[company][i18n.language].map(
                        (resource, i) => (
                            <a
                                key={i}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="resourceButton"
                                href={resource[1]}
                            >
                                <i className="material-icons">description</i>
                                {resource[0]}
                            </a>
                        ),
                    )}
                </div>
            )}

            <h2>{t('User guide')}</h2>
            <div className="resourceContainer">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="resourceButton"
                    href={userGuides[company][i18n.language]}
                >
                    <i className="material-icons">person</i> {t('User guide')}
                </a>
            </div>
            <h2>{t('2024 Privilege Passport Video')}</h2>
            <div
                style={{
                    borderRadius: '4px',
                    overflow: 'hidden',
                    boxShadow: '0 3px 6px rgba(0,0,0,0.2)',
                }}
            >
                <Stream
                    controls
                    src={
                        i18n.language === 'fr'
                            ? 'fbf8a89242d4a000b79359c6d1151d80'
                            : 'c270b919a01898da33ccd603ce460e93'
                    }
                />
            </div>
        </div>
    )
}
