import React from 'react'
import { useTranslation } from 'react-i18next';
import './unauthorized.css'
import napaLogoEN from '../../assets/napa-logo_en.png'
import napaLogoFR from '../../assets/napa-logo_fr.png'
import altromLogo from '../../assets/altrom-logo.png'
import { useCompany } from '../../hooks/company'

export default function Unauthorized({ children }) {

    const { i18n } = useTranslation()
    const company = useCompany()

    const logos = {
        "NAPA": {
            "en": napaLogoEN,
            "fr": napaLogoFR
        },
        "AAG": {
            "en": altromLogo,
            "fr": altromLogo
        }
    }

    const logo = logos[company][i18n.language]

    return(
        <div className='unauthorizedContainer'>
            <div className='unauthorizedContent'>
                <div className='unauthorizedContainerPadding'>
                    <div className='unauthorizedLogo'>
                        <img src={ logo } alt={`${ company === "NAPA" ? "NAPA" : "Altrom" } Privilege`} />
                    </div>
                    <div className='unauthorizedForm'>
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )

}