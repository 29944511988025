import gql from 'graphql-tag'

export const GET_ACCESS_LEVEL_STATS = gql`
    query GetAccessLevelStats($level: String!, $selector: ID) {
        privilege_accessLevelStats(level: $level, selector: $selector) {
            selector
            stats
        }
    }
`
