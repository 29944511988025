import React, { createContext, useContext, useState, useEffect } from 'react'
import { useMounted } from 'hooks/mounted'

const FormContext = createContext()

export default function Form({
    children,
    onChange,
    onSubmit,
    init = {},
    className,
    disabled,
    reinitialize,
}) {
    const isMounted = useMounted()

    const [isSubmitted, setSubmitted] = useState(false)
    const [shouldChange, setShouldChange] = useState(reinitialize)
    const [inputs, setInputs] = useState(init)

    useEffect(() => {
        if (
            isMounted &&
            JSON.stringify(reinitialize) !== JSON.stringify(shouldChange)
        ) {
            setInputs(init)
            setShouldChange(reinitialize)
        }
        // eslint-disable-next-line
    }, [reinitialize, init])

    useEffect(() => {
        if (onChange) {
            onChange({ inputs, isSubmitted })
        }
    })

    // useEffect(() => {
    //     console.log('INPUTS', inputs)
    // }, [inputs])

    useEffect(() => {
        setSubmitted(disabled)
    }, [disabled])

    function handleSetState(input, id) {
        if (id && (id.length > 0 || id > 0)) {
            setInputs((inputs) => ({
                ...inputs,
                [Object.keys(input)[0]]: {
                    ...inputs[Object.keys(input)[0]],
                    [id]: input[Object.keys(input)[0]],
                },
            }))
        } else {
            setInputs((inputs) => ({
                ...inputs,
                ...input,
            }))
        }
    }

    function handleUnsetInput(input, id) {
        // console.log('UNSET', input, id)
        setInputs((inputs) => {
            const newInputs = inputs
            try {
                if (input && id && newInputs[input]) delete newInputs[input][id]
                if (!id) delete newInputs[input]
                return {
                    ...newInputs,
                }
            } catch (err) {
                console.log(err, input, id, newInputs)
            }
        })
    }

    function handleFormReset() {
        setInputs(init)
    }

    function handleEnableSubmit() {
        setSubmitted(false)
    }

    function rawInputs() {
        const rawInputs = {}
        Object.keys(inputs).map((input) => {
            if (!inputs[input].hasOwnProperty('rawValue')) {
                const nextRawInputs = {}
                Object.keys(inputs[input]).map((inputKey) => {
                    return (nextRawInputs[inputKey] = inputs[input][inputKey]
                        ? inputs[input][inputKey].rawValue
                        : '')
                })
                return (rawInputs[input] = nextRawInputs)
            }
            return (rawInputs[input] = inputs[input]
                ? inputs[input].rawValue
                : '')
        })
        return rawInputs
    }

    // useEffect(() => {
    //     console.log('INPUTS',inputs)
    // }, [inputs])

    return (
        <FormContext.Provider
            value={{
                inputs,
                rawInputs: rawInputs(),
                setInput: (input, id) => handleSetState(input, id),
                unsetInput: (input, id) => handleUnsetInput(input, id),
                isSubmitted,
                resetForm: () => handleFormReset(),
            }}
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault()

                    setSubmitted(true)

                    if (onSubmit) {
                        onSubmit({
                            inputs,
                            rawInputs: rawInputs(),
                            isSubmitted,
                            resetForm: handleFormReset,
                            enableSubmit: handleEnableSubmit,
                        })
                    }
                }}
                className={className}
            >
                <FormContext.Consumer>{children}</FormContext.Consumer>
            </form>
        </FormContext.Provider>
    )
}

function useForm() {
    return useContext(FormContext)
}

export { useForm, FormContext }
