import React, { useState, useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import Card, { CardSection } from 'components/Card'
import { DateTime } from 'luxon'
import { FormatCurrency, FormatDate } from 'components/Format'
import PointsPooling, { PointsPoolingTable } from 'components/PointsPooling'
import Loader from 'components/Loader'
import { GET_INSTALLER } from 'operations/Installer'

import { useCompany } from 'hooks/company'

import { useQuery } from '@apollo/client'
import { GET_ACTIVITIES, GET_REDEMPTIONS } from 'operations/Activity'
import { PrivilegeBenefitLogo } from 'components/InstallerDashboard'
import { useCampaign } from 'hooks/campaign'
import currency from 'currency.js'
import NoData from 'components/NoData'

export default function Redemptions() {
    const { t, i18n } = useTranslation()

    const { error, loading, data } = useQuery(GET_REDEMPTIONS)

    if (loading) return <Loader />

    const redemptions = data?.installer?.privilege?.member?.redemptions

    const { balance } = data?.installer?.privilege?.member

    return (
        <div className="contentContainer">
            {/* TODO: Standardize this header area style + externalize shared CSS */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <h1>{t('headerRedemptions', 'Redemptions')}</h1>
                <div className="balanceAmount">
                    {t('balanceHeader', 'Balance:')}{' '}
                    <FormatCurrency>{balance}</FormatCurrency>
                </div>
            </div>
            {redemptions?.length < 1 && (
                <div>
                    <NoData>Sorry, no redemptions to display.</NoData>
                </div>
            )}
            {redemptions?.map((redemption, i) => {
                return (
                    <Card
                        key={i}
                        to={`/order/${redemption.orderNumber}`}
                        style={{ display: 'flex', gap: '4rem' }}
                    >
                        <CardSection title={t('orderNumber', 'Order number')}>
                            {redemption.orderNumber}
                        </CardSection>
                        <CardSection
                            title={t('amountRedeemed', `Amount redeemed`)}
                        >
                            <FormatCurrency>
                                {redemption.totalPoints}
                            </FormatCurrency>
                        </CardSection>
                        <CardSection title={t('itemsOrdered', `Items`)}>
                            {redemption.quantity}
                        </CardSection>
                        {/* <CardSection
                                title={t(
                                    'isCertificateRedemption',
                                    `Certificates`,
                                )}
                            >
                                {redemption.isCertificate ? 'TRUE' : 'FALSE'}
                            </CardSection> */}
                        <CardSection title={t('redeemedOn', 'Redeemed on')}>
                            {DateTime.fromISO(
                                redemption.enteredAt,
                            ).toLocaleString(DateTime.DATETIME_FULL)}
                        </CardSection>
                    </Card>
                )
            })}
        </div>
        // </div>
    )
}
