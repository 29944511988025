import { useQuery } from '@apollo/client'
import AccessLevelStats from 'components/AccessLevelStats'
import Card, { CardSection } from 'components/Card'
import Loader from 'components/Loader'
import { useAuthentication } from 'hooks/useAuthentication'
import { useGlobalState } from 'hooks/useGlobalState'
import { ME } from 'operations/Auth'
import { GET_STORES } from 'operations/Store'
import { MajorAccounts } from 'pages/MajorAccounts'
import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import Button from 'components/Button'
import { useCompany } from 'hooks/company'
import './stores.scss'

export default function Stores() {
    const { t } = useTranslation()

    const { dc } = useGlobalState()

    const { user } = useAuthentication()

    const { error, loading, data } = useQuery(ME)

    const { userPermissionGrants, username } = data && data.me ? data.me : []

    let queryVars = {}
    if (user.userType === 'SALES_REP') {
        const storeNavisionIds = []
        userPermissionGrants &&
            userPermissionGrants.map((grant) => {
                return storeNavisionIds.push(grant.username)
            })

        queryVars.filter = {
            navisionId: {
                in: storeNavisionIds,
            },
        }
    }
    if (
        ['AGENT', 'HEAD_OFFICE', 'TERRITORY_SALES_MANAGER'].includes(
            user.userType,
        )
    ) {
        queryVars.filter = {
            dcNum: {
                eq: dc.dcNum,
            },
        }
    }

    return (
        <div className="contentContainer">
            {(user.userType === 'DC' || dc) && (
                <AccessLevelStats
                    level="DC"
                    selector={dc ? dc.dcNum : data?.me?.approvedDcList}
                />
            )}
            <MajorAccounts />

            <h1>{t('My Stores')}</h1>

            {user.userType === 'SALES_REP' && (
                <>
                    {loading && <Loader />}
                    {error && error.message}
                    {!loading && userPermissionGrants.length < 1 && (
                        <Trans i18nKey="noStorePermissions">
                            You have not been granted access to any NAPA Store
                            accounts.
                            <br />
                            Please contact your NAPA Store to request access for
                            username:
                            <br />
                            <strong>{{ username }}</strong>
                        </Trans>
                    )}
                    {!loading && userPermissionGrants.length > 0 && (
                        <GetStores queryVars={queryVars} />
                    )}
                </>
            )}

            {(user.userType === 'DC' ||
                user.userType === 'BUSINESS_DEVELOPMENT_SPECIALIST') && (
                <GetAllowedDCList />
            )}

            {['AGENT', 'HEAD_OFFICE', 'TERRITORY_SALES_MANAGER'].includes(
                user.userType,
            ) && (
                <>
                    {loading && <Loader />}
                    {error && error.message}
                    {!loading && data && <GetStores queryVars={queryVars} />}
                </>
            )}
        </div>
    )
}

function GetAllowedDCList() {
    const { error, loading, data } = useQuery(ME)
    if (loading) return <Loader />
    if (error) return `Error! ${error.message}`

    const { username, approvedDcList } = data.me

    if (!approvedDcList || approvedDcList?.length < 1) {
        return (
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '2rem',
                    color: 'rgba(0,0,0,0.6)',
                    padding: '4rem 8rem',
                    border: '2px dashed rgba(0,0,0,0.1)',
                    borderRadius: '10px',
                    textAlign: 'center',
                    lineHeight: '3rem',
                }}
            >
                <Trans i18nKey="noDCPermissions">
                    You have not been granted access to any NAPA DCs.
                    <br />
                    <strong>{{ username }}</strong>
                </Trans>
            </div>
        )
    }

    return (
        <GetStores
            queryVars={{
                filter: { dcNum: { in: [...approvedDcList.split(',')] } },
            }}
        />
    )
}

function GetStores({ queryVars = {} }) {
    const { t } = useTranslation()

    const company = useCompany()

    const [offset, setOffset] = useState(0)
    const [limit] = useState(25)

    const [hasNext, setHasNext] = useState(false)

    const { setStore } = useGlobalState()

    const { error, loading, data, fetchMore } = useQuery(GET_STORES, {
        variables: {
            ...queryVars,
            offset,
            limit,
        },
    })

    useEffect(() => {
        if (offset > 0) {
            fetchMore({
                variables: {
                    ...queryVars,
                    offset,
                    limit,
                },
            })
        }
    }, [offset, limit, queryVars, fetchMore])

    useEffect(() => {
        if (data && data.stores) {
            const hasNext = data.stores.length % limit === 0
            setHasNext(hasNext)
        }
    }, [data, limit, setHasNext])

    if (error) return 'Error loading store list'
    if (loading) return <Loader />

    const { stores } = data

    return (
        <>
            {stores &&
                stores.map((store) => {
                    let totalEnrollments = 0
                    if (store.privilegeStats?.ALL)
                        totalEnrollments = store.privilegeStats.ALL
                    return (
                        <Card
                            key={store.id}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr 1fr .5fr',
                            }}
                            onClick={() => setStore(store)}
                        >
                            <CardSection
                                title={t('storeNumber', 'Store number')}
                            >
                                {store.napaNumber}
                            </CardSection>
                            <CardSection
                                title={t('storeBusinessName', 'Store name')}
                            >
                                {store.businessName}
                            </CardSection>
                            <CardSection title={t('storeLocation', 'Location')}>
                                {store.address}
                                <br />
                                {store.city}, {store.province} {store.postal}
                            </CardSection>
                            <CardSection
                                style={{ textAlign: 'right' }}
                                title={t(
                                    'totalEnrollments',
                                    'Total enrollments',
                                )}
                            >
                                {totalEnrollments}
                            </CardSection>
                        </Card>
                    )
                })}
            {hasNext && (
                <Button
                    context={company === 'NAPA' ? 'yellow' : 'blue'}
                    onClick={() => setOffset(offset + limit)}
                >
                    {t('storesLoadMore', 'Load more')}
                </Button>
            )}
        </>
    )
}
