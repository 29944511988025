import moment from "moment"
import { useTranslation } from 'react-i18next'

export default function Formatting({ format, children, number, currency, phone, decimalPlaces = 2 }) {
    const { i18n } = useTranslation()

    if (format === 'CURRENCY' || currency) return new Intl.NumberFormat('en', { style: 'currency', currency: 'USD' }).format(children).replace(/US/g, "")

    if (format === 'DATE_SHORT') return moment(children).locale(i18n.language).format('ll')

    if (format === 'PHONE' || phone) {
        var cleaned = ('' + children).replace(/\D/g, '')
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            return match[1] + '-' + match[2] + '-' + match[3]
        }
        return children
    }

    if (number) return new Intl.NumberFormat('en', { maximumFractionDigits: decimalPlaces }).format(children)

    if (!children) return null

    return children

}