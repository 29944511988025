import Card, { CardSection } from 'components/Card'
import { FormatCurrency } from 'components/Format'
import { useCart } from 'hooks/cart'
import '../Checkout/checkout.scss'
import currency from 'currency.js'
import { GET_INSTALLER } from 'operations/Installer'
import { useMutation, useQuery } from '@apollo/client'
import NoData from 'components/NoData'
import { Link, useParams, useLocation } from 'react-router-dom'
import certificateNapaEn from 'components/CertificateImage/certificate_napa_en.svg'
import VisaCard from 'components/VisaCard'
import { imageOverlay } from 'leaflet'
import { Img } from 'components/Tueri'
import { CHECKOUT, GET_ORDER } from 'operations/Activity'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Form, { Field, FormContext, Submit } from 'components/Form'
import validator from 'validator'
import ErrorMessage from 'components/ErrorMessage'
import * as phoneFns from 'phone-fns'
import Loader from 'components/Loader'
import './order.scss'

export default function Order() {
    const { t, i18n } = useTranslation()
    const { total: cartTotal, resetCart } = useCart()
    const { orderNumber } = useParams()
    const location = useLocation()

    const [error, setError] = useState()
    const [errors, setErrors] = useState()

    const [poll, setPoll] = useState(true)

    useEffect(() => {
        if (location?.search) {
            const searchParams = new URLSearchParams(location.search)
            const complete = Boolean(searchParams.get('complete'))
            if (complete) {
                resetCart()
            }
        }
        // eslint-disable-next-line
    }, [location])

    const {
        error: installerError,
        loading: installerLoading,
        data: installerData,
    } = useQuery(GET_INSTALLER)

    const { loading: orderLoading, data: orderData } = useQuery(GET_ORDER, {
        variables: {
            orderNumber,
            language: i18n.language,
            privilegeNavisionId:
                installerData?.installer?.privilege?.member
                    ?.privilegeNavisionId,
        },
        skip: !installerData?.installer?.privilege?.member?.privilegeNavisionId,
        pollInterval: poll ? 2500 : 0,
    })

    useEffect(() => {
        if (orderData?.privilege_order) {
            const shouldPoll = orderData.privilege_order?.some(
                (line) =>
                    line.certificateDownloadId && !line.certificateDownloadUrl,
            )
            if (!shouldPoll) setPoll(false)
        }
    }, [orderData])

    const balance = installerData?.installer?.privilege?.member?.balance
        ? currency(installerData?.installer?.privilege?.member?.balance).value
        : 0

    if (installerLoading || orderLoading) return <Loader />

    const asp = installerData?.installer
    // const member = asp?.privilege?.member
    // const store = asp?.store

    const subtotal = orderData?.privilege_order?.reduce(
        (total, current) =>
            (total += current.certificateDownloadId
                ? currency(current.pointsRedeemed).value
                : currency(current.pointsRedeemed).multiply(current.quantity)
                      .value),
        0,
    )
    const shippingHandling = currency(subtotal).multiply(0.06).value
    const total = currency(subtotal).multiply(1.06).value

    const email = validator.isEmail(asp.privilegeEmail)
        ? asp.privilegeEmail
        : asp.email

    return (
        <div className="contentContainer">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <h1>
                    {t('headerOrder', 'Order:')} #{orderNumber}
                </h1>
                <div className="balanceAmount">
                    {t('headerBalance', 'Balance:')}{' '}
                    <FormatCurrency>
                        {currency(balance).subtract(cartTotal).value}
                    </FormatCurrency>
                </div>
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '.5fr 1px 1fr',
                    gap: '4rem',
                }}
            >
                <div>
                    <h2>{t('shipTo', 'Send to')}</h2>
                    {/* <div>ATTN: {asp.ownerName}</div> */}
                    <div>
                        <strong>{asp.businessName}</strong>
                    </div>
                    <div>{asp.address}</div>
                    <div>
                        {asp.city}, {asp.province}
                    </div>
                    <div>{asp.postal}</div>
                    <div>
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>
                </div>
                <div style={{ width: '1px', background: '#eee' }} />
                <div>
                    {orderData?.privilege_order?.map((item, i) => {
                        return (
                            <div key={i} className="checkoutLine">
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '2rem',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            src={
                                                item?.product?.imageUrl.replace(
                                                    'https://napa-privilege.nyc3.digitaloceanspaces.com/',
                                                    'https://cdn.tueri.io/68719476737/napaprivilege/',
                                                ) + '?w=80'
                                            }
                                            alt={item?.product?.name}
                                            style={{
                                                boxShadow: `0 1px 1px 0 rgba(60, 64, 67, 0.08),
                                        0 1px 3px 1px rgba(60, 64, 67, 0.16)`,
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                            }}
                                        />
                                        <div className="checkoutQuantity">
                                            {item.quantity}
                                        </div>
                                    </div>
                                    <CardSection
                                        title={t('cardHeaderName', 'Name')}
                                    >
                                        <strong>{item?.product?.name}</strong>
                                        {item.certificateDownloadId ? (
                                            item.certificateDownloadUrl ? (
                                                <div>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={`https://pdf.napaapi.com?path=${item.certificateDownloadUrl.replace(
                                                            'nyc3.digitaloceanspaces.com/napa-privilege',
                                                            'https://napa-privilege.nyc3.cdn.digitaloceanspaces.com',
                                                        )}`}
                                                    >
                                                        {t(
                                                            'downloadCertificatesLink',
                                                            'Download certificates',
                                                        )}
                                                    </a>
                                                </div>
                                            ) : (
                                                <div className="generatingCertificateFlash">
                                                    {t(
                                                        'generatingCertificatesLoaderText',
                                                        'Generating certificates...',
                                                    )}
                                                </div>
                                            )
                                        ) : null}
                                    </CardSection>
                                </div>
                                <div style={{ display: 'flex', gap: '4rem' }}>
                                    <CardSection
                                        title={t('cardHeaderAmount', 'Amount')}
                                    >
                                        <FormatCurrency>
                                            {item.certificateDownloadId
                                                ? currency(
                                                      item.pointsRedeemed,
                                                  ).divide(item.quantity).value
                                                : item.pointsRedeemed}
                                        </FormatCurrency>
                                    </CardSection>
                                    <CardSection
                                        title={t('cardHeaderTotal', 'Total')}
                                    >
                                        <FormatCurrency>
                                            {item.certificateDownloadId
                                                ? item.pointsRedeemed
                                                : currency(
                                                      item.pointsRedeemed,
                                                  ).multiply(item.quantity)
                                                      .value}
                                        </FormatCurrency>
                                    </CardSection>
                                </div>
                            </div>
                        )
                    })}
                    <div className="checkoutTotalContainer">
                        <div className="checkoutTotalGroupContainer">
                            <div className="">{t('subtotal', 'Subtotal:')}</div>
                            <div className="">
                                {t('shippingHandling', 'Admin Fee @ 6%:')}
                            </div>
                            <div className="checkoutTotalTitle">
                                {t('total', 'Total:')}
                            </div>
                        </div>
                        <div className="checkoutTotalGroupContainer">
                            <div>
                                <FormatCurrency>{subtotal}</FormatCurrency>
                            </div>
                            <div>
                                <FormatCurrency>
                                    {shippingHandling}
                                </FormatCurrency>
                            </div>
                            <div className="checkoutTotalAmount">
                                <FormatCurrency>{total}</FormatCurrency>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
