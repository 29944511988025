import { useQuery } from '@apollo/client'
import { ME } from 'operations/Auth'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuthentication } from './useAuthentication'

const SalesRepContext = createContext()

export default function SalesRepProvider({ children }) {

    const [state, setState] = useState()

    const { user } = useAuthentication()

    const { data } = useQuery(ME, { skip: user.userType !== 'SALES_REP' })

    useEffect(() => {
        if (user.userType === 'SALES_REP' && data && data.me) {
            setState({
              ...(data.me.firstName && data.me.lastName && { fullName: data.me.firstName + ' ' + data.me.lastName }),
              email: data.me.username
            })
        }
    }, [data, user])

    return (
        <SalesRepContext.Provider
            value={[ state, setState ]}
        >
            { children }
        </SalesRepContext.Provider>
    )

}

export function useSalesRep() {
    return useContext(SalesRepContext)
}
