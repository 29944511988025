import React, { useState } from 'react'

import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { FormatCurrency, FormatDate } from 'components/Format'
import Button from 'components/Button'

import VisaCard from 'components/VisaCard'
import giftCardImage from '../Privilege/assets/toolcertificate-en-transparent.png'

import { useCompany } from 'hooks/company'

import { InstallerProgressBar as ProgressBar } from 'components/ProgressBar'
import Card from 'components/Card'
import TabSelector from 'components/TabSelector'

import './installerDashboard.scss'
import Loader from 'components/Loader'

import certificateNapaEn from 'components/CertificateImage/certificate_napa_en.svg'
import certificateNapaFr from 'components/CertificateImage/certificate_napa_fr.svg'
import certificateAltromEn from 'components/CertificateImage/certificate_altrom_en.svg'
import certificateAltromFr from 'components/CertificateImage/certificate_altrom_fr.svg'

import { Link } from 'react-router-dom'

import Portal from '@reach/portal'
import { useCampaign } from 'hooks/campaign'

const PASSPORT = 'PASSPORT'
const REWARDS = 'REWARDS'

const LOGOS = {
    privilege_rectangle: {
        en: 'PAP_NPA2019006_LogoPrivilege_HOR_RGB_EN.png',
        fr: 'PAP_NPA2019006_LogoPrivilege_HOR_RGB_FR.png',
    },
    privilege_square: {
        en: 'NPA_2019_006_Logo_NAPA-Privilege_RGB_EN.jpg',
        fr: 'NPA_2019_006_Logo_NAPA-Privilege_RGB_FR.jpg',
    },
    PASSPORT: {
        en: 'NPA_2019_006_Logo_NAPA-Passport_RGB.jpg',
        fr: 'NPA_2019_006_Logo_NAPA-Passeport_F_RGB.jpg',
    },
    REWARDS: {
        en: 'NPA_2019_006_Logo_NAPA-Rewards_RGB.jpg',
        fr: 'NPA_2019_006_Logo_NAPA-Recompense_RGB.jpg',
    },
}

const ALTROM_LOGOS = {
    PASSPORT: {
        en: 'altromPassport_en.png',
        fr: 'altromPassport_fr.png',
    },
    REWARDS: {
        en: 'altromRewards_en.png',
        fr: 'altromRewards_fr.png',
    },
}

const CERTIFICATE_IMAGES = {
    NAPA: {
        en: certificateNapaEn,
        fr: certificateNapaFr,
    },
    AAG: {
        en: certificateAltromEn,
        fr: certificateAltromFr,
    },
}

const LOGO_BASE_PATH =
    'https://cdn.tueri.io/68719476737/napaprivilege/assets/logo/'

export function PrivilegeBenefitLogo({ membership, type }) {
    const company = useCompany()

    let benefit
    if (membership.isTrip) benefit = PASSPORT
    if (membership.privilegeBenefit && !membership.isTrip) benefit = REWARDS

    const width = type === 'sm' ? 80 : 140

    const {
        i18n: { language },
    } = useTranslation()

    return (
        <div
            style={{
                width: `${width}px`,
                height: `${width}px`,
                // background: '#eee',
                // boxShadow: `0 1px 1px 0 rgba(60, 64, 67, 0.08),
                // 0 1px 3px 1px rgba(60, 64, 67, 0.16)`,
            }}
        >
            {benefit === PASSPORT && (
                <>
                    {company === 'NAPA' && (
                        <div
                            className={`sectionHeading__image ${
                                type === 'sm' ? 'sectionHeading__image-sm' : ''
                            }`}
                        >
                            <img
                                src={
                                    LOGO_BASE_PATH +
                                    LOGOS[PASSPORT][language] +
                                    '?w=' +
                                    width
                                }
                                alt="Privilege Passport"
                            />
                        </div>
                    )}
                    {company === 'AAG' && (
                        <div
                            className={`sectionHeading__image ${
                                type === 'sm' ? 'sectionHeading__image-sm' : ''
                            }`}
                        >
                            <img
                                src={
                                    LOGO_BASE_PATH +
                                    ALTROM_LOGOS[PASSPORT][language] +
                                    '?w=' +
                                    width
                                }
                                alt="Privilege Passport"
                            />
                        </div>
                    )}
                </>
            )}
            {benefit === REWARDS && (
                <>
                    {company === 'NAPA' && (
                        <div
                            className={`sectionHeading__image ${
                                type === 'sm' ? 'sectionHeading__image-sm' : ''
                            }`}
                        >
                            <img
                                src={
                                    LOGO_BASE_PATH +
                                    LOGOS[REWARDS][language] +
                                    '?w=' +
                                    width
                                }
                                alt="Privilege Rewards"
                            />
                        </div>
                    )}
                    {company === 'AAG' && (
                        <div
                            className={`sectionHeading__image ${
                                type === 'sm' ? 'sectionHeading__image-sm' : ''
                            }`}
                        >
                            <img
                                src={
                                    LOGO_BASE_PATH +
                                    ALTROM_LOGOS[REWARDS][language] +
                                    '?w=' +
                                    width
                                }
                                alt="Privilege Rewards"
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default function InstallerDashboard({ membership, noRedemption }) {
    const company = useCompany()
    const { deadline, monthsRemaining } = useCampaign()

    const { t, i18n } = useTranslation()

    const [modal, setModal] = useState()

    const [showDetails, setShowDetails] = useState(false)
    const [tripDetailsDestination, setTripDetailsDestination] = useState()

    const benefit = membership.isTrip ? PASSPORT : REWARDS

    // TODO: Populate this based on query
    // const [lastYearPurchases, setLastYearPurchases] = useState(140000)

    // const monthlyAverage = Number(lastYearPurchases) / 12

    // const EARNING_START = DateTime.fromObject(
    //     { year: 2021, month: 12, day: 1 },
    //     { zone: 'America/Toronto' },
    // )
    // const DEADLINE = DateTime.fromObject(
    //     { year: 2023, month: 11, day: 30, hour: 11, minute: 59, second: 59 },
    //     { zone: 'America/Toronto' },
    // )
    // const BACKDATE_DEADLINE = DateTime.fromObject(
    //     { year: 2022, month: 9, day: 30, hour: 11, minute: 59, second: 59 },
    //     { zone: 'America/Toronto' },
    // )
    // const CURRENT_TIME = DateTime.now()

    // const isAfterTenthDayOfMonth = CURRENT_TIME.day > 10

    const earningsStartDate = DateTime.fromMillis(
        membership.privilegeEarningStart || 0,
    )
    // CURRENT_TIME > BACKDATE_DEADLINE
    //     ? isAfterTenthDayOfMonth
    //         ? // Set to beginning of next month
    //           CURRENT_TIME.plus({ months: 1 }).startOf('month')
    //         : // Backdate to beginning of current month
    //           CURRENT_TIME.startOf('month')
    //     : EARNING_START

    const { months } = deadline.diff(earningsStartDate, 'months').toObject()

    const earningMonths = Math.ceil(months)

    // const monthsRemaining = monthsLeft > 24 ? 24 : Math.ceil(months)

    const elapsedMonths = Math.ceil(earningMonths - monthsRemaining)

    const targetEarnings =
        (membership.maxEarningCap / earningMonths) * elapsedMonths
    const stops = [targetEarnings, membership.maxEarningCap]

    // const passportEarnings = monthlyAverage * monthsRemaining * 0.03
    // const rewardsEarnings = (monthlyAverage * monthsRemaining * 0.01) / 1.06

    let earnings = 0
    if (!membership.isTrip) earnings = membership.balance ?? 0
    if (membership.isTrip) earnings = membership.totalEarnings ?? 0

    // const rewardsEarnings = earnings >= 106 ? earnings / 1.06 : 0

    // const certificateBookCount = Math.floor(
    //     parseFloat(rewardsEarnings).toFixed() / 100,
    // )

    // const certificateCount = Math.floor(
    //     parseFloat(rewardsEarnings).toFixed() / 10,
    // )
    // const bonusCertificateCount = Math.floor(certificateCount / 10)

    if (!membership?.privilegeBenefit) return <Loader />
    return (
        <div className="privilegeBenefit__container contentContainer">
            {/* {['INSTALLER', 'EMPLOYEE'].includes(user.userType) && (
                <Button
                    to="/earnings"
                    style={{
                        color: '#0d2a8a',
                        display: 'inline',
                        textDecoration: 'underline',
                        textAlign: 'left',
                        marginBottom: 0,
                        marginTop: '3.5rem',
                    }}
                >
                    {t('aspEarningsLink', 'View Earnings')}
                </Button>
            )} */}

            {benefit === PASSPORT && (
                <>
                    {/* {company === 'NAPA' && (
                        <div className="sectionHeading__image">
                            <img
                                src={
                                    LOGO_BASE_PATH +
                                    LOGOS[PASSPORT][language] +
                                    '?w=200'
                                }
                                alt="Privilege Passport"
                            />
                        </div>
                    )}
                    {company === 'AAG' && (
                        <div className="sectionHeading__image">
                            <img
                                src={
                                    LOGO_BASE_PATH +
                                    ALTROM_LOGOS[PASSPORT][language] +
                                    '?w=200'
                                }
                                alt="Privilege Passport"
                            />
                        </div>
                    )} */}
                    <div
                        className={`privilegeRewardsContainer ${
                            !membership.hasBothTrips
                                ? 'privilegeRewardsContainer--singleTrip'
                                : ''
                        }`}
                    >
                        <div
                            className={`privilegeTripImageContainer ${
                                membership.hasBothTrips
                                    ? 'privilegeTripImageContainer--bothTrips'
                                    : ''
                            }`}
                        >
                            <div className="privilegeCardOuterContainer">
                                <div className="privilegeCardContainer">
                                    <div className="privilegeCardImage">
                                        <img
                                            src={
                                                membership
                                                    .privilegeBenefitDetails
                                                    .imageUrl + '?w=600&h=350'
                                            }
                                            alt={
                                                membership
                                                    .privilegeBenefitDetails
                                                    .location
                                            }
                                        />
                                    </div>
                                    <div className="privilegeTripHeading">
                                        {
                                            membership.privilegeBenefitDetails[
                                                `location_${i18n.language}`
                                            ]
                                        }
                                    </div>
                                </div>
                                {membership.tripHasExtension && (
                                    <div className="tripExtensionOnImage">
                                        +
                                        {
                                            membership.extensionDetails[
                                                `extension_${i18n.language}`
                                            ]
                                        }
                                    </div>
                                )}
                            </div>
                            {membership.hasBothTrips && (
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <div
                                            style={{
                                                boxShadow:
                                                    '0 1px 1px 0 rgb(60 64 67 / 8%), 0 1px 3px 1px rgb(60 64 67 / 16%)',
                                                borderRadius: '9999px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <i className="material-icons">
                                                add
                                            </i>
                                        </div>
                                    </div>
                                    <div className="privilegeCardContainer">
                                        <div className="privilegeCardImage">
                                            <img
                                                src={
                                                    membership
                                                        .secondaryMemberships[0]
                                                        .privilegeBenefitDetails
                                                        .imageUrl +
                                                    '?w=600&h=350'
                                                }
                                                alt={
                                                    membership
                                                        .secondaryMemberships[0]
                                                        .privilegeBenefitDetails
                                                        .location
                                                }
                                            />
                                        </div>
                                        <div className="privilegeTripHeading">
                                            {
                                                membership
                                                    .secondaryMemberships[0]
                                                    .privilegeBenefitDetails[
                                                    `location_${i18n.language}`
                                                ]
                                            }
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div>
                            {benefit === PASSPORT && (
                                <>
                                    <ProgressBar
                                        trip={{ stops }}
                                        earnings={earnings}
                                        monthsRemaining={monthsRemaining}
                                        earningsStartDate={earningsStartDate}
                                    />

                                    <Button
                                        className="btn-toggle-details"
                                        onClick={() =>
                                            setShowDetails(!showDetails)
                                        }
                                    >
                                        {!showDetails
                                            ? t('detailsToggleShow', 'Show')
                                            : t(
                                                  'detailsToggleHide',
                                                  'Hide',
                                              )}{' '}
                                        {t('details', 'Details')}
                                        <i
                                            className={`material-icons btn-toggle-details--icon ${
                                                showDetails
                                                    ? 'rotate'
                                                    : 'no-rotate'
                                            }`}
                                        >
                                            keyboard_arrow_down
                                        </i>
                                    </Button>

                                    {showDetails && (
                                        <Card>
                                            {membership?.secondaryMemberships
                                                ?.length > 0 && (
                                                <TabSelector
                                                    tabs={[
                                                        [
                                                            'PRIMARY',
                                                            membership
                                                                .privilegeBenefitDetails[
                                                                `location_${i18n.language}`
                                                            ],
                                                        ],
                                                        [
                                                            'SECONDARY',
                                                            membership
                                                                .secondaryMemberships[0]
                                                                .privilegeBenefitDetails[
                                                                `location_${i18n.language}`
                                                            ],
                                                        ],
                                                    ]}
                                                    onClick={(e) =>
                                                        setTripDetailsDestination(
                                                            e,
                                                        )
                                                    }
                                                />
                                            )}
                                            <div className="tripDetails">
                                                <h3>
                                                    {t(
                                                        'travelDatesHeading',
                                                        'Travel dates:',
                                                    )}{' '}
                                                    {tripDetailsDestination ===
                                                    'SECONDARY'
                                                        ? membership
                                                              .secondaryMemberships[0]
                                                              .privilegeBenefitDetails[
                                                              `travelDates_${i18n.language}`
                                                          ]
                                                        : membership
                                                              .privilegeBenefitDetails[
                                                              `travelDates_${i18n.language}`
                                                          ]}
                                                </h3>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            tripDetailsDestination ===
                                                            'SECONDARY'
                                                                ? membership
                                                                      .secondaryMemberships[0]
                                                                      .privilegeBenefitDetails[
                                                                      `travelDetails_${i18n.language}`
                                                                  ]
                                                                : membership
                                                                      .privilegeBenefitDetails[
                                                                      `travelDetails_${i18n.language}`
                                                                  ],
                                                    }}
                                                />
                                            </div>
                                        </Card>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}

            {/* Gift card section */}
            {benefit === REWARDS && (
                <>
                    <div className="privilegeRewardsContainer">
                        <div className="privilegeRewardsImageContainer">
                            <div>
                                <VisaCard
                                    noMax
                                    // amount={parseFloat(rewardsEarnings).toFixed(2)}
                                    noAmount
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <div
                                    style={{
                                        boxShadow:
                                            '0 1px 1px 0 rgb(60 64 67 / 8%), 0 1px 3px 1px rgb(60 64 67 / 16%)',
                                        borderRadius: '9999px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '48px',
                                        width: '48px',
                                        fontSize: '2rem',
                                    }}
                                >
                                    {t('or', 'or')}
                                </div>
                            </div>
                            <div className="certificateContainer">
                                {[
                                    // 10,
                                    // 9,
                                    // 8,
                                    // 7,
                                    // 6,
                                    // 5,
                                    4, 3, 2, 1, 0,
                                ].map((num, i) => (
                                    <div
                                        key={i}
                                        className="certificate"
                                        style={{
                                            position: 'absolute',
                                            top: `${2 * i}px`,
                                            left: `${2 * i}px`,
                                            width: '100%',
                                            height: '100%',
                                            zIndex: num,
                                        }}
                                    />
                                ))}
                                <div
                                    // className="visaCard"
                                    className="certificate"
                                    style={{
                                        position: 'relative',
                                        zIndex: 10,
                                    }}
                                >
                                    {/* <CertificateImage
                                    amount={certificateBookCount * 110}
                                /> */}
                                    <img
                                        style={{
                                            width: '100%',
                                        }}
                                        src={
                                            CERTIFICATE_IMAGES[company][
                                                i18n.language
                                            ]
                                        }
                                        alt="Certificate"
                                    />
                                </div>
                                {/* <div className="quantity">
                                x{certificateBookCount}
                            </div> */}
                            </div>
                        </div>
                        {!noRedemption && (
                            <div
                                className="privilegeRewardsImageContainer"
                                style={{ marginTop: '3rem' }}
                            >
                                <div style={{ textAlign: 'center' }}>
                                    <Link
                                        to="/reward/visa-giftcard"
                                        // type="button"
                                        className="orderButton"
                                        // onClick={() => setModal('GIFT_CARDS')}
                                    >
                                        {t(
                                            'rewardsButtonOrderGC',
                                            'Order gift cards',
                                        )}
                                    </Link>
                                </div>
                                <div />
                                <div style={{ textAlign: 'center' }}>
                                    {/* {certificateCount} Certificates +{' '}
                            {bonusCertificateCount} Bonus Certificates */}
                                    {/* <FormatCurrency>
                                {certificateBookCount * 110}
                            </FormatCurrency>{' '}
                            VALUE* */}
                                    <Link
                                        to={`/reward/toolcertificate`}
                                        // type="button"
                                        className="orderButton"
                                        // onClick={() => setModal('CERTIFICATES')}
                                    >
                                        {t(
                                            'rewardsButtonOrderCertificates',
                                            'Order certificates',
                                        )}
                                    </Link>
                                </div>
                            </div>
                        )}
                        {/* <div
                            style={{
                                marginTop: '4rem',
                                background: '#eee',
                                borderRadius: '4px',
                                padding: '2rem',
                                fontStyle: 'italic',
                            }}
                        >
                            * Visa gift cards and/or NAPA Tools & Equipment
                            certificates will be subject to a 6%
                            handling/shipping charge. Installer must earn the
                            minimum qualifying amount of $106 to be eligible for
                            redemption.
                        </div> */}
                    </div>
                    {modal && (
                        <OrderForm
                            type={modal}
                            onClose={() => setModal('CLOSED')}
                        />
                    )}
                </>
            )}
        </div>
    )
}

function OrderForm({ type, onClose }) {
    return (
        <Portal>
            <div
                style={{
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(255,255,255,0.8)',
                    zIndex: 20,
                }}
            ></div>
        </Portal>
    )
}
