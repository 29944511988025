import gql from 'graphql-tag'

export const GET_TRIPS = gql`
    query Trips {
        trips: privilege_trips {
            id
            location
            location_en
            location_fr
            slug
            primaryTrip
            extensions {
                id
                extension
                extension_en
                extension_fr
            }
        }
    }
`

export const GET_DEPARTURE_CITIES = gql`
    query DepartureCities {
        privilege_departureCities {
            id
            navisionId
            city_en
            city_fr
            airportCode
            airportName_en
            airportName_fr
            latitude
            longitude
        }
    }
`

export const GET_DEPARTURE_CITIES_DISTANCE = gql`
    query DepartureCitiesDistance($latitude: Float, $longitude: Float) {
        privilege_departureCitiesDistance(
            latitude: $latitude
            longitude: $longitude
        ) {
            id
            airportCode
            distance
            distanceUnit
            isClosest
        }
    }
`
