import Button from '../../components/Button'
import { useCompany } from 'hooks/company'
import { useTranslation } from 'react-i18next'
import PreviousInstallers from './PreviousInstallers'
import CurrentInstallers from './CurrentInstallers'

import './members.scss'
import { useState } from 'react'
import { useAuthentication } from 'hooks/useAuthentication'
import AccessLevelStats from 'components/AccessLevelStats'
import { useGlobalState } from 'hooks/useGlobalState'
import { useMajorAccount } from 'hooks/majorAccount'

export default function Installers({ history }) {
    const company = useCompany()

    const { store, bds } = useGlobalState()
    const { majorAccount } = useMajorAccount()

    const { user } = useAuthentication()

    const [tab, setTab] = useState(
        () =>
            // company === 'AAG' ? 'CURRENT' : 'PAST',
            'CURRENT',
    )

    const { t } = useTranslation()

    return (
        <div className="contentContainer">
            {store && (
                <AccessLevelStats level="STORE" selector={store.navisionId} />
            )}
            {user.userType === 'STORE' && (
                <AccessLevelStats level="STORE" selector={user.navisionId} />
            )}
            {(user.userType === 'BUSINESS_DEVELOPMENT_SPECIALIST' || bds) && (
                <AccessLevelStats
                    level="BUSINESS_DEVELOPMENT_SPECIALIST"
                    selector={bds ? bds.id : user.linkId}
                />
            )}
            {/* {majorAccount && (
                <AccessLevelStats
                    level="MAJOR_ACCOUNT"
                    selector={majorAccount.account}
                />
            )} */}

            <h1>{t('aspsListHeading', 'My Customers')}</h1>
            {/* {['SALES_REP', 'STORE'].includes(user.userType) && !majorAccount && (
                <Button
                    to="/register"
                    context={company === 'NAPA' ? 'yellow' : 'blue'}
                >
                    {t('Enroll an ASP')}
                </Button>
            )} */}

            <div style={{ display: 'flex', gap: '2rem', marginBottom: '2rem' }}>
                {company === 'NAPA' && (
                    <button
                        type="button"
                        className={`tabButton ${
                            tab && tab === 'PAST' ? 'tabButton--selected' : ''
                        }`}
                        onClick={() => setTab('PAST')}
                    >
                        {t('2020/2021 Members')}
                    </button>
                )}
                {!['DIRECTORs', 'BUSINESS_DEVELOPMENT_SPECIALISTs'].includes(
                    user.userType,
                ) && (
                    <button
                        type="button"
                        className={`tabButton ${
                            tab && tab === 'CURRENT'
                                ? 'tabButton--selected'
                                : ''
                        }`}
                        onClick={() => setTab('CURRENT')}
                    >
                        {t('2022/2023 Members')}
                    </button>
                )}
            </div>
            {tab === 'PAST' && <PreviousInstallers />}
            {tab === 'CURRENT' && <CurrentInstallers />}
        </div>
    )
}
