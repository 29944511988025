import Card, { CardSection } from 'components/Card'
import { FormatCurrency } from 'components/Format'
import { useCart } from 'hooks/cart'
import './cart.scss'
import currency from 'currency.js'
import { GET_INSTALLER } from 'operations/Installer'
import { useQuery } from '@apollo/client'
import NoData from 'components/NoData'
import { Link } from 'react-router-dom'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'

export default function Cart() {
    const { t, i18n } = useTranslation()

    const { cart, total, subtotal, shippingHandling, removeFromCart } =
        useCart()

    const { data: installerData } = useQuery(GET_INSTALLER)

    const balance = installerData?.installer?.privilege?.member?.balance
        ? currency(installerData?.installer?.privilege?.member?.balance).value
        : 0

    return (
        <div className="contentContainer">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <h1>{t('headerCart', 'Cart')}</h1>
                <div className="balanceAmount">
                    {t('headerBalance', 'Balance:')}{' '}
                    <FormatCurrency>
                        {currency(balance).subtract(total).value}
                    </FormatCurrency>
                </div>
            </div>
            <div>
                {cart.length === 0 && (
                    <NoData>
                        <div>
                            {t(
                                'noCartItems',
                                "Sorry, it doesn't look like you have added anything to your cart.",
                            )}
                        </div>
                        <div>
                            <Link to="/">
                                {t(
                                    'noCartItemsLink',
                                    `Let's find something to add your cart.`,
                                )}
                            </Link>
                        </div>
                    </NoData>
                )}
                {cart?.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className="checkoutLine"
                            style={{
                                display: 'grid',
                                gap: '2rem',
                                gridTemplateColumns: '2fr 1fr 1fr 1fr 60px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '2rem',
                                    alignItems: 'center',
                                }}
                            >
                                <div style={{ position: 'relative' }}>
                                    <img
                                        src={
                                            item.imageUrl.replace(
                                                'https://napa-privilege.nyc3.digitaloceanspaces.com/',
                                                'https://cdn.tueri.io/68719476737/napaprivilege/',
                                            ) + '?w=80'
                                        }
                                        alt={item.name}
                                        style={{
                                            boxShadow: `0 1px 1px 0 rgba(60, 64, 67, 0.08),
                                        0 1px 3px 1px rgba(60, 64, 67, 0.16)`,
                                            borderRadius: '4px',
                                            overflow: 'hidden',
                                        }}
                                    />
                                    <div className="checkoutQuantity">
                                        {item.quantity}
                                    </div>
                                </div>
                                <div>
                                    <CardSection
                                        title={t('cardHeaderName', 'Name')}
                                    >
                                        <strong>{item.name}</strong>
                                    </CardSection>
                                </div>
                            </div>
                            <CardSection
                                title={t('cardHeaderAmount', 'Amount')}
                                style={{ textAlign: 'right' }}
                            >
                                <FormatCurrency>{item.amount}</FormatCurrency>
                            </CardSection>
                            <CardSection
                                title={t('cardHeaderQuantity', 'Quantity')}
                                style={{ textAlign: 'right' }}
                            >
                                {item.quantity}
                            </CardSection>
                            <CardSection
                                title={t('cardHeaderTotal', 'Total')}
                                style={{ textAlign: 'right' }}
                            >
                                <FormatCurrency>
                                    {currency(item.amount).multiply(
                                        item.quantity,
                                    )}
                                </FormatCurrency>
                            </CardSection>
                            <button
                                type="button"
                                onClick={() => removeFromCart(i)}
                                className="cartRemoveButton"
                            >
                                <i className="material-icons">delete</i>
                            </button>
                        </div>
                    )
                })}
                <div className="checkoutTotalContainer">
                    <div className="checkoutTotalGroupContainer">
                        <div className="">{t('subtotal', 'Subtotal:')}</div>
                        <div className="">
                            {t('shippingHandling', 'Shipping & Handling @ 6%:')}
                        </div>
                        <div className="checkoutTotalTitle">
                            {t('total', 'Total:')}
                        </div>
                    </div>
                    <div className="checkoutTotalGroupContainer">
                        <div>
                            <FormatCurrency>{subtotal}</FormatCurrency>
                        </div>
                        <div>
                            <FormatCurrency>{shippingHandling}</FormatCurrency>
                        </div>
                        <div className="checkoutTotalAmount">
                            <FormatCurrency>{total}</FormatCurrency>
                        </div>
                    </div>
                </div>
                {subtotal >= 100 ? (
                    <Button
                        to="/checkout"
                        context="primary"
                        style={{ display: 'inline-block' }}
                    >
                        {t('cartButtonCheckout', 'Checkout')}
                    </Button>
                ) : (
                    <div
                        style={{
                            textAlign: 'center',
                            margin: '2rem',
                            color: 'red',
                        }}
                    >
                        {i18n.language.substring(0, 2) === 'en' ? (
                            <em>
                                You need a minimum <strong>subtotal</strong> of
                                $100 in your cart to redeem.
                            </em>
                        ) : (
                            <em>
                                Vous avez besoin d'un{' '}
                                <strong>sous-total</strong> minimum de 100$ dans
                                votre panier pour échanger
                            </em>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
