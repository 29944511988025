import React, { createContext, useContext } from 'react'
import { Helmet } from 'react-helmet'

const CompanyContext = createContext()

export const COMPANIES = {
    'napaprivilege.com': 'NAPA',
    '2022.napaprivilege.com': 'NAPA',
    'aagprivilege.com': 'AAG',
    '2022.aagprivilege.com': 'AAG',
}

const HEAD = {
    NAPA: {
        title: 'NAPA Privilege',
    },
    AAG: {
        title: 'AAG Privilege',
    },
}

const companyOverride = 'NAPA'

export default function CompanyProvider({ children }) {
    const company =
        process.env.NODE_ENV === 'production'
            ? COMPANIES[window.location.hostname]
            : companyOverride
            ? companyOverride
            : process.env.REACT_APP_COMPANY

    return (
        <CompanyContext.Provider value={company}>
            <Helmet>
                <title>{HEAD[company].title}</title>
                <link
                    rel="icon"
                    type="image/png"
                    href={
                        company === 'AAG'
                            ? '/assets/favicon_aag.png'
                            : '/assets/favicon_napa.png'
                    }
                />
            </Helmet>
            <div className={`companyContainer company--${company}`}>
                {children}
            </div>
        </CompanyContext.Provider>
    )
}

export function useCompany() {
    return useContext(CompanyContext)
}
