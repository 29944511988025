import React, { useState, useEffect } from 'react'

import validator from 'validator'
// import { getProvinces, getLanguages } from './functions'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'

import {
    GET_PREVIOUS_ACCOUNTS_RECEIVABLE,
    GET_TEMP_MEMBERS,
} from 'operations/Members'
// import { GET_INSTALLERS } from 'operations/Installer'
import { GET_STORE } from 'operations/Store'
// NOTE: The immediately following imports are from newer structure copied from AP consumer rebates site
import Installer from 'components/Installer'
import Form, { Field, Submit } from 'components/Form'
// import { Autocomplete } from "components/Form/Autocomplete"

// import RegistrationLock from './RegistrationLock'

// import { v4 as uuid } from 'uuid'
import { useAuthentication } from '../../hooks/useAuthentication'
// import RegistrationLockProvider from 'hooks/registrationLock'
import HR from 'components/HR'

// import { useCompany } from 'hooks/company'

import './register.scss'
import { CREATE_STAGING_RECORD, GET_STAGING_RECORD } from 'operations/Staging'
// import Loader from 'components/Loader'
import {
    GET_DEPARTURE_CITIES,
    GET_DEPARTURE_CITIES_DISTANCE,
    GET_TRIPS,
} from 'operations/Trip'
import Map from './Map'
import Formatting from 'components/Formatting'
import { useHistory, useParams } from 'react-router'
// import { useSalesRep } from 'hooks/salesRep'
// import SalesRep from 'components/SalesRep'
// import { useCompany } from 'hooks/company'

// import Loader from '../../components/Loader'

// import { IS_ASP_REGISTERED } from '../../operations/Registrations'

// import Form, { FormContext, Select, Input, Submit } from '../../components/Forms'

export default function Register({ onComplete }) {
    // const company = useCompany()

    const [preloadAsp, setPreloadAsp] = useState(false)

    const { t, i18n } = useTranslation()
    const { user } = useAuthentication()
    // const { userType } = user

    // const [ salesRep, setSalesRep ] = useSalesRep()

    const history = useHistory()
    const { aspId } = useParams()

    // const { company } = useCompany()

    // const [ ossNumber, setOssNumber ] = useState('')
    // const [ ossName, setOssName ] = useState('')
    // const [ ossTitle, setOssTitle ] = useState('')
    // const [ ossEmail, setOssEmail ] = useState('')

    const { data: privilegeTrips } = useQuery(GET_TRIPS)

    const {
        // error: storeError,
        // loading: storeLoading,
        data: storeData,
    } = useQuery(GET_STORE, {
        variables: {
            filter: {
                ...(user.userType === 'STORE' && {
                    navisionId: { eq: user.navisionId },
                }),
            },
        },
    })

    const [errors, setErrors] = useState({})
    const {
        // loading: aspIdLoading,
        data: aspIdData,
    } = useQuery(GET_TEMP_MEMBERS, {
        fetchPolicy: 'network-only',
        skip: !aspId,
        variables: { search: `@id:{${aspId}}` },
    })
    // const [ aspSearch, { loading: aspLoading, data: aspData } ] = useLazyQuery(GET_TEMP_MEMBERS, { fetchPolicy: 'network-only' })
    const [asp, setAsp] = useState()
    const [
        // aspNotFound,
        setAspNotFound,
    ] = useState()
    const [selectedProgram, setSelectedProgram] = useState()

    // const [ registrationLocked, setRegistrationLocked ] = useState(false)

    // points pooling installers query (to be modified)
    // const [ poolPoints, setPoolPoints ] = useState('NOT_SET')
    // const [ pointsPoolingAspSearch, { loading: pointsPoolingAspLoading, data: pointsPoolingAspData } ] = useLazyQuery(GET_TEMP_MEMBERS, { fetchPolicy: 'network-only' })
    // const [ pointsPoolingAsp, setPointsPoolingAsp ] = useState()
    // const [ pointsPoolingAspNotFound, setPointsPoolingAspNotFound ] = useState()
    // const [ ignorePreviousPointsPooling, setIgnorePreviousPointsPooling ] = useState()

    // const [ storeSearch, { loading: storeSearchLoading, data: storeSearchData } ] = useLazyQuery(STORE_SEARCH, { fetchPolicy: 'network-only' })
    const [
        // customerAccounts,
        setCustomerAccounts,
    ] = useState([])

    const {
        // error: departureCitiesError,
        // loading: departureCitiesLoading,
        data: departureCitiesData,
    } = useQuery(GET_DEPARTURE_CITIES)
    const {
        // error: departureCitiesDistanceError,
        // loading: departureCitiesDistanceLoading,
        data: departureCitiesDistanceData,
    } = useQuery(GET_DEPARTURE_CITIES_DISTANCE, {
        skip: !asp || (asp && (!asp.latitude || !asp.longitude)),
        variables: {
            latitude: asp && asp.latitude,
            longitude: asp && asp.longitude,
        },
    })

    const {
        // error: previousAccountsReceivableError,
        // loading: previousAccountsReceivableLoading,
        data: previousAccountsReceivableData,
    } = useQuery(GET_PREVIOUS_ACCOUNTS_RECEIVABLE, {
        variables: {
            id: asp && asp.id,
        },
        skip: !asp,
    })

    const [departure, setDeparture] = useState()

    const [createStagingRecord] = useMutation(CREATE_STAGING_RECORD, {
        refetchQueries: [
            { query: GET_STAGING_RECORD, variables: { id: 'store' } },
        ],
    })

    // Preload ASP (from re-enroll)
    useEffect(() => {
        if (
            !preloadAsp &&
            aspId &&
            aspIdData &&
            aspIdData.privilege_tempMemberSearch &&
            aspIdData.privilege_tempMemberSearch.length === 1
        ) {
            setAsp(aspIdData.privilege_tempMemberSearch[0])
            setPreloadAsp(true)
        }
    }, [aspId, aspIdData, preloadAsp])

    // AR Numbers
    useEffect(() => {
        if (storeData && previousAccountsReceivableData) {
            // NO previous AR #'s or the previous primary store is not the same as entering
            if (
                previousAccountsReceivableData
                    .privilege_previousAccountsReceivable.length < 1 ||
                (previousAccountsReceivableData
                    .privilege_previousAccountsReceivable.length > 0 &&
                    storeData.store.id !==
                        previousAccountsReceivableData
                            .privilege_previousAccountsReceivable[0].id)
            ) {
                const { __typename, ...rest } = storeData.store
                setCustomerAccounts([{ ...rest, locked: true }])
            }
            // Previous AR #'s exist and the primary is the same as entering
            if (
                previousAccountsReceivableData
                    .privilege_previousAccountsReceivable.length > 0 &&
                storeData.store.id ===
                    previousAccountsReceivableData
                        .privilege_previousAccountsReceivable[0].id
            ) {
                const customerAccounts = []
                previousAccountsReceivableData.privilege_previousAccountsReceivable.map(
                    ({ __typename, ...rest }, i) =>
                        customerAccounts.push({
                            ...rest,
                            locked: i === 0 ? true : false,
                        }),
                )
                setCustomerAccounts(customerAccounts)
            }
        }
    }, [storeData, setCustomerAccounts, previousAccountsReceivableData])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [errors])
    // const provinces = getProvinces(i18n.language)
    // const languages = getLanguages(i18n.language)

    const trips =
        privilegeTrips && privilegeTrips.trips
            ? privilegeTrips.trips.map((trip) => [
                  trip.slug,
                  t('passport', 'Passport:') +
                      ' ' +
                      trip[`location_${i18n.language}`],
              ])
            : []

    let tripSlugs = []
    privilegeTrips &&
        privilegeTrips.trips &&
        privilegeTrips.trips.map((trip) => tripSlugs.push(trip.slug))
    if (tripSlugs.length > 0)
        trips.push([
            tripSlugs.join(','),
            t('passportBoth', 'Passport: BOTH TRIPS'),
        ])

    const departureCities =
        departureCitiesData &&
        departureCitiesData.privilege_departureCities &&
        departureCitiesData.privilege_departureCities.map((departureCity) => [
            departureCity.navisionId,
            departureCity[`city_${i18n.language}`],
        ])

    const departureDistance =
        departure &&
        departureCitiesDistanceData &&
        departureCitiesDistanceData.privilege_departureCitiesDistance
            ? departureCitiesDistanceData.privilege_departureCitiesDistance.find(
                  (departureCity) =>
                      departureCity.airportCode === departure.airportCode,
              )
            : null

    return <div>Enrollments are closed</div>

    return (
        <div className="contentContainer">
            <Form
                // className='grid'
                style={{ paddingTop: '2rem' }}
                onSubmit={async ({ rawInputs: inputs, enableSubmit }) => {
                    try {
                        const formErrors = {}

                        if (
                            !inputs.language ||
                            validator.isEmpty(inputs.language)
                        )
                            formErrors.language = true
                        if (
                            !inputs.ownerName ||
                            validator.isEmpty(inputs.ownerName)
                        )
                            formErrors.ownerName = true
                        if (
                            !inputs.email ||
                            validator.isEmpty(inputs.email) ||
                            !validator.isEmail(inputs.email)
                        )
                            formErrors.email = true
                        if (
                            inputs.cellPhone &&
                            !validator.isEmpty(inputs.cellPhone) &&
                            !validator.isMobilePhone(inputs.cellPhone)
                        )
                            formErrors.cellPhone = true

                        // Privilege membership benefit
                        if (
                            !inputs.program ||
                            validator.isEmpty(inputs.program)
                        )
                            formErrors.program = true
                        if (
                            inputs.program &&
                            !validator.isEmpty(inputs.program)
                        ) {
                            // If TRIP
                            if (selectedProgram === 'TRIP') {
                                const totalTravellers19Plus =
                                    inputs.totalTravellers19Plus.trim()
                                if (
                                    !validator.isNumeric(
                                        totalTravellers19Plus,
                                    ) ||
                                    Number(totalTravellers19Plus || 0) < 1 ||
                                    Number(totalTravellers19Plus || 0) > 50
                                )
                                    formErrors.totalTravellers19Plus = true
                                const totalTravellersUnder19 =
                                    inputs.totalTravellersUnder19.trim()
                                if (
                                    !validator.isNumeric(
                                        totalTravellersUnder19,
                                    ) ||
                                    Number(totalTravellersUnder19 || 0) > 50
                                )
                                    formErrors.totalTravellersUnder19 = true

                                if (inputs.tripExtension instanceof Object) {
                                    Object.keys(inputs.tripExtension).map(
                                        (key) => {
                                            if (inputs.tripExtension[key]) {
                                                const totalTravellers19Plus =
                                                    inputs.extensionTotalTravellers19Plus[
                                                        key
                                                    ].trim()
                                                if (
                                                    !validator.isNumeric(
                                                        totalTravellers19Plus,
                                                    ) ||
                                                    Number(
                                                        totalTravellers19Plus,
                                                    ) < 1 ||
                                                    Number(
                                                        totalTravellers19Plus,
                                                    ) > 50
                                                ) {
                                                    if (
                                                        !formErrors.extensionTotalTravellers19Plus
                                                    )
                                                        formErrors.extensionTotalTravellers19Plus =
                                                            {}
                                                    formErrors.extensionTotalTravellers19Plus[
                                                        key
                                                    ] = true
                                                }
                                                const totalTravellersUnder19 =
                                                    inputs.extensionTotalTravellersUnder19[
                                                        key
                                                    ].trim()
                                                if (
                                                    !validator.isNumeric(
                                                        totalTravellersUnder19,
                                                    ) ||
                                                    Number(
                                                        totalTravellersUnder19,
                                                    ) > 50
                                                ) {
                                                    if (
                                                        !formErrors.extensionTotalTravellersUnder19
                                                    )
                                                        formErrors.extensionTotalTravellersUnder19 =
                                                            {}
                                                    formErrors.extensionTotalTravellersUnder19[
                                                        key
                                                    ] = true
                                                }
                                            }

                                            return null
                                        },
                                    )
                                }

                                if (validator.isEmpty(inputs.departureCity))
                                    formErrors.departureCity = true
                            }
                        }

                        // console.log('FORM ERRORS',formErrors)

                        // If there are any errors, return
                        if (Object.keys(formErrors).length > 0) {
                            enableSubmit()
                            return setErrors(formErrors)
                        }

                        setErrors({})

                        let commentsArray = []

                        const primaryStore = storeData.store

                        // Is the extension selected?
                        let extension = false
                        let extensionId = null
                        let extensionName = null
                        let extensionTotalTravellers19Plus = null
                        let extensionTotalTravellersUnder19 = null
                        let trip = null
                        if (selectedProgram === 'TRIP') {
                            const tripSlugs = inputs.program.split(',')
                            if (tripSlugs.length > 1)
                                commentsArray.push('BOTH TRIPS REQUESTED')

                            trip =
                                inputs.program &&
                                privilegeTrips &&
                                privilegeTrips.trips.find(
                                    (trip) => trip.slug === tripSlugs[0],
                                )

                            if (inputs.tripExtension instanceof Object) {
                                Object.keys(inputs.tripExtension).map((key) => {
                                    if (inputs.tripExtension[key]) {
                                        extension = true

                                        const whichExtension =
                                            trip.extensions.find(
                                                (extension) =>
                                                    Number(extension.id) ===
                                                    Number(key),
                                            )
                                        extensionName = whichExtension.extension

                                        extensionTotalTravellers19Plus =
                                            Number(
                                                inputs
                                                    .extensionTotalTravellers19Plus[
                                                    key
                                                ],
                                            ) +
                                            Number(
                                                inputs
                                                    .extensionTotalTravellersUnder19[
                                                    key
                                                ],
                                            )
                                        extensionTotalTravellersUnder19 =
                                            Number(
                                                inputs
                                                    .extensionTotalTravellersUnder19[
                                                    key
                                                ],
                                            )
                                    }

                                    return null
                                })
                            }
                        }

                        // enableSubmit()
                        // return null

                        await createStagingRecord({
                            variables: {
                                payload: {
                                    memberType: 'store',
                                    pointsGoTo: null,

                                    // ossNumber: salesRep ? salesRep.number : inputs.ossNumber,
                                    // ossTitle: salesRep ? salesRep.title : inputs.ossTitle,
                                    ossName: inputs.ownerName,
                                    ossEmail: inputs.email,
                                    cellPhone: (inputs.cellPhone || '')
                                        .replace(/[^0-9]/g, '')
                                        .trim(),

                                    storeId: Number(primaryStore.id),
                                    storeNavisionId: primaryStore.navisionId,
                                    storeNum: primaryStore.napaNumber,
                                    // arNum: primaryStore.arNumber,
                                    // storeNum2: customerAccounts[1] ? customerAccounts[1].napaNumber : '',
                                    // arNum2: customerAccounts[1] ? customerAccounts[1].arNumber.trim() : '',
                                    // storeNum3: customerAccounts[2] ? customerAccounts[2].napaNumber : '',
                                    // arNum3: customerAccounts[2] ? customerAccounts[2].arNumber.trim() : '',
                                    // storeNum4: customerAccounts[3] ? customerAccounts[3].napaNumber : '',
                                    // arNum4: customerAccounts[3] ? customerAccounts[3].arNumber.trim() : '',
                                    // isInPrivilege: !aspNotFound && asp ? true : false,
                                    // tempId: !aspNotFound && asp ? Number(asp.id) : null,
                                    // privilegeNavisionId: !aspNotFound && asp ? asp.privilegeNavisionId : null,
                                    privilegeBenefit: `Trip ${trip.location}`,
                                    tripId: trip.id,
                                    tripLocation: trip.location,
                                    tripTravellersNum:
                                        Number(inputs.totalTravellers19Plus) +
                                        Number(inputs.totalTravellersUnder19),
                                    tripTravellersNumKids: Number(
                                        inputs.totalTravellersUnder19,
                                    ),
                                    extension: extension,
                                    extensionId: extensionId,
                                    extensionName: extensionName,
                                    extensionTravellersNum:
                                        extensionTotalTravellers19Plus,
                                    extensionTravellersNumKids:
                                        extensionTotalTravellersUnder19,
                                    promoOption: '',
                                    promoNavisionId: null,
                                    comments: commentsArray.join('\\n'),
                                    departureCity: inputs.departureCity || '0',
                                },
                            },
                        })

                        history.replace(`/enrollment/staging/store`, {
                            complete: true,
                        })
                    } catch (err) {
                        console.log(err)
                    }
                }}
            >
                {({ inputs }) => {
                    const selectedTrips = []
                    if (inputs.program && privilegeTrips) {
                        const trips = inputs.program.rawValue.split(',')
                        trips.map((tripSlug) => {
                            const selectedTrip = privilegeTrips.trips.find(
                                (trip) => trip.slug === tripSlug,
                            )
                            if (selectedTrip) selectedTrips.push(selectedTrip)
                            return null
                        })
                    }

                    return (
                        <>
                            {Object.keys(errors).length > 0 && (
                                <div
                                    style={{
                                        padding: '2rem',
                                        background: 'rgba(255,0,0,0.2)',
                                        borderRadius: '4px',
                                        color: '#880000',
                                        fontSize: '1.4rem',
                                    }}
                                >
                                    <p>
                                        {t(
                                            'enrollmentErrorsHelperText',
                                            'There was a problem processing this registration, please review and try again.',
                                        )}
                                    </p>
                                </div>
                            )}

                            {storeData && storeData.store && (
                                <>
                                    <Installer
                                        installer={storeData.store}
                                        noClose={true}
                                        onClear={() => {
                                            setAsp()
                                            return setAspNotFound()
                                        }}
                                    />

                                    <div
                                        className={`businessInformation__container`}
                                    >
                                        <h2>{t('Store owner information')}</h2>

                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns:
                                                    '1fr 1fr 1fr 1fr',
                                                gap: '3rem',
                                            }}
                                        >
                                            <Field
                                                name="ownerName"
                                                label={t('Owner name')}
                                                required
                                                error={errors.ownerName}
                                                errorMessage={t(
                                                    'ownerNameError',
                                                    'Please enter an owner name.',
                                                )}
                                            />
                                            <Field
                                                name="email"
                                                type="email"
                                                label={t('Owner email')}
                                                required
                                                error={errors.email}
                                                errorMessage={t(
                                                    'emailError',
                                                    'Please enter a valid email.',
                                                )}
                                            />
                                            <Field
                                                name="cellPhone"
                                                type="tel"
                                                label={t('Cell phone')}
                                                error={errors.cellPhone}
                                                errorMessage={t(
                                                    'cellPhoneError',
                                                    'Please enter a valid phone number: ie. 555-555-5555',
                                                )}
                                            />
                                            <Field
                                                name="language"
                                                label={t('Language')}
                                                noOptional
                                                type="select"
                                                options={[
                                                    ['E', 'English'],
                                                    ['F', 'French'],
                                                ]}
                                                error={errors.language}
                                                errorMessage={t(
                                                    'languageError',
                                                    'Please select a Language.',
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <HR />

                                    {/* Start program selection dropdown */}
                                    <div>
                                        <h2 className="new-installer-subheading">
                                            {t(
                                                'programSelectionSubheading',
                                                'Privilege membership benefit',
                                            )}
                                        </h2>
                                        <div className="grid grid-one-third">
                                            <Field
                                                type="select"
                                                name="program"
                                                label={t(
                                                    'programSelection',
                                                    'Select a membership benefit',
                                                )}
                                                options={[...trips]}
                                                required
                                                onChange={(e) =>
                                                    setSelectedProgram(
                                                        e.target.value ===
                                                            'GIFT_CARD'
                                                            ? 'GIFT_CARD'
                                                            : e.target.value !==
                                                              ''
                                                            ? 'TRIP'
                                                            : '',
                                                    )
                                                }
                                                error={errors.program}
                                                errorMessage={t(
                                                    'programSelectionError',
                                                    'Please select a membership benefit.',
                                                )}
                                            />
                                            {selectedProgram === 'TRIP' && (
                                                <>
                                                    <Field
                                                        type="numeric"
                                                        name="totalTravellers19Plus"
                                                        label={t(
                                                            'totalTravellers',
                                                            'Number of Travelling Adults (19 and Over)',
                                                        )}
                                                        required
                                                        error={
                                                            errors.totalTravellers19Plus
                                                        }
                                                        errorMessage={t(
                                                            'totalTravellersError',
                                                            'How many adults (19 and over) will be travelling?',
                                                        )}
                                                    />
                                                    <Field
                                                        type="numeric"
                                                        name="totalTravellersUnder19"
                                                        label={t(
                                                            'totalTravellersUnder19',
                                                            'Number of Travelling Children (Under 19)',
                                                        )}
                                                        required
                                                        error={
                                                            errors.totalTravellersUnder19
                                                        }
                                                        errorMessage={t(
                                                            'totalTravellersUnder19Error',
                                                            'How many children (under 19) will be travelling?',
                                                        )}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        {selectedTrips &&
                                            selectedTrips.map((trip) => (
                                                <>
                                                    {trip &&
                                                        inputs.totalTravellers19Plus && (
                                                            <div className="totalTravellers">
                                                                <Trans key="totalTravellersToExtension">
                                                                    Total
                                                                    travellers
                                                                    to{' '}
                                                                    {
                                                                        trip[
                                                                            `location_${i18n.language}`
                                                                        ]
                                                                    }
                                                                    :
                                                                </Trans>{' '}
                                                                {Number(
                                                                    inputs
                                                                        .totalTravellers19Plus
                                                                        .rawValue ||
                                                                        0,
                                                                ) +
                                                                    Number(
                                                                        inputs
                                                                            .totalTravellersUnder19
                                                                            .rawValue ||
                                                                            0,
                                                                    )}
                                                            </div>
                                                        )}
                                                </>
                                            ))}
                                        {selectedTrips &&
                                            selectedTrips.map((trip) => (
                                                <>
                                                    {trip &&
                                                        trip.extensions &&
                                                        trip.extensions.length >
                                                            0 && (
                                                            <>
                                                                <h3>
                                                                    Optional
                                                                    Trip
                                                                    Extension(s)
                                                                </h3>
                                                                {trip.extensions.map(
                                                                    (
                                                                        extension,
                                                                        i,
                                                                    ) => (
                                                                        <React.Fragment
                                                                            key={
                                                                                i
                                                                            }
                                                                        >
                                                                            <div className="grid grid-one-third">
                                                                                <Field
                                                                                    type="checkbox"
                                                                                    name="tripExtension"
                                                                                    id={
                                                                                        extension.id
                                                                                    }
                                                                                    label={
                                                                                        extension[
                                                                                            `extension_${i18n.language}`
                                                                                        ]
                                                                                    }
                                                                                    noOptional
                                                                                />
                                                                                {inputs.tripExtension &&
                                                                                    inputs
                                                                                        .tripExtension[
                                                                                        extension
                                                                                            .id
                                                                                    ] &&
                                                                                    inputs
                                                                                        .tripExtension[
                                                                                        extension
                                                                                            .id
                                                                                    ]
                                                                                        .rawValue ===
                                                                                        true && (
                                                                                        <>
                                                                                            <Field
                                                                                                type="numeric"
                                                                                                name="extensionTotalTravellers19Plus"
                                                                                                id={
                                                                                                    extension.id
                                                                                                }
                                                                                                label={t(
                                                                                                    'totalTravellers',
                                                                                                    'Number of Travelling Adults (19 and Over)',
                                                                                                )}
                                                                                                required
                                                                                                error={
                                                                                                    errors.extensionTotalTravellers19Plus &&
                                                                                                    errors
                                                                                                        .extensionTotalTravellers19Plus[
                                                                                                        extension
                                                                                                            .id
                                                                                                    ]
                                                                                                }
                                                                                                errorMessage={t(
                                                                                                    'totalTravellersError',
                                                                                                    'How many adults (19 and over) will be travelling?',
                                                                                                )}
                                                                                            />
                                                                                            <Field
                                                                                                type="numeric"
                                                                                                name="extensionTotalTravellersUnder19"
                                                                                                id={
                                                                                                    extension.id
                                                                                                }
                                                                                                label={t(
                                                                                                    'totalTravellersUnder19',
                                                                                                    'Number of Travelling Children (Under 19)',
                                                                                                )}
                                                                                                required
                                                                                                error={
                                                                                                    errors.extensionTotalTravellersUnder19 &&
                                                                                                    errors
                                                                                                        .extensionTotalTravellersUnder19[
                                                                                                        extension
                                                                                                            .id
                                                                                                    ]
                                                                                                }
                                                                                                errorMessage={t(
                                                                                                    'totalTravellersUnder19Error',
                                                                                                    'How many children (under 19) will be travelling?',
                                                                                                )}
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                            </div>
                                                                            {inputs.extensionTotalTravellers19Plus &&
                                                                                inputs
                                                                                    .extensionTotalTravellers19Plus[
                                                                                    extension
                                                                                        .id
                                                                                ] && (
                                                                                    <div className="totalTravellers">
                                                                                        <Trans key="totalTravellersToExtension">
                                                                                            Total
                                                                                            travellers
                                                                                            to{' '}
                                                                                            {
                                                                                                extension[
                                                                                                    `extension_${i18n.language}`
                                                                                                ]
                                                                                            }

                                                                                            :
                                                                                        </Trans>{' '}
                                                                                        {Number(
                                                                                            inputs
                                                                                                .extensionTotalTravellers19Plus[
                                                                                                extension
                                                                                                    .id
                                                                                            ]
                                                                                                .rawValue ||
                                                                                                0,
                                                                                        ) +
                                                                                            Number(
                                                                                                inputs
                                                                                                    .extensionTotalTravellersUnder19[
                                                                                                    extension
                                                                                                        .id
                                                                                                ]
                                                                                                    .rawValue ||
                                                                                                    0,
                                                                                            )}
                                                                                    </div>
                                                                                )}
                                                                        </React.Fragment>
                                                                    ),
                                                                )}
                                                            </>
                                                        )}
                                                </>
                                            ))}
                                        {/* Additional trip selection fields */}
                                        {selectedProgram === 'TRIP' && (
                                            <>
                                                <div>
                                                    <h3 className="new-installer-subheading">
                                                        {t(
                                                            'tripDepartureCitySubheading',
                                                            'Departing From',
                                                        )}
                                                    </h3>
                                                    {/* <div className='grid grid-one-third'> */}
                                                    <Field
                                                        type="select"
                                                        name="departureCity"
                                                        label={t(
                                                            'departureCity',
                                                            'Departing From',
                                                        )}
                                                        required
                                                        options={
                                                            departureCities
                                                        }
                                                        error={
                                                            errors.departureCity
                                                        }
                                                        errorMessage={t(
                                                            'departureCityError',
                                                            'Please select a departure location.',
                                                        )}
                                                        onChange={(e) => {
                                                            const departureCity =
                                                                departureCitiesData &&
                                                                departureCitiesData.privilege_departureCities.find(
                                                                    (
                                                                        departureCity,
                                                                    ) =>
                                                                        Number(
                                                                            departureCity.navisionId,
                                                                        ) ===
                                                                        Number(
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        ),
                                                                )
                                                            return setDeparture(
                                                                departureCity,
                                                            )
                                                        }}
                                                    />
                                                    {/* </div> */}
                                                    {departure &&
                                                        departure.navisionId !==
                                                            17 && (
                                                            <div className="mapContainer">
                                                                <div className="airportInfo">
                                                                    {
                                                                        departure[
                                                                            `airportName_${i18n.language}`
                                                                        ]
                                                                    }{' '}
                                                                    (
                                                                    {
                                                                        departure.airportCode
                                                                    }
                                                                    )
                                                                    {departureDistance && (
                                                                        <div className="airportData">
                                                                            {/* <Trans i18nKey='departureDistance'> */}
                                                                            <Formatting
                                                                                number
                                                                            >
                                                                                {
                                                                                    departureDistance.distance
                                                                                }
                                                                            </Formatting>{' '}
                                                                            {
                                                                                departureDistance.distanceUnit
                                                                            }{' '}
                                                                            {t(
                                                                                'departureDistanceAwayFrom',
                                                                                'away from',
                                                                            )}{' '}
                                                                            <span>
                                                                                {inputs
                                                                                    .businessName
                                                                                    .value ||
                                                                                    t(
                                                                                        'your customer',
                                                                                    )}
                                                                            </span>
                                                                            {/* </Trans> */}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <Map
                                                                    latitude={
                                                                        departure.latitude
                                                                    }
                                                                    longitude={
                                                                        departure.longitude
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {/* End program selection dropdown */}

                                    <HR />

                                    <Submit
                                        fullWidth
                                        context={'yellow'}
                                        style={{ marginTop: '3rem' }}
                                        disabled={1 === 2}
                                    >
                                        {t('Complete enrollment')}
                                    </Submit>
                                </>
                            )}
                        </>
                    )
                }}
            </Form>
        </div>
    )
}
