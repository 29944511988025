import gql from 'graphql-tag'

export const GET_PRIVILEGE_PRODUCT = gql`
    query PrivilegeProduct($slug: String!) {
        privilege_product(slug: $slug)
    }
`

export const CHECKOUT = gql`
    mutation Checkout($payload: JSON!) {
        privilege_checkout(payload: $payload)
    }
`

export const GET_ACTIVITIES = gql`
    query Activities($filter: InstallerFilter, $allowCancelled: Boolean) {
        installer(filter: $filter, allowCancelled: $allowCancelled) {
            privilege {
                member {
                    privilegeNavisionId
                    installerNavisionId
                    privilegeEarningStart
                    privilegeBenefit
                    isTrip
                    totalSales
                    totalEarnings
                    sales {
                        id
                        recordType
                        arNumber
                        pointsGoTo
                        monthlySales
                        month
                        year
                        totalPoints
                        pointsRedeemable
                        status
                        creditReason
                        installerNavisionId
                        privilegeNavisionId
                        storeNapaNumber
                        # visaCardNumber: String
                        enteredAt
                        enteredBy
                        statusAt
                        statusBy
                        reportedAt
                        excludeFromReport
                        salesCompany
                    }
                    balance
                }
            }
        }
    }
`

export const GET_REDEMPTIONS = gql`
    query Redemptions($filter: InstallerFilter, $allowCancelled: Boolean) {
        installer(filter: $filter, allowCancelled: $allowCancelled) {
            privilege {
                member {
                    privilegeNavisionId
                    installerNavisionId
                    privilegeEarningStart
                    privilegeBenefit
                    isTrip
                    totalSales
                    totalEarnings
                    redemptions
                    balance
                }
            }
        }
    }
`

export const GET_ORDER = gql`
    query Order(
        $orderNumber: String!
        $privilegeNavisionId: String!
        $language: String!
    ) {
        privilege_order(
            orderNumber: $orderNumber
            privilegeNavisionId: $privilegeNavisionId
            language: $language
        )
    }
`
