import React, { createContext, useContext, useEffect, useState } from 'react'
import currency from 'currency.js'

const CartContext = createContext()

export default function CartProvider({ children }) {
    const [state, setState] = useState([])

    const subtotal = state.reduce(
        (total, current) =>
            (total = currency(total).add(
                currency(current.amount).multiply(current.quantity),
            )).value,

        0,
    )

    const shippingHandling = currency(subtotal).multiply(0.06).value
    const total = currency(subtotal).multiply(1.06).value

    return (
        <CartContext.Provider
            value={{
                subtotal,
                shippingHandling,
                total,
                quantity: state.reduce(
                    (total, current) => (total += current.quantity),

                    0,
                ),
                cart: state,
                addToCart: (item) =>
                    setState((state) => {
                        const nextState = state
                        const findIndex = nextState.findIndex(
                            (current) =>
                                current.id === item.id &&
                                current.amount === item.amount,
                        )
                        if (findIndex > -1) {
                            nextState[findIndex].quantity += item.quantity
                            return [...nextState]
                        }
                        return [...state, item]
                    }),
                removeFromCart: (index) =>
                    setState((state) => {
                        state.splice(index, 1)
                        return [...state]
                    }),
                updateQuantity: (index, quantity) => {
                    setState((state) => {
                        const nextState = state
                        if (!nextState[index]) return [...nextState]
                        nextState[index].quantity = quantity
                        return [...nextState]
                    })
                },
                incrementQuantity: (index) => {
                    setState((state) => {
                        const nextState = state
                        if (!nextState[index]) return [...nextState]
                        nextState[index].quantity += 1
                        return [...nextState]
                    })
                },
                decrementQuantity: (index) => {
                    setState((state) => {
                        const nextState = state
                        if (!nextState[index]) return [...nextState]
                        if (nextState[index].quantity === 1)
                            return [...nextState]
                        nextState[index].quantity -= 1
                        return [...nextState]
                    })
                },
                resetCart: () => setState([]),
            }}
        >
            {children}
        </CartContext.Provider>
    )
}

export function useCart() {
    return useContext(CartContext)
}
