import gql from 'graphql-tag'

export const GET_DCS = gql`
    query GetDCs($filter: DCFilter, $limit: Int, $offset: Int) {
        dcs(filter: $filter, limit: $limit, offset: $offset) {
            id
            dcNum
            name
            address
            city
            province
            postal
            status
            privilegeStats
        }
    }
`
