import gql from 'graphql-tag'

export const OAUTH_GENERATE = gql`
    mutation OauthGenerate {
        oauthGenerate {
            oauthToken
            oauthRedirect
        }
    }
`

export const OAUTH_AUTHENTICATE = gql`
    query OauthAuthenticate($payload: OauthPayload! ) {
        oauthAuthenticate(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const AUTHENTICATE = gql`
    mutation Authenticate($payload: AuthPayload!) {
        authenticate(payload: $payload) {
            accessToken
            refreshToken
        }
    }
`

export const REFRESH_TOKEN = gql`
    mutation RefreshToken($payload: RefreshTokenPayload!) {
        refreshToken(payload: $payload) {
            accessToken
        }
    }
`

export const ME = gql`
    query Me {
        me {
            uuid
            username
            approvedDcList
            userType
            firstName
            lastName
            userPermissionGrants {
                id
                uuid
                username
            }
        }
    }
`

export const UPDATE_USER = gql`
    mutation UpdateUser($payload: UserPayload!) {
        updateUserAccount(payload: $payload) {
            id
            uuid
            username
            firstName
            lastName
        }
    }
`