import React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    useLocation,
} from 'react-router-dom'
import Nav from './components/Nav'
import Breadcrumbs from './components/Breadcrumbs'
import { useAuthentication } from './hooks/useAuthentication'
import { useGlobalState } from './hooks/useGlobalState'
import Unauthorized from './components/Unauthorized'
// import Login from './pages/Login'
// import Promotions from './pages/Promotions'
import HeadOffice from './pages/HeadOffice'
import DCs from './pages/DCs'
import Bds from 'pages/Bds'
import Stores from './pages/Stores'
import Installers from './pages/Installers'
import Installer from './pages/Installer'
import Register from './pages/Register'
import RegisterStore from './pages/Register/RegisterStore'
import Enrollment from './pages/Enrollment'
import EnrollmentMember from './pages/Enrollment/Member'
import PageNotFound from './pages/PageNotFound'

import InstallerEarnings from './pages/Earnings'

import GAListener from './GAListener'

import ScrollRestoration from './ScrollRestoration'
import Refresh from './components/Refresh'

import OAUTH from './pages/OAUTH'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { GET_STORE } from './operations/Store'
import Loader from './components/Loader'
import { useCompany } from 'hooks/company'
import Resources from 'pages/Resources'

import { MajorAccounts } from 'pages/MajorAccounts'
import Reward from 'pages/Reward'
import Cart from 'pages/Cart'
import Checkout from 'pages/Checkout'
import Order from 'pages/Order'
import Redemptions from 'pages/Remptions'

const MYPROGRAMS_URI = {
    NAPA: {
        development: 'http://localhost:3100',
        production: 'https://mynapaprograms.com',
    },
    AAG: {
        development: 'http://localhost:3100',
        production: 'https://myaagprograms.com',
    },
}

export default function Routes() {
    const company = useCompany()

    const { i18n } = useTranslation()
    const { isAuthenticated, user } = useAuthentication()
    const { majorAccount, dc, store, bds } = useGlobalState()

    const RootComponentByUser = {
        STORE: Installers,
        SALES_REP: majorAccount ? Installers : !store ? Stores : Installers,
        // 'EMPLOYEE': Promotions,
        AGENT: majorAccount
            ? Installers
            : !store
            ? !dc
                ? HeadOffice
                : Stores
            : Installers,

        HEAD_OFFICE: majorAccount
            ? Installers
            : !store
            ? !dc
                ? HeadOffice
                : Stores
            : Installers,

        // TSM is the same as DC, but can see multiple DC's
        TERRITORY_SALES_MANAGER: majorAccount
            ? Installers
            : !store
            ? !dc
                ? DCs
                : Stores
            : Installers,

        DC: majorAccount ? Installers : !store ? Stores : Installers,

        MAJOR_ACCOUNT: majorAccount ? Installers : MajorAccounts,

        // Director manages a group of BDS
        DIRECTOR: !bds ? Bds : Installers,
        BUSINESS_DEVELOPMENT_SPECIALIST: Installers,

        INSTALLER: Installer,
    }
    if (window?.location?.pathname?.includes('pdfProxy')) return null

    const RootComponent = RootComponentByUser[user.userType]

    if (!isAuthenticated)
        return (
            <UnauthorizedLayout>
                <Router>
                    <Switch>
                        {/* <Route path='/' exact component={ Login } /> */}
                        <Route
                            path="/"
                            exact
                            render={() =>
                                window.location.replace(
                                    `${
                                        MYPROGRAMS_URI[company][
                                            process.env.NODE_ENV
                                        ]
                                    }/l/${i18n.language}/privilege`,
                                )
                            }
                        />
                        <Route
                            path="/oauth/:language/:token"
                            component={OAUTH}
                        />
                    </Switch>
                    <Route component={GAListener} />
                    {/* <Route component={ Refresh } /> */}
                </Router>
            </UnauthorizedLayout>
        )

    return (
        <Router>
            <ScrollRestoration />
            <AuthorizedLayout>
                <Breadcrumbs />

                <Switch>
                    <ProtectedRoute
                        userTypes={['INSTALLER']}
                        path="/earnings/:installerNavisionId?"
                        component={InstallerEarnings}
                    />
                    <ProtectedRoute
                        userTypes={['INSTALLER']}
                        path="/redemptions"
                        component={Redemptions}
                    />
                    <ProtectedRoute
                        userTypes={['INSTALLER']}
                        path="/reward/:product"
                        component={Reward}
                    />
                    <ProtectedRoute
                        userTypes={['INSTALLER']}
                        path="/cart"
                        component={Cart}
                    />
                    <ProtectedRoute
                        userTypes={['INSTALLER']}
                        path="/checkout"
                        component={Checkout}
                    />
                    <ProtectedRoute
                        userTypes={['INSTALLER']}
                        path="/order/:orderNumber"
                        component={Order}
                    />
                    <ProtectedRoute
                        userTypes={['STORE']}
                        path="/register-store"
                        component={RegisterStore}
                    />
                    <ProtectedRoute
                        userTypes={['SALES_REP', 'STORE']}
                        isMajorAccount={majorAccount ? true : false}
                        path="/register/:aspId?"
                        component={Register}
                    />
                    <ProtectedRoute
                        userTypes={[
                            'AGENT',
                            'SALES_REP',
                            'STORE',
                            'BUSINESS_DEVELOPMENT_SPECIALIST',
                            'DIRECTOR',
                            'HEAD_OFFICE',
                            'DC',
                            'MAJOR_ACCOUNT',
                            'TERRITORY_SALES_MANAGER',
                        ]}
                        isMajorAccount={majorAccount ? true : false}
                        path="/enrollment/staging/:id"
                        component={Enrollment}
                    />
                    <ProtectedRoute
                        userTypes={[
                            'AGENT',
                            'SALES_REP',
                            'STORE',
                            'BUSINESS_DEVELOPMENT_SPECIALIST',
                            'DIRECTOR',
                            'HEAD_OFFICE',
                            'DC',
                            'MAJOR_ACCOUNT',
                            'TERRITORY_SALES_MANAGER',
                        ]}
                        isMajorAccount={majorAccount ? true : false}
                        path="/enrollment/member/:id/:installerNavisionId?"
                        component={EnrollmentMember}
                    />

                    <Route path="/resources" component={Resources} />

                    <Route path="/" exact component={RootComponent} />
                    <Route path="/oauth/:language/:token" component={OAUTH} />
                    <Route component={PageNotFound} />
                </Switch>
                <Route component={GAListener} />
                <Route component={Refresh} />
            </AuthorizedLayout>
        </Router>
    )
}

function UnauthorizedLayout({ children }) {
    return <Unauthorized>{children}</Unauthorized>
}

function AuthorizedLayout({ children }) {
    const { user } = useAuthentication()

    return (
        <div
            className={`mainContainer ${
                user.userType === 'STORE' ? 'mainContainer--store' : ''
            }`}
        >
            <header className="header">
                {user.userType === 'STORE' && <StoreData />}

                <Nav />
            </header>
            <div
                className={`main ${
                    ['INSTALLER', 'EMPLOYEE'].includes(user.userType)
                        ? 'installer'
                        : 'store'
                }`}
            >
                {children}
            </div>
        </div>
    )
}

function StoreData() {
    const { user } = useAuthentication()

    let queryVars = {}

    queryVars.filter = {
        navisionId: {
            eq: user.navisionId,
        },
    }

    const { error, loading, data } = useQuery(GET_STORE, {
        variables: queryVars,
    })

    if (loading) return <Loader />
    if (error) return `Error loading store data`

    const {
        navisionId,
        storeNapaNumber,
        businessName,
        address,
        city,
        province,
    } = data.store

    return (
        <span className="store-login--account">
            {navisionId} ({storeNapaNumber})<br />
            {businessName}
            <br />
            {address}, {city} {province}
        </span>
    )
}

function ProtectedRoute({ userTypes, isMajorAccount, component, ...rest }) {
    const { user } = useAuthentication()
    const Component = component
    if (userTypes.indexOf(user.userType) > -1 || isMajorAccount)
        return <Route {...rest} render={(props) => <Component {...props} />} />
    return <Redirect to="/" />
}
