import React, { createContext, useContext, useState } from 'react'

const ModalContext = createContext()

export default function Modal({ children }) {

    const [state, setState] = useState()

    return (
        <ModalContext.Provider
            value={{
                modal: state,
                setModal: (state) => setState(state)
            }}
        >
            { children }
        </ModalContext.Provider>
    )

}

export const useModal = () => useContext(ModalContext)