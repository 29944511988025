import gql from 'graphql-tag'

export const GET_INSTALLER = gql`
    query Installer($filter: InstallerFilter, $allowCancelled: Boolean) {
        installer(filter: $filter, allowCancelled: $allowCancelled) {
            id
            navisionId
            ownerName
            businessName
            legalName
            address
            city
            province
            language
            email
            privilegeEmail
            postal
            phone
            cellPhone

            installerNumber

            store {
                navisionId
                storeNapaNumber
                businessName
            }

            privilege {
                member {
                    id
                    balance
                    totalEarnings
                    totalRedemptions
                    memberType
                    arNumber
                    privilegeNavisionId
                    privilegeBenefit
                    privilegeBenefitDetails {
                        id
                        location
                        location_en
                        location_fr
                        slug
                        primaryTrip
                        imageUrl
                        travelDates_en
                        travelDetails_en
                        travelDates_fr
                        travelDetails_fr
                    }
                    tripLocations
                    isTrip
                    enteredDate
                    maxEarningCap
                    privilegeEarningStart
                    tripAttendeeCount
                    tripAttendeeChildrenCount
                    tripHasExtension
                    extensionName
                    extensionAttendeeCount
                    extensionAttendeeChildrenCount
                    extensionDetails {
                        id
                        tripId
                        extension
                        extension_en
                        extension_fr
                    }
                    hasBothTrips
                    secondaryMemberships {
                        id
                        privilegeBenefit
                        privilegeBenefitDetails {
                            id
                            location
                            location_en
                            location_fr
                            slug
                            primaryTrip
                            imageUrl
                            travelDates_en
                            travelDetails_en
                            travelDates_fr
                            travelDetails_fr
                        }
                        tripLocations
                        isTrip
                        tripAttendeeCount
                        tripAttendeeChildrenCount
                        tripHasExtension
                        extensionName
                        extensionAttendeeCount
                        extensionAttendeeChildrenCount
                    }
                }
            }

            user {
                id
                isValidEmail
                validationEmailResends
            }
        }
    }
`

export const GET_INSTALLERS = gql`
    query SearchInstallers($search: String) {
        installerSearch(search: $search) {
            id
            navisionId
            legalName
            businessName
            address
            city
            province
            postal
            phone
            email
            ownerName
            language
            cellPhone
            # TODO: Confirm preferred way of getting DC Store num
            storeNum
        }
    }
`

export const GET_INSTALLER_BALANCE = gql`
    query PointsBalance(
        $installerFilter: Promotions_PointsFilter
        $productFilter: ProductService_CartFilter!
    ) {
        promotions {
            pointsBalance(filter: $installerFilter) {
                points
            }
        }
        productService {
            cartCount(filter: $productFilter)
            cartPointBalance(filter: $productFilter)
        }
    }
`

export const GET_INSTALLER_BALANCE_DATA = gql`
    query InstallerBalanceData(
        $filter: InstallerFilter
        $allowCancelled: Boolean
    ) {
        installer(filter: $filter, allowCancelled: $allowCancelled) {
            id
            promotions {
                pointsEarned
            }
            productService {
                pointsRedeemed
            }
        }
    }
`

export const GET_SALES_DATA = gql`
    query SalesData($filter: Promotions_PointsFilter) {
        promotions {
            salesData(filter: $filter) {
                data
            }
        }
    }
`

export const GET_SALES_TOTALS = gql`
    query SalesTotals($filter: Promotions_PointsFilter) {
        promotions {
            salesTotals(filter: $filter) {
                data
            }
        }
    }
`

export const GET_REGISTRATION_WINDOW_OPEN = gql`
    query GetRegistrationIsOpen {
        promotions {
            registrationIsOpen
        }
    }
`

export const GET_PRIVILEGE_BENEFIT = gql`
    query GetPrivilegeBenefit {
        privilege {
            member {
                id
                installerNavisionId
                installer {
                    businessName
                    address
                    city
                    province
                    postal
                    phone
                    email
                }
                privilegeBenefit
            }
        }
    }
`

export const REGISTER_INSTALLER = gql`
    mutation RegisterInstaller($payload: Promotions_RegistrationPayload!) {
        promotion_registerInstaller(payload: $payload) {
            id

            store {
                id
                navisionId
            }
            installer {
                id
                navisionId
            }

            promotionYearId

            # New Installer fields entered from frontend (NT number is a read-only field)
            # locNumber
            address
            language
            businessName
            city
            ownerName
            legalName
            province
            email
            phone
            postal
            cellPhone

            enrollments {
                id
                promotion {
                    id
                    # promoId
                    napaBulletinNumber
                    images
                    names
                    startDate
                    endDate
                    minimumPurchaseAmount
                    familyLines
                    pointsEarned
                    purchases
                    slug
                }
            }
        }
    }
`

export const ENROLL_INSTALLER = gql`
    mutation EnrollInstaller($payload: Promotions_EnrollmentPayload!) {
        promotion_enrollInstaller(payload: $payload) {
            id

            store {
                id
                navisionId
            }
            installer {
                id
                navisionId
            }

            promotionYearId

            # New Installer fields entered from frontend (NT number is a read-only field)
            # locNumber
            address
            language
            businessName
            city
            ownerName
            legalName
            province
            email
            phone
            postal
            cellPhone

            enrollments {
                id
                promotion {
                    id
                    # promoId
                    napaBulletinNumber
                    images
                    names
                    startDate
                    endDate
                    minimumPurchaseAmount
                    familyLines
                    pointsEarned
                    purchases
                    slug
                }
            }
        }
    }
`

export const RESEND_INSTALLER_INVITATION = gql`
    mutation ResendInstallerInvitation(
        $payload: ResendInstallerInvitationPayload!
    ) {
        resendInstallerInvitation(payload: $payload) {
            id
            user {
                id
                isValidEmail
                validationEmailResends
            }
        }
    }
`

export const RESET_EMAIL_LIMIT = gql`
    mutation ResetInstallerResendLimit(
        $payload: ResetInstallerResendLimitPayload!
    ) {
        resetInstallerResendLimit(payload: $payload) {
            id
            user {
                id
                validationEmailResends
            }
        }
    }
`

export const GET_INSTALLER_WITH_STORE = gql`
    query Installer($allowCancelled: Boolean) {
        installer(allowCancelled: $allowCancelled) {
            id
            navisionId
            ownerName
            businessName

            store {
                id
                navisionId
                storeNapaNumber
                businessName
                contactName
                address
                city
                province
                postal
                phone

                dc {
                    id
                    dcNum
                }
            }
        }
    }
`
