import { useQuery } from '@apollo/client'
import Card, { CardSection } from 'components/Card'
import Format, { FormatCurrency } from 'components/Format'
import InstallerDashboard, {
    PrivilegeBenefitLogo,
} from 'components/InstallerDashboard'
import Loader from 'components/Loader'
import { useCart } from 'hooks/cart'
import { DateTime } from 'luxon'
import { GET_INSTALLER } from 'operations/Installer'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import currency from 'currency.js'

import './installerDashboard.scss'
import { Link } from 'react-router-dom'

export default function Installer({ member: privilegeMember }) {
    const { t, i18n } = useTranslation()

    const { error, loading, data } = useQuery(GET_INSTALLER, {
        skip: privilegeMember ? true : false,
    })

    const { installer = {} } = data || {}

    const membership = privilegeMember
        ? privilegeMember
        : installer.privilege?.member ?? {}

    const {
        total,
        // cart,
        // addToCart,
        // removeFromCart,
        // updateQuantity,
        // incrementQuantity,
        // decrementQuantity,
    } = useCart()

    // useEffect(() => {
    //     removeFromCart(1)
    //     // test2
    // }, [])

    // useEffect(() => {
    //     removeFromCart(0)
    // }, [])
    // useEffect(() => {
    //     addToCart({
    //         id: 1,
    //         name: 'Visa card',
    //         slug: 'gift-card',
    //         quantity: 1,
    //         amount: 25,
    //     })
    //     addToCart({
    //         id: 2,
    //         name: 'Tool Certificates',
    //         slug: 'napa-tool-certificates',
    //         quantity: 2,
    //         amount: 100,
    //     })
    // }, [])

    const balance = membership?.balance
        ? currency(membership?.balance).value
        : 0

    return (
        <div className="contentContainer">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <h1>{t('rewardHeader', 'Reward')}</h1>
                {membership && !membership?.isTrip && !privilegeMember && (
                    <div className="balanceAmount">
                        {t('balanceHeader', 'Balance:')}{' '}
                        <FormatCurrency>
                            {currency(balance).subtract(total).value}
                        </FormatCurrency>
                    </div>
                )}
            </div>

            {!membership ? (
                <Loader />
            ) : (
                <>
                    {!privilegeMember && (
                        <Card className="installerDashboardCard">
                            <div className="installerDashboardCard--logo">
                                <PrivilegeBenefitLogo membership={membership} />
                            </div>
                            <div className="installerDashboardCard--inner">
                                <CardSection
                                    title={t(
                                        'cardHeaderPrivBenefit',
                                        'Privilege benefit',
                                    )}
                                >
                                    {/* TODO: Shorten this */}
                                    {membership &&
                                        membership.privilegeBenefitDetails &&
                                        membership.privilegeBenefitDetails[
                                            `location_${i18n.language}`
                                        ]}
                                    {membership.tripHasExtension &&
                                        ' + ' +
                                            membership?.extensionDetails[
                                                `extension_${i18n.language}`
                                            ]}
                                    {membership.secondaryMemberships?.map(
                                        (secondaryMembership) => {
                                            return (
                                                <span
                                                    key={secondaryMembership.id}
                                                >
                                                    ,{' '}
                                                    {
                                                        secondaryMembership
                                                            ?.privilegeBenefitDetails[
                                                            `location_${i18n.language}`
                                                        ]
                                                    }
                                                </span>
                                            )
                                        },
                                    )}
                                </CardSection>
                                {membership.isTrip && (
                                    <>
                                        <CardSection
                                            title={t(
                                                'cardHeaderTravellers',
                                                'Travellers',
                                            )}
                                        >
                                            {membership.tripAttendeeCount}{' '}
                                            Travellers
                                            {/* {membership.tripAttendeeChildrenCount &&
                                                `& ${membership.tripAttendeeChildrenCount} Children`} */}
                                        </CardSection>
                                    </>
                                )}
                                <CardSection
                                    title={t(
                                        'cardHeaderEarningStart',
                                        'Earnings start',
                                    )}
                                >
                                    {DateTime.fromMillis(
                                        membership.privilegeEarningStart || 0,
                                    )
                                        // .setZone('UTC')
                                        .setLocale(i18n.language)
                                        .toLocaleString(DateTime.DATE_FULL)}
                                </CardSection>

                                <CardSection
                                    title={
                                        <Link
                                            to="/earnings"
                                            className="cardSection"
                                        >
                                            {t(
                                                'cardHeaderEarnings',
                                                'Earnings',
                                            )}
                                            <i className="material-icons">
                                                link
                                            </i>
                                        </Link>
                                    }
                                    style={{ textAlign: 'right' }}
                                >
                                    <FormatCurrency>
                                        {membership.totalEarnings ?? 0}
                                    </FormatCurrency>
                                </CardSection>
                                {!membership.isTrip && (
                                    <CardSection
                                        title={
                                            <Link
                                                to="/redemptions"
                                                className="cardSection"
                                            >
                                                {t(
                                                    'cardHeaderRedemptions',
                                                    'Redemptions',
                                                )}
                                                <i className="material-icons">
                                                    link
                                                </i>
                                            </Link>
                                        }
                                        style={{ textAlign: 'right' }}
                                    >
                                        <FormatCurrency>
                                            {membership.totalRedemptions ?? 0}
                                        </FormatCurrency>
                                    </CardSection>
                                )}
                                {/* <CardSection title="Enrolled">
                            {DateTime.fromMillis(
                                membership.enteredDate || 0,
                            ).toLocaleString(DateTime.DATE_FULL)}
                        </CardSection> */}
                            </div>
                        </Card>
                    )}

                    <div
                        className={`privilegeRewardContainer ${
                            membership.hasBothTrips
                                ? 'privilegeRewardContainer--noMaxWidth'
                                : 'privilegeRewardContainer--maxWidth'
                        }`}
                    >
                        <InstallerDashboard
                            membership={membership}
                            noRedemption={privilegeMember ? true : false}
                        />
                    </div>
                    {/* {JSON.stringify(cart)} */}
                </>
            )}
        </div>
    )
}
