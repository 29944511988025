import gql from 'graphql-tag'

export const STORE_SEARCH = gql`
    query StoreSearch($search: String) {
        storeSearch(search: $search) {
            id
            navisionId
            businessName
            address
            city
            province
            postal
            contactName
            napaNumber
            relationshipType
            status
            language
            primaryCompany
            customerOf
        }
    }
`

export const GET_STORE = gql`
    query Store($filter: StoreFilter) {
        store(filter: $filter) {
            id
            navisionId
            legalName
            businessName
            address
            city
            province
            postal
            contactName
            storeNapaNumber
            napaNumber
            relationshipType
            status
            language
        }
    }
`

export const GET_STORES = gql`
    query Stores($filter: StoreFilter, $limit: Int, $offset: Int) {
        stores(filter: $filter, limit: $limit, offset: $offset) {
            id
            navisionId
            legalName
            businessName
            address
            city
            province
            postal
            storeNapaNumber
            napaNumber
            privilegeStats
        }
    }
`
