import Card, { CardSection } from 'components/Card'
import { FormatCurrency } from 'components/Format'
import { useCart } from 'hooks/cart'
import './checkout.scss'
import currency from 'currency.js'
import { GET_INSTALLER } from 'operations/Installer'
import { useMutation, useQuery } from '@apollo/client'
import NoData from 'components/NoData'
import { Link, Redirect } from 'react-router-dom'
import certificateNapaEn from 'components/CertificateImage/certificate_napa_en.svg'
import VisaCard from 'components/VisaCard'
import { imageOverlay } from 'leaflet'
import { Img } from 'components/Tueri'
import { CHECKOUT } from 'operations/Activity'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Form, { Field, FormContext, Submit } from 'components/Form'
import validator from 'validator'
import ErrorMessage from 'components/ErrorMessage'
import * as phoneFns from 'phone-fns'

export default function Checkout() {
    const { t, i18n } = useTranslation()
    const { cart, total, subtotal, shippingHandling } = useCart()

    const [error, setError] = useState()
    const [errors, setErrors] = useState()

    const { data: installerData } = useQuery(GET_INSTALLER)

    const [checkout, { data: checkoutData }] = useMutation(CHECKOUT, {
        refetchQueries: [{ query: GET_INSTALLER }],
    })

    const balance = installerData?.installer?.privilege?.member?.balance
        ? currency(installerData?.installer?.privilege?.member?.balance).value
        : 0

    const asp = installerData?.installer
    const member = asp?.privilege?.member
    const store = asp?.store

    const errorMessages = {
        INSUFFICIENT_POINTS: t('Insufficient Points'),
    }

    const email = validator.isEmail(asp.privilegeEmail)
        ? asp.privilegeEmail
        : asp.email

    return (
        <div className="contentContainer">
            {checkoutData && (
                <Redirect
                    to={`/order/${checkoutData.privilege_checkout}?complete=1`}
                    replace
                />
            )}
            {error && <ErrorMessage>{errorMessages[error]}</ErrorMessage>}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <h1>Checkout</h1>
                <div className="balanceAmount">
                    {t('balanceHeader', 'Balance:')}{' '}
                    <FormatCurrency>
                        {currency(balance).subtract(total).value}
                    </FormatCurrency>
                </div>
            </div>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '.5fr 1px 1fr',
                    gap: '4rem',
                }}
            >
                <div>
                    <h2>{t('shipTo', 'Send to')}</h2>
                    {/* <div>ATTN: {asp.ownerName}</div> */}
                    <div>
                        <strong>{asp.businessName}</strong>
                    </div>
                    <div>{asp.address}</div>
                    <div>
                        {asp.city}, {asp.province}
                    </div>
                    <div>{asp.postal}</div>
                    <div>
                        <a href={`mailto:${email}`}>{email}</a>
                    </div>
                </div>
                <div style={{ width: '1px', background: '#eee' }} />
                <div>
                    {cart.length === 0 && (
                        <div>
                            <NoData>
                                <div>
                                    {t(
                                        'noCartItems',
                                        "Sorry, it doesn't look like you have added anything to your cart.",
                                    )}
                                </div>
                                <div>
                                    <Link to="/">
                                        {t(
                                            'noCartItemsLink',
                                            `Let's find something to add your cart.`,
                                        )}
                                    </Link>
                                </div>
                            </NoData>
                        </div>
                    )}
                    {cart?.map((item, i) => {
                        return (
                            <div key={i} className="checkoutLine">
                                <div
                                    style={{
                                        display: 'flex',
                                        gap: '2rem',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ position: 'relative' }}>
                                        <img
                                            src={
                                                item.imageUrl.replace(
                                                    'https://napa-privilege.nyc3.digitaloceanspaces.com/',
                                                    'https://cdn.tueri.io/68719476737/napaprivilege/',
                                                ) + '?w=80'
                                            }
                                            alt={item.name}
                                            style={{
                                                boxShadow: `0 1px 1px 0 rgba(60, 64, 67, 0.08),
                                        0 1px 3px 1px rgba(60, 64, 67, 0.16)`,
                                                borderRadius: '4px',
                                                overflow: 'hidden',
                                            }}
                                        />
                                        <div className="checkoutQuantity">
                                            {item.quantity}
                                        </div>
                                    </div>
                                    <CardSection
                                        title={t('cardHeaderName', 'Name')}
                                    >
                                        <strong>{item.name}</strong>
                                    </CardSection>
                                </div>
                                <div style={{ display: 'flex', gap: '4rem' }}>
                                    <CardSection
                                        title={t('cardHeaderAmount', 'Amount')}
                                    >
                                        <FormatCurrency>
                                            {item.amount}
                                        </FormatCurrency>
                                    </CardSection>
                                    <CardSection
                                        title={t('cardHeaderTotal', 'Total')}
                                    >
                                        <FormatCurrency>
                                            {
                                                currency(item.amount).multiply(
                                                    item.quantity,
                                                ).value
                                            }
                                        </FormatCurrency>
                                    </CardSection>
                                </div>
                            </div>
                        )
                    })}
                    <div className="checkoutTotalContainer">
                        <div className="checkoutTotalGroupContainer">
                            <div className="">{t('subtotal', 'Subtotal:')}</div>
                            <div className="">
                                {t('shippingHandling', 'Admin Fee @ 6%:')}
                            </div>
                            <div className="checkoutTotalTitle">
                                {t('total', 'Total:')}
                            </div>
                        </div>
                        <div className="checkoutTotalGroupContainer">
                            <div>
                                <FormatCurrency>{subtotal}</FormatCurrency>
                            </div>
                            <div>
                                <FormatCurrency>
                                    {shippingHandling}
                                </FormatCurrency>
                            </div>
                            <div className="checkoutTotalAmount">
                                <FormatCurrency>{total}</FormatCurrency>
                            </div>
                        </div>
                    </div>
                    {subtotal >= 100 ? (
                        <Form
                            onSubmit={async ({
                                rawInputs: inputs,
                                enableSubmit,
                            }) => {
                                try {
                                    if (subtotal < 100) return enableSubmit()

                                    console.log(inputs)

                                    const errors = {}
                                    if (
                                        !inputs.phone ||
                                        !validator.isMobilePhone(
                                            inputs.phone,
                                            'en-CA',
                                        )
                                    )
                                        errors.phone = true

                                    if (Object.keys(errors).length > 0) {
                                        setErrors(errors)
                                        return enableSubmit()
                                    }

                                    await checkout({
                                        variables: {
                                            payload: {
                                                installerNavisionId:
                                                    asp.navisionId,
                                                privilegeNavisionId:
                                                    member.privilegeNavisionId,
                                                storeNavisionId:
                                                    store.navisionId,
                                                storeNapaNumber:
                                                    store.storeNapaNumber,
                                                arNumber: member.arNumber,
                                                pointsGoTo:
                                                    member.privilegeNavisionId,
                                                cart,
                                                language: i18n.language,
                                                total,
                                                phoneNumber: phoneFns.format(
                                                    'NNN-NNN-NNNN',
                                                    inputs.phone,
                                                ),
                                            },
                                        },
                                    })
                                } catch (err) {
                                    setError(err.message)
                                }
                            }}
                        >
                            {() => (
                                <>
                                    <Field
                                        name="phone"
                                        label={t(
                                            '"Contact phone number',
                                            'Contact phone number',
                                        )}
                                        required
                                        error={errors?.phone}
                                        errorMessage={t(
                                            'errorCheckoutPhone',
                                            'Please enter a phone number to contact for delivery.',
                                        )}
                                    />
                                    <Submit>
                                        {t(
                                            'completeOrderButton',
                                            'Complete my order',
                                        )}
                                    </Submit>
                                </>
                            )}
                        </Form>
                    ) : (
                        <div
                            style={{
                                textAlign: 'center',
                                margin: '2rem',
                                color: 'red',
                            }}
                        >
                            {i18n.language.substring(0, 2) === 'en' ? (
                                <em>
                                    You need a minimum <strong>subtotal</strong>{' '}
                                    of $100 in your cart to redeem.
                                </em>
                            ) : (
                                <em>
                                    Vous avez besoin d'un{' '}
                                    <strong>sous-total</strong> minimum de 100$
                                    dans votre panier pour échanger
                                </em>
                            )}
                        </div>
                    )}
                    {/* <button
                        type="button"
                        onClick={async () => {
                            try {
                                await checkout({
                                    variables: {
                                        payload: {
                                            installerNavisionId: asp.navisionId,
                                            privilegeNavisionId:
                                                member.privilegeNavisionId,
                                            storeNavisionId: store.navisionId,
                                            storeNapaNumber:
                                                store.storeNapaNumber,
                                            arNumber: member.arNumber,
                                            pointsGoTo:
                                                member.privilegeNavisionId,
                                            cart,
                                            language: i18n.language,
                                            total,
                                            // TODO: PHONE NUMBER?
                                        },
                                    },
                                })
                            } catch (err) {
                                setError(err.message)
                            }
                        }}
                    >
                        Complete my order
                    </button> */}
                    {/* {membership && !membership?.isTrip && (
                    <div className="balanceAmount">
                        Redeemable Balance:{' '}
                        <FormatCurrency>
                            {currency(balance).subtract(total).value}
                        </FormatCurrency>
                    </div>
                )} */}
                </div>
            </div>
        </div>
    )
}
