import React, { createContext, useContext, useEffect, useState } from 'react'

import { DateTime } from 'luxon'
import { useQuery } from '@apollo/client'
import { GET_CAMPAIGN } from 'operations/Campaign'
import Loader from 'components/Loader'
import { useAuthentication } from './useAuthentication'

const CampaignContext = createContext()

export default function CampaignProvider({ children }) {
    const { user } = useAuthentication()

    console.log({ user })

    const { error, loading, data } = useQuery(GET_CAMPAIGN, {
        skip: !user?.userType,
    })

    // useEffect(() => {
    //     if (data?.privilege_campaign) {
    const DEADLINE = data?.privilege_campaign
        ? DateTime.fromISO(data?.privilege_campaign?.earningDeadline)
        : DateTime.now()

    const CURRENT_TIME = DateTime.now()

    const { months: monthsRemaining } = DEADLINE
        ? DEADLINE.diff(CURRENT_TIME, 'months').toObject()
        : {}

    return (
        <CampaignContext.Provider
            value={{
                deadline: DEADLINE,
                monthsRemaining: Math.ceil(monthsRemaining || 0),
            }}
        >
            {children}
        </CampaignContext.Provider>
    )
}

export const useCampaign = () => useContext(CampaignContext)
