import React, { useState, useEffect, useRef } from 'react'
// import PropTypes from 'prop-types'
import validator from 'validator'

import { useForm } from './FormProvider'
import FieldContainer from './FieldContainer'
import owasp from 'owasp-password-strength-test'
// import Blur from 'components/Blur'

import './field.scss'

const INPUT_TYPES = {
    text: 'text',
    tel: 'text',
    email: 'text',
    select: 'select',
    textarea: null,
    password: 'password',
    decimal: 'text',
    url: 'text',
    checkbox: 'checkbox',
    pin: 'password',
    numeric: 'text',
}

const INPUT_MODES = {
    text: 'text',
    tel: 'tel',
    email: 'email',
    select: null,
    textarea: 'text',
    password: 'text',
    decimal: 'decimal',
    url: 'url',
    checkbox: 'text',
    pin: 'numeric',
    numeric: 'numeric',
}

const FORMATTING = {
    text: (value) => value,
    textarea: (value) => value,
    select: (value) => value,
    tel: (value) => formatPhone(value),
    password: (value) => value,
    decimal: (value) => (value || '').toString().replace(/[^0-9.]/g, ''),
    url: (value) => value,
    email: (value) => value,
    uppercase: (value) => (value || '').toUpperCase(),
    alpha: (value) => (value || '').replace(/[^A-Za-z]/g, ''),
    checkbox: (value) => value,
    postal: (value) => value,
    pin: (value) => value,
    numeric: (value) => (value || '').replace(/[^0-9]/g, ''),
}

const RAW_FORMATTING = {
    text: (value) => value,
    textarea: (value) => value,
    select: (value) => value,
    tel: (value) => (value || '').replace(/\D/g, ''),
    password: (value) => value,
    decimal: (value) => (value || '').toString().replace(/[^0-9.]/g, ''),
    url: (value) => value,
    email: (value) => value,
    uppercase: (value) => (value || '').toUpperCase(),
    alpha: (value) => (value || '').replace(/[^A-Za-z]/g, ''),
    checkbox: (value) => value,
    postal: (value) => value,
    pin: (value) => value,
    numeric: (value) => (value || '').replace(/[^0-9]/g, ''),
}

const ERRORS = {
    tel: (value) => !validator.isMobilePhone(value, 'en-US'),
    password: (value) => checkOwaspErrors(value),
    url: (value) => !validator.isURL(value, { require_protocol: true }),
    email: (value) => !validator.isEmail(value),
    postal: (value) =>
        !validator.isPostalCode(value, 'CA') &&
        !validator.isPostalCode(value, 'US'),
    pin: (value) => value.length < 4,
}

function checkOwaspErrors(value) {
    const result = owasp.test(value)
    // console.log(result)
    if (result.errors.length > 0) return true
    return false
}

// const VALIDATIONS = {
//     text: () => true,
//     email: (value) => {
//         validationError(validator.isEmail(value), 'Please enter a valid email address.')
//     }
// }

// function validationError(validation, errorMessage) {
//     return {
//         pass: validation,
//         message: validation ? '' : errorMessage
//     }
// }

function formatPhone(value) {
    let phone = value.replace(/\D/g, '')
    const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/)
    if (match) {
        phone = `${match[2] ? '(' : ''}${match[1]}${match[2] ? ') ' : ''}${
            match[2]
        }${match[3] ? '-' : ''}${match[3]}`
    }
    return phone
}

export default function Field({
    name,
    id,
    label,
    helper = '',
    type = 'text',
    defaultValue = '',
    autoComplete = 'on',
    format = [],
    maxLength,
    onChange,
    options = [],
    noBlankOption = false,
    noOptional = false,
    validate = {},
    required,
    error,
    errorMessage,
    focus,
    disabled = false,
    onKeyPress,
    onFocus,
    onBlur,
    className,
}) {
    const inputRef = useRef(null)

    const [isFocused, setFocus] = useState(false)
    const [hasError, setHasError] = useState(false)
    const { inputs, setInput, unsetInput } = useForm()

    // const hasId = id > -1
    const hasId = id && (id.length > 0 || id > 0)

    // On init, set object name
    useEffect(() => {
        if (!inputs[name] || (hasId && !inputs[name][id])) {
            let value = FORMATTING[type](defaultValue)
            format &&
                format.map((format) => (value = FORMATTING[format](value)))
            let rawValue = RAW_FORMATTING[type](value)
            format &&
                format.map(
                    (format) => (rawValue = RAW_FORMATTING[format](value)),
                )

            setInput(
                {
                    [name]: {
                        value,
                        rawValue,
                    },
                },
                id,
            )
        }

        return () => {
            unsetInput(name, id)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (inputRef.current && focus) {
            inputRef.current.focus()
        }
        // eslint-disable-next-line
    }, [inputRef])

    const inputId = `f_${name}${hasId ? `_${id}` : ''}`
    const inputValues = hasId && inputs[name] ? inputs[name][id] : inputs[name]

    let value = inputValues ? inputValues.value : ''
    format && format.map((format) => (value = FORMATTING[format](value)))

    const El = type === 'textarea' ? 'textarea' : 'input'

    return (
        <FieldContainer
            inputId={inputId}
            label={label}
            helper={helper}
            isFocused={isFocused}
            isFilled={inputValues && inputValues.value ? true : false}
            isTextarea={type === 'textarea'}
            isCheckbox={type === 'checkbox'}
            required={required}
            error={error || hasError}
            noOptional={noOptional || noBlankOption}
            disabled={disabled}
            className={className}
        >
            {type === 'checkbox' && (
                <>
                    <div
                        className={`checkbox ${
                            value ? 'checkbox--checked' : 'checkbox--unchecked'
                        }`}
                    >
                        <i className="material-icons">
                            {value ? 'check_box' : 'check_box_outline_blank'}
                        </i>
                    </div>
                    <input
                        ref={inputRef}
                        type="checkbox"
                        onFocus={() => setFocus(true)}
                        onBlur={() => setFocus(false)}
                        value={inputValues ? inputValues.value : ''}
                        disabled={disabled}
                        name={hasId ? name + '_' + id : name}
                        onChange={(e) => {
                            setInput(
                                {
                                    [name]: {
                                        value: e.target.checked,
                                        rawValue: e.target.checked,
                                    },
                                },
                                id,
                            )
                            onChange &&
                                onChange({
                                    target: {
                                        name,
                                        id,
                                        value: e.target.checked,
                                        rawValue: e.target.checked,
                                    },
                                })
                        }}
                    />
                </>
            )}
            {type === 'select' && (
                <select
                    ref={inputRef}
                    id={inputId}
                    name={name}
                    onFocus={() => setFocus(true)}
                    onBlur={() => {
                        setFocus(false)
                        if (required && !value) return setHasError(true)
                        return setHasError(false)
                    }}
                    value={inputValues ? inputValues.value : ''}
                    disabled={disabled}
                    onChange={(e) => {
                        const value = e.target.value
                        const rawValue = value

                        setInput(
                            {
                                [name]: {
                                    value,
                                    rawValue,
                                },
                            },
                            id,
                        )

                        onChange &&
                            onChange({
                                target: {
                                    name,
                                    id,
                                    value,
                                    rawValue,
                                },
                            })
                    }}
                >
                    {noBlankOption ? null : <option value=""></option>}
                    {options &&
                        options.map((obj, i) => {
                            const option = Array.isArray(obj) ? obj[1] : obj
                            const optionValue = Array.isArray(obj)
                                ? obj[0]
                                : obj

                            return (
                                <option key={i} value={optionValue}>
                                    {option}
                                </option>
                            )
                        })}
                </select>
            )}
            {!['checkbox', 'select'].includes(type) && (
                <El
                    ref={inputRef}
                    id={inputId}
                    name={name}
                    type={INPUT_TYPES[type]}
                    inputMode={INPUT_MODES[type]}
                    disabled={disabled}
                    onFocus={() => {
                        setFocus(true)
                        onFocus && onFocus()
                    }}
                    onBlur={() => {
                        setFocus(false)
                        if (ERRORS[type] && value) {
                            const error = ERRORS[type](value)
                            if (error) return setHasError(true)
                            return setHasError(false)
                        }
                        if (required && !value) return setHasError(true)
                        onBlur && onBlur()
                        return setHasError(false)
                    }}
                    value={value}
                    onKeyDown={(e) => onKeyPress && onKeyPress(e)}
                    onChange={(e) => {
                        let value = FORMATTING[type](e.target.value)
                        format &&
                            format.map(
                                (format) => (value = FORMATTING[format](value)),
                            )
                        let rawValue = RAW_FORMATTING[type](value)
                        format &&
                            format.map(
                                (format) =>
                                    (rawValue = RAW_FORMATTING[format](value)),
                            )

                        if (maxLength && rawValue.length > maxLength) {
                            return setInput({
                                [name]: inputValues,
                            })
                        }

                        setInput(
                            {
                                [name]: {
                                    value,
                                    rawValue,
                                },
                            },
                            id,
                        )

                        onChange &&
                            onChange({
                                target: {
                                    name,
                                    id,
                                    value,
                                    rawValue,
                                },
                            })
                    }}
                    autoComplete={
                        autoComplete === 'off' ? 'nope' : autoComplete
                    }
                    rows={type === 'textarea' ? 10 : null}
                    cols={type === 'textarea' ? 2 : null}
                    className={`${
                        type === 'tel' && process.env.REACT_APP_BLUR
                            ? 'blur'
                            : ''
                    }`}
                />
            )}
            {(error || hasError) && (
                <div className="field__error">{errorMessage}</div>
            )}
        </FieldContainer>
    )
}

// Field.propTypes = {
//     name: PropTypes.string.isRequired,
//     id: PropTypes.number,
//     type: PropTypes.string,
//     label: PropTypes.string,
//     helper: PropTypes.string,
//     inputMode: PropTypes.string,
//     // defaultValue: PropTypes.string,
//     autoComplete: PropTypes.string,
//     format: PropTypes.array,
//     maxLength: PropTypes.number,
//     options: PropTypes.array,
//     noBlankOption: PropTypes.bool,
//     validate: PropTypes.object,
//     required: PropTypes.bool,
//     onChange: PropTypes.func,
// }

// type values: [text, checkbox, email, hidden, number, password, radio, search, tel, time, url, week]
// inputMode values: [none, text (default), decimal, numeric, tel, search, email, url]
