import React, { useState, useEffect } from 'react'

import validator from 'validator'
// import { getProvinces, getLanguages } from './functions'
import { useTranslation, Trans } from 'react-i18next'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'

import {
    GET_PREVIOUS_ACCOUNTS_RECEIVABLE,
    GET_TEMP_MEMBERS,
} from 'operations/Members'
// import { GET_INSTALLERS } from 'operations/Installer'
import { GET_STORE, STORE_SEARCH } from 'operations/Store'
// NOTE: The immediately following imports are from newer structure copied from AP consumer rebates site
import Installer from 'components/Installer'
import Form, { Field, Submit } from 'components/Form'
import { Autocomplete } from 'components/Form/Autocomplete'

import RegistrationLock from './RegistrationLock'

import { v4 as uuid } from 'uuid'
import { useAuthentication } from '../../hooks/useAuthentication'
// import RegistrationLockProvider from 'hooks/registrationLock'
import HR from 'components/HR'

// import { useCompany } from 'hooks/company'

import './register.scss'
import { CREATE_STAGING_RECORD } from 'operations/Staging'
import Loader from 'components/Loader'
import {
    GET_DEPARTURE_CITIES,
    GET_DEPARTURE_CITIES_DISTANCE,
    GET_TRIPS,
} from 'operations/Trip'
import Map from './Map'
import Formatting from 'components/Formatting'
import { useHistory, useParams } from 'react-router'
import { useSalesRep } from 'hooks/salesRep'
import SalesRep from 'components/SalesRep'
import { useCompany } from 'hooks/company'
import { useGlobalState } from 'hooks/useGlobalState'
import { ME, UPDATE_USER } from 'operations/Auth'
import { nanoid } from 'nanoid'
import { useMajorAccount } from 'hooks/majorAccount'
import { useStore } from 'hooks/store'

// import Loader from '../../components/Loader'

// import { IS_ASP_REGISTERED } from '../../operations/Registrations'

// import Form, { FormContext, Select, Input, Submit } from '../../components/Forms'

const TRIP_ENROLLMENT_OPEN = false

export default function Register({ onComplete }) {
    const company = useCompany()

    const { store } = useGlobalState()
    const { majorAccount, refetch: refetchMajorAccount } = useMajorAccount()
    const { storeMajorAccount } = useStore()

    const [preloadAsp, setPreloadAsp] = useState(false)

    const { t, i18n } = useTranslation()
    const { user } = useAuthentication()
    const { userType } = user

    const [salesRep, setSalesRep] = useSalesRep()

    const history = useHistory()
    const { aspId } = useParams()

    // const { company } = useCompany()

    const [ossNumber, setOssNumber] = useState('')
    const [ossName, setOssName] = useState('')
    const [ossTitle, setOssTitle] = useState('')
    const [ossEmail, setOssEmail] = useState('')
    const [salesRepFirstName, setSalesRepFirstName] = useState('')
    const [salesRepLastName, setSalesRepLastName] = useState('')

    const { data: privilegeTrips } = useQuery(GET_TRIPS)

    const {
        // error: storeError,
        // loading: storeLoading,
        data: storeData,
    } = useQuery(GET_STORE, {
        variables: {
            filter: {
                ...(user.userType === 'STORE' && {
                    navisionId: { eq: user.navisionId },
                }),
                ...(user.userType === 'SALES_REP' &&
                    !majorAccount &&
                    store && {
                        navisionId: { eq: store.navisionId },
                    }),
                // ...(!['STORE', 'SALES_REP'].includes(user.userType) && {
                //     navisionId: { eq: 'EMPTY' },
                // }),
            },
        },
        // skip: !['STORE', 'SALES_REP'].includes(user.userType),
        skip: majorAccount,
    })

    const [updateSalesRep] = useMutation(UPDATE_USER, {
        refetchQueries: [{ query: ME }],
    })

    const [errors, setErrors] = useState({})
    const {
        // loading: aspIdLoading,
        data: aspIdData,
    } = useQuery(GET_TEMP_MEMBERS, {
        fetchPolicy: 'network-only',
        skip: !aspId,
        variables: { search: `@id:{${aspId}}` },
    })
    const [aspSearch, { loading: aspLoading, data: aspData }] = useLazyQuery(
        GET_TEMP_MEMBERS,
        { fetchPolicy: 'network-only' },
    )
    const [asp, setAsp] = useState()
    const [aspNotFound, setAspNotFound] = useState()
    const [selectedProgram, setSelectedProgram] = useState()

    const [registrationLocked, setRegistrationLocked] = useState(false)

    // points pooling installers query (to be modified)
    // const [ poolPoints, setPoolPoints ] = useState('NOT_SET')
    const [
        pointsPoolingAspSearch,
        { loading: pointsPoolingAspLoading, data: pointsPoolingAspData },
    ] = useLazyQuery(GET_TEMP_MEMBERS, { fetchPolicy: 'network-only' })
    const [pointsPoolingAsp, setPointsPoolingAsp] = useState()
    const [
        pointsPoolingAspNotFound,
        // setPointsPoolingAspNotFound
    ] = useState()
    const [ignorePreviousPointsPooling, setIgnorePreviousPointsPooling] =
        useState()

    const [
        storeSearch,
        { loading: storeSearchLoading, data: storeSearchData },
    ] = useLazyQuery(STORE_SEARCH, { fetchPolicy: 'network-only' })
    const [customerAccounts, setCustomerAccounts] = useState([])

    const {
        // error: departureCitiesError,
        // loading: departureCitiesLoading,
        data: departureCitiesData,
    } = useQuery(GET_DEPARTURE_CITIES)
    const {
        // error: departureCitiesDistanceError,
        // loading: departureCitiesDistanceLoading,
        data: departureCitiesDistanceData,
    } = useQuery(GET_DEPARTURE_CITIES_DISTANCE, {
        skip: !asp || (asp && (!asp.latitude || !asp.longitude)),
        variables: {
            latitude: asp && asp.latitude,
            longitude: asp && asp.longitude,
        },
    })

    const {
        // error: previousAccountsReceivableError,
        loading: previousAccountsReceivableLoading,
        data: previousAccountsReceivableData,
    } = useQuery(GET_PREVIOUS_ACCOUNTS_RECEIVABLE, {
        variables: {
            id: asp && asp.id,
        },
        skip: !asp,
    })

    const [departure, setDeparture] = useState()

    const [createStagingRecord] = useMutation(CREATE_STAGING_RECORD)

    // Preload ASP (from re-enroll)
    useEffect(() => {
        if (
            !preloadAsp &&
            aspId &&
            aspIdData &&
            aspIdData.privilege_tempMemberSearch &&
            aspIdData.privilege_tempMemberSearch.length === 1
        ) {
            setAsp(aspIdData.privilege_tempMemberSearch[0])
            setPreloadAsp(true)
        }
    }, [aspId, aspIdData, preloadAsp])

    // AR Numbers
    useEffect(() => {
        if (
            storeData &&
            !previousAccountsReceivableLoading &&
            !previousAccountsReceivableData
        ) {
            // Either a Store has been selected, or a store is enrolling; and this installer has no previous AR data
            const { __typename, ...rest } = storeData.store
            setCustomerAccounts([{ ...rest, locked: true }])
        } else if (previousAccountsReceivableData) {
            // console.log('PREVIOUS', previousAccountsReceivableData)
            // NO previous AR #'s or the previous primary store is not the same as entering
            if (
                storeData &&
                (previousAccountsReceivableData
                    .privilege_previousAccountsReceivable.length < 1 ||
                    (previousAccountsReceivableData
                        .privilege_previousAccountsReceivable.length > 0 &&
                        storeData.store.navisionId !==
                            previousAccountsReceivableData
                                .privilege_previousAccountsReceivable[0]
                                .navisionId))
            ) {
                const { __typename, ...rest } = storeData.store
                setCustomerAccounts([{ ...rest, locked: true }])
            }
            // Previous AR #'s exist and the primary is the same as entering
            // OR
            // MajorAccount enrollment
            if (
                (!majorAccount &&
                    previousAccountsReceivableData
                        .privilege_previousAccountsReceivable.length > 0 &&
                    storeData.store.navisionId ===
                        previousAccountsReceivableData
                            .privilege_previousAccountsReceivable[0]
                            .navisionId) ||
                majorAccount
            ) {
                const customerAccounts = []
                previousAccountsReceivableData.privilege_previousAccountsReceivable.map(
                    ({ __typename, ...rest }, i) =>
                        customerAccounts.push({
                            ...rest,
                            locked: i === 0 && !majorAccount ? true : false,
                        }),
                )
                setCustomerAccounts(customerAccounts)
            }
        }
    }, [
        storeData,
        previousAccountsReceivableLoading,
        previousAccountsReceivableData,
        majorAccount,
    ])

    useEffect(() => {
        setCustomerAccounts([])
    }, [aspNotFound])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [errors])
    // const provinces = getProvinces(i18n.language)
    // const languages = getLanguages(i18n.language)

    const trips = []

    let bothTrips = false
    if (majorAccount) {
        if (
            !majorAccount?.requirePrimaryEnrollment &&
            !majorAccount?.requireSecondaryEnrollment
        )
            bothTrips = true
        if (
            majorAccount?.requirePrimaryEnrollment &&
            majorAccount?.primaryInstallerNavisionId ===
                asp?.installerNavisionId
        )
            bothTrips = true
    }

    if (
        TRIP_ENROLLMENT_OPEN &&
        majorAccount?.benefitsAllowed?.includes('BOTH_TRIPS') &&
        bothTrips
    ) {
        trips.push([
            'BOTH_TRIPS',
            t('bothTrips', 'Passport: BOTH TRIP LOCATIONS'),
        ])
    }

    TRIP_ENROLLMENT_OPEN &&
        privilegeTrips &&
        privilegeTrips.trips &&
        privilegeTrips.trips.map((trip) => {
            // For group accounts
            // If major account doesn't allow trips, block trips
            if (majorAccount && !majorAccount.benefitsAllowed?.includes('TRIP'))
                return false

            // Block other trip from being enrolled for secondary locations
            if (
                majorAccount &&
                majorAccount.primaryBenefitSelected &&
                majorAccount.primaryBenefitSelected !== trip.slug &&
                (!majorAccount.secondaryInstallerNavisionId ||
                    (majorAccount.secondaryInstallerNavisionId &&
                        majorAccount.secondaryInstallerNavisionId !==
                            asp?.installerNavisionId))
            ) {
                return false
            }

            // If the second-primary installer is being enrolled, force trip to be the opposite of the primary asp
            // if (
            //     majorAccount &&
            //     majorAccount.secondaryInstallerNavisionId &&
            //     asp &&
            //     asp.installerNavisionId &&
            //     majorAccount.secondaryInstallerNavisionId ===
            //         asp.installerNavisionId &&
            //     majorAccount.primaryBenefitSelected === trip.slug
            // ) {
            //     console.log(
            //         'BLOCKED TRIP',
            //         'Block primary trip (this is the second-primary enrollment)',
            //         trip.slug,
            //     )
            //     return false
            // }
            // If a store is doing the enrollment, block trip enrollment for major accounts unless expressly allowed to enroll for trips
            if (
                storeMajorAccount &&
                !storeMajorAccount.storeBenefitsAllowed?.includes('TRIP')
            ) {
                return false
            }

            // If major account has both primary and secondary NT #s
            if (
                majorAccount?.primaryInstallerNavisionId &&
                majorAccount?.secondaryInstallerNavisionId &&
                asp
            ) {
                // If enrolling secondary, do not allow trips, only allow giftcards
                if (
                    majorAccount?.secondaryInstallerNavisionId ===
                    asp.installerNavisionId
                ) {
                    return false
                }
            }

            if (majorAccount?.primaryBothTripsSelected)
                return trips.push([
                    trip.slug,
                    t('bothTrips', 'Passport: BOTH TRIP LOCATIONS'),
                ])

            return trips.push([
                trip.slug,
                t('passport', 'Passport:') +
                    ' ' +
                    trip[`location_${i18n.language}`],
            ])
        })

    const departureCities =
        departureCitiesData &&
        departureCitiesData.privilege_departureCities &&
        departureCitiesData.privilege_departureCities.map((departureCity) => [
            departureCity.navisionId,
            departureCity[`city_${i18n.language}`],
        ])

    const departureDistance =
        departure &&
        departureCitiesDistanceData &&
        departureCitiesDistanceData.privilege_departureCitiesDistance
            ? departureCitiesDistanceData.privilege_departureCitiesDistance.find(
                  (departureCity) =>
                      departureCity.airportCode === departure.airportCode,
              )
            : null

    let allowTripTravellerSelection = false
    if (!majorAccount && !storeMajorAccount) allowTripTravellerSelection = true
    if (majorAccount) {
        if (
            asp?.installerNavisionId ===
            majorAccount?.primaryInstallerNavisionId
        )
            allowTripTravellerSelection = true
        if (
            asp?.installerNavisionId ===
            majorAccount?.secondaryInstallerNavisionId
        )
            allowTripTravellerSelection = true

        if (!majorAccount?.primaryInstallerNavisionId)
            allowTripTravellerSelection = true
    }

    let allowGiftCardEnrollment = false
    if (!majorAccount) allowGiftCardEnrollment = true
    if (majorAccount) {
        if (majorAccount.benefitsAllowed?.includes('GIFT_CARD')) {
            // If both benefits are allowed, as long as it's not the primary location
            if (majorAccount.allowBothBenefits) {
                if (
                    majorAccount.secondaryInstallerNavisionId ===
                    asp?.installerNavisionId
                )
                    allowGiftCardEnrollment = true
                if (
                    majorAccount.primaryInstallerNavisionId !==
                    asp?.installerNavisionId
                )
                    allowGiftCardEnrollment = true
            }

            // If both benefits are NOT allowed, only the primary location or other locations if that's what the primary selected
            if (!majorAccount.allowBothBenefits) {
                if (
                    majorAccount.primaryInstallerNavisionId ===
                    asp?.installerNavisionId
                )
                    allowGiftCardEnrollment = true
                if (
                    majorAccount.primaryInstallerNavisionId !==
                        asp?.installerNavisionId &&
                    majorAccount.primaryBenefitSelected === 'GIFT_CARD'
                )
                    allowGiftCardEnrollment = true
            }

            if (!majorAccount.requirePrimaryEnrollment)
                allowGiftCardEnrollment = true
        }
    }
    if (storeMajorAccount?.storeBenefitsAllowed?.includes('GIFT_CARD'))
        allowGiftCardEnrollment = true

    let displayPointsPooling = false
    if (majorAccount && majorAccount.primaryInstallerNavisionId) {
        if (majorAccount.allowBothBenefits) {
            if (
                majorAccount.primaryInstallerNavisionId !==
                    asp?.installerNavisionId &&
                majorAccount.secondaryInstallerNavisionId !==
                    asp?.installerNavisionId
            )
                displayPointsPooling = true
        }
        if (!majorAccount.allowBothBenefits) {
            if (
                majorAccount.primaryInstallerNavisionId !==
                asp?.installerNavisionId
            )
                displayPointsPooling = true
        }
    }

    // console.log('AR#s', customerAccounts, previousAccountsReceivableData)

    let arNumbersChanged = false
    customerAccounts?.map((customerAccount) => {
        const previousCustomerAccount =
            previousAccountsReceivableData?.privilege_previousAccountsReceivable?.find(
                (account) => account.id === customerAccount.id,
            )
        if (!previousCustomerAccount) return (arNumbersChanged = true)
        if (previousCustomerAccount.arNumber !== customerAccount.arNumber)
            return (arNumbersChanged = true)
        return null
    })

    return <div>Enrollments are closed</div>

    // let aspIsInGroup = false
    // if (storeMajorAccount?.account === asp?.groupName) aspIsInGroup = true
    return (
        <div className="contentContainer">
            <Form
                // className='grid'
                style={{ paddingTop: '2rem' }}
                onSubmit={async ({ rawInputs: inputs, enableSubmit }) => {
                    try {
                        const formErrors = {}

                        if (!asp && aspNotFound && !inputs.asp)
                            formErrors.asp = true

                        if (user.userType !== 'SALES_REP') {
                            // console.log(
                            //     'SALES REP VALIDATION',
                            //     salesRep,
                            //     inputs.ossName,
                            // )
                            if (
                                !salesRep &&
                                (validator.isEmpty(inputs.ossName || '') ||
                                    (inputs.ossName || '').split(' ').length <
                                        2)
                            )
                                formErrors.ossName = true
                            if (
                                !salesRep &&
                                validator.isEmpty(inputs.ossTitle || '')
                            )
                                formErrors.ossTitle = true
                            if (
                                !salesRep &&
                                inputs.ossEmail &&
                                !validator.isEmpty(inputs.ossEmail || '') &&
                                !validator.isEmail(inputs.ossEmail)
                            )
                                formErrors.ossEmail = true
                        }
                        if (user.userType === 'SALES_REP') {
                            if (!salesRep.fullName) {
                                if (!inputs.salesRepFirstName)
                                    formErrors.salesRepFirstName = true
                                if (!inputs.salesRepLastName)
                                    formErrors.salesRepLastName = true
                            }
                        }

                        // Customer info
                        if (
                            !inputs.businessName ||
                            validator.isEmpty(inputs.businessName)
                        )
                            formErrors.businessName = true
                        if (
                            !inputs.phone ||
                            validator.isEmpty(inputs.phone) ||
                            !validator.isMobilePhone(inputs.phone)
                        )
                            formErrors.phone = true
                        if (
                            inputs.contactName &&
                            inputs.contactName.length > 0 &&
                            (inputs.contactName || '').split(' ').length < 2
                        )
                            formErrors.contactName = true
                        // if (validator.isEmpty(inputs.contactName)) formErrors.contactName = true

                        if (
                            !inputs.address ||
                            validator.isEmpty(inputs.address)
                        )
                            formErrors.address = true
                        if (!inputs.city || validator.isEmpty(inputs.city))
                            formErrors.city = true
                        if (
                            !inputs.province ||
                            validator.isEmpty(inputs.province)
                        )
                            formErrors.province = true
                        if (
                            !inputs.postal ||
                            validator.isEmpty(inputs.postal) ||
                            !validator.isPostalCode(inputs.postal, 'CA')
                        )
                            formErrors.postal = true

                        if (
                            !inputs.language ||
                            validator.isEmpty(inputs.language)
                        )
                            formErrors.language = true
                        if (
                            !inputs.ownerName ||
                            validator.isEmpty(inputs.ownerName) ||
                            (inputs.ownerName || '').split(' ').length < 2
                        )
                            formErrors.ownerName = true
                        if (
                            !inputs.email ||
                            validator.isEmpty(inputs.email) ||
                            !validator.isEmail(inputs.email)
                        )
                            formErrors.email = true
                        if (
                            inputs.email &&
                            (inputs.email.includes('@napacanada') ||
                                inputs.email.includes('@altrom') ||
                                inputs.email.includes('@autocamping'))
                        ) {
                            formErrors.email = true
                            formErrors.emailDomain = true
                        }
                        if (
                            inputs.cellPhone &&
                            !validator.isEmpty(inputs.cellPhone) &&
                            !validator.isMobilePhone(inputs.cellPhone)
                        )
                            formErrors.cellPhone = true

                        // Privilege membership benefit
                        if (
                            !inputs.program ||
                            validator.isEmpty(inputs.program)
                        )
                            formErrors.program = true
                        if (
                            inputs.program &&
                            !validator.isEmpty(inputs.program)
                        ) {
                            // If TRIP
                            if (
                                ['TRIP', 'BOTH_TRIPS'].includes(selectedProgram)
                            ) {
                                if (allowTripTravellerSelection) {
                                    const totalTravellers19Plus = (
                                        inputs.totalTravellers19Plus || ''
                                    ).trim()
                                    if (
                                        !validator.isNumeric(
                                            totalTravellers19Plus,
                                        ) ||
                                        Number(totalTravellers19Plus || 0) <
                                            1 ||
                                        Number(totalTravellers19Plus || 0) > 50
                                    )
                                        formErrors.totalTravellers19Plus = true
                                    const totalTravellersUnder19 = (
                                        inputs.totalTravellersUnder19 || ''
                                    ).trim()
                                    if (
                                        !validator.isNumeric(
                                            totalTravellersUnder19,
                                        ) ||
                                        Number(totalTravellersUnder19 || 0) > 50
                                    )
                                        formErrors.totalTravellersUnder19 = true

                                    if (
                                        inputs.tripExtension instanceof Object
                                    ) {
                                        Object.keys(inputs.tripExtension).map(
                                            (key) => {
                                                if (inputs.tripExtension[key]) {
                                                    const totalTravellers19Plus =
                                                        inputs.extensionTotalTravellers19Plus[
                                                            key
                                                        ].trim()
                                                    if (
                                                        !validator.isNumeric(
                                                            totalTravellers19Plus,
                                                        ) ||
                                                        Number(
                                                            totalTravellers19Plus,
                                                        ) < 1 ||
                                                        Number(
                                                            totalTravellers19Plus,
                                                        ) > 50
                                                    ) {
                                                        if (
                                                            !formErrors.extensionTotalTravellers19Plus
                                                        )
                                                            formErrors.extensionTotalTravellers19Plus =
                                                                {}
                                                        formErrors.extensionTotalTravellers19Plus[
                                                            key
                                                        ] = true
                                                    }
                                                    const totalTravellersUnder19 =
                                                        inputs.extensionTotalTravellersUnder19[
                                                            key
                                                        ].trim()
                                                    if (
                                                        !validator.isNumeric(
                                                            totalTravellersUnder19,
                                                        ) ||
                                                        Number(
                                                            totalTravellersUnder19,
                                                        ) > 50
                                                    ) {
                                                        if (
                                                            !formErrors.extensionTotalTravellersUnder19
                                                        )
                                                            formErrors.extensionTotalTravellersUnder19 =
                                                                {}
                                                        formErrors.extensionTotalTravellersUnder19[
                                                            key
                                                        ] = true
                                                    }
                                                }

                                                return null
                                            },
                                        )
                                    }

                                    if (
                                        validator.isEmpty(inputs.departureCity)
                                    ) {
                                        formErrors.departureCity = true
                                    }
                                }
                            }
                            // BOTH_TRIPS
                            // If TRIP
                            if (['BOTH_TRIPS'].includes(selectedProgram)) {
                                const totalTravellers19Plus =
                                    inputs[
                                        'totalTravellers19Plus:alternate'
                                    ][1].trim()
                                if (
                                    !validator.isNumeric(
                                        totalTravellers19Plus,
                                    ) ||
                                    Number(totalTravellers19Plus || 0) < 1 ||
                                    Number(totalTravellers19Plus || 0) > 50
                                )
                                    formErrors[
                                        'totalTravellers19Plus:alternate'
                                    ] = true
                                const totalTravellersUnder19 =
                                    inputs[
                                        'totalTravellersUnder19:alternate'
                                    ][1].trim()
                                if (
                                    !validator.isNumeric(
                                        totalTravellersUnder19,
                                    ) ||
                                    Number(totalTravellersUnder19 || 0) > 50
                                )
                                    formErrors[
                                        'totalTravellersUnder19:alternate'
                                    ] = true
                            }
                        }

                        // Accounts receivable
                        if (
                            !customerAccounts ||
                            (customerAccounts && customerAccounts.length < 1)
                        )
                            formErrors.customerAccounts = true
                        if (customerAccounts && customerAccounts.length > 0) {
                            if (!inputs.accountNumber) {
                                formErrors.customerAccounts = true
                            } else {
                                customerAccounts.map((store, i) => {
                                    if (
                                        validator.isEmpty(
                                            inputs.accountNumber[
                                                store.id || ''
                                            ].trim(),
                                        )
                                    ) {
                                        if (!formErrors.accountNumber)
                                            formErrors.accountNumber = {}
                                        formErrors.accountNumber[i] = true
                                    }
                                    return null
                                })
                            }
                        }

                        // If there are any errors, return
                        if (Object.keys(formErrors).length > 0) {
                            enableSubmit()
                            return setErrors(formErrors)
                        }

                        setErrors({})

                        let commentsArray = []
                        let aspData = {}
                        if (!aspNotFound && asp) {
                            aspData = {
                                installerId: Number(asp.id),
                                installerNavisionId: asp.installerNavisionId,
                                legalName: (asp.legalName || '').trim(),
                                businessName: (asp.businessName || '').trim(),
                                address: (asp.address || '').trim(),
                                city: (asp.city || '').trim(),
                                province: asp.province,
                                postal: (asp.postal || '').trim(),
                                phone: (asp.phone || '')
                                    .replace(/[^0-9]/g, '')
                                    .trim(),
                                email: (asp.privilegeEmail || '').trim(),
                                language: (asp.language || '').trim(),
                                ownerName: (asp.ownerName || '').trim(),
                                cellPhone: (asp.cellPhone || '')
                                    .replace(/[^0-9]/g, '')
                                    .trim(),
                                contactName: (asp.contactName || '').trim(),
                            }
                            if (asp.legalName !== inputs.legalName)
                                commentsArray.push(
                                    `LEGAL NAME: ${(
                                        inputs.legalName || ''
                                    ).trim()}`,
                                )
                            if (asp.businessName !== inputs.businessName)
                                commentsArray.push(
                                    `BUSINESS NAME: ${(
                                        inputs.businessName || ''
                                    ).trim()}`,
                                )
                            if (asp.address !== inputs.address)
                                commentsArray.push(
                                    `ADDRESS: ${(inputs.address || '').trim()}`,
                                )
                            if (asp.city !== inputs.city)
                                commentsArray.push(
                                    `CITY: ${(inputs.city || '').trim()}`,
                                )
                            if (asp.province !== inputs.province)
                                commentsArray.push(
                                    `PROVINCE: ${(
                                        inputs.province || ''
                                    ).trim()}`,
                                )
                            if (asp.postal !== inputs.postal)
                                commentsArray.push(
                                    `POSTAL: ${(inputs.postal || '').trim()}`,
                                )
                            if (asp.ownerName !== inputs.ownerName)
                                commentsArray.push(
                                    `OWNER NAME: ${(
                                        inputs.ownerName || ''
                                    ).trim()}`,
                                )
                            if (asp.contactName !== inputs.contactName)
                                commentsArray.push(
                                    `CONTACT NAME: ${(
                                        inputs.contactName || ''
                                    ).trim()}`,
                                )
                            if (asp.phone !== inputs.phone)
                                commentsArray.push(
                                    `PHONE: ${inputs.phone
                                        .replace(/[^0-9]/g, '')
                                        .trim()}`,
                                )
                            if (asp.cellPhone !== inputs.cellPhone)
                                commentsArray.push(
                                    `CELL PHONE: ${(inputs.cellPhone || '')
                                        .replace(/[^0-9]/g, '')
                                        .trim()}`,
                                )
                            if (asp.privilegeEmail !== inputs.email)
                                commentsArray.push(
                                    `EMAIL: ${(inputs.email || '').trim()}`,
                                )
                            if (asp.language !== inputs.language)
                                commentsArray.push(
                                    `LANGUAGE: ${
                                        inputs.language === 'E'
                                            ? 'English'
                                            : 'French'
                                    }`,
                                )
                            if (arNumbersChanged)
                                commentsArray.push('AR NUMBER or STORE CHANGE')
                        }
                        if (aspNotFound) {
                            aspData = {
                                installerId: 0,
                                installerNavisionId: '',
                                legalName: (inputs.legalName || '').trim(),
                                businessName: (
                                    inputs.businessName || ''
                                ).trim(),
                                address: (inputs.address || '').trim(),
                                city: (inputs.city || '').trim(),
                                province: inputs.province,
                                postal: (inputs.postal || '').trim(),
                                phone: (inputs.phone || '')
                                    .replace(/[^0-9]/g, '')
                                    .trim(),
                                email: (inputs.email || '').trim(),
                                language: (inputs.language || '').trim(),
                                ownerName: (inputs.ownerName || '').trim(),
                                cellPhone: (inputs.cellPhone || '')
                                    .replace(/[^0-9]/g, '')
                                    .trim(),
                                contactName: (inputs.contactName || '').trim(),
                            }
                        }

                        if (aspData.phone && (aspData.phone + '').length === 10)
                            aspData.phone =
                                aspData.phone.substr(0, 3) +
                                '-' +
                                aspData.phone.substr(3, 3) +
                                '-' +
                                aspData.phone.substr(6, 4)

                        if (
                            aspData.cellPhone &&
                            (aspData.cellPhone + '').length === 10
                        )
                            aspData.cellPhone =
                                aspData.cellPhone.substr(0, 3) +
                                '-' +
                                aspData.cellPhone.substr(3, 3) +
                                '-' +
                                aspData.cellPhone.substr(6, 4)

                        const primaryStore = customerAccounts[0]

                        // Is the extension selected?
                        let extension = false
                        let extensionId = null
                        let extensionName = null
                        let extensionTotalTravellers19Plus = null
                        let extensionTotalTravellersUnder19 = null
                        let trip = null
                        let secondTrip = null

                        if (['TRIP', 'BOTH_TRIPS'].includes(selectedProgram)) {
                            if (selectedProgram === 'TRIP') {
                                trip =
                                    inputs.program &&
                                    privilegeTrips &&
                                    privilegeTrips.trips.find(
                                        (trip) => trip.slug === inputs.program,
                                    )
                            }
                            if (selectedProgram === 'BOTH_TRIPS') {
                                trip = privilegeTrips.trips[0]
                            }

                            if (inputs.tripExtension instanceof Object) {
                                Object.keys(inputs.tripExtension).map((key) => {
                                    if (inputs.tripExtension[key]) {
                                        extension = true

                                        const whichExtension =
                                            trip.extensions.find(
                                                (extension) =>
                                                    Number(extension.id) ===
                                                    Number(key),
                                            )
                                        extensionId = whichExtension.id
                                        extensionName = whichExtension.extension

                                        extensionTotalTravellers19Plus =
                                            Number(
                                                inputs
                                                    .extensionTotalTravellers19Plus[
                                                    key
                                                ],
                                            ) +
                                            Number(
                                                inputs
                                                    .extensionTotalTravellersUnder19[
                                                    key
                                                ],
                                            )
                                        extensionTotalTravellersUnder19 =
                                            Number(
                                                inputs
                                                    .extensionTotalTravellersUnder19[
                                                    key
                                                ],
                                            )
                                    }

                                    return null
                                })
                            }
                        }

                        if (selectedProgram === 'BOTH_TRIPS') {
                            secondTrip = {}
                            secondTrip.slug = privilegeTrips.trips[1]?.slug
                            secondTrip.travellers =
                                inputs['totalTravellers19Plus:alternate'] &&
                                inputs['totalTravellers19Plus:alternate'][1]
                                    ? inputs[
                                          'totalTravellers19Plus:alternate'
                                      ][1].trim()
                                    : 0
                            secondTrip.travellersKids =
                                inputs['totalTravellersUnder19:alternate'] &&
                                inputs['totalTravellersUnder19:alternate'][1]
                                    ? inputs[
                                          'totalTravellersUnder19:alternate'
                                      ][1].trim()
                                    : 0

                            if (commentsArray.length > 0)
                                commentsArray.push('---')
                            commentsArray.push(`[BOTH TRIPS REQUESTED]`)
                            commentsArray.push(
                                `Travellers over 19: ${secondTrip.travellers}`,
                            )
                            commentsArray.push(
                                `Travellers under 19: ${secondTrip.travellersKids}`,
                            )
                        }

                        // enableSubmit()
                        // return null
                        let majorAccountsPointsPooling = null
                        if (majorAccount && inputs.program) {
                            if (majorAccount.requirePrimaryEnrollment) {
                                majorAccountsPointsPooling =
                                    majorAccount['primaryInstaller']

                                if (
                                    majorAccount.allowBothBenefits &&
                                    selectedProgram === 'GIFT_CARD'
                                ) {
                                    majorAccountsPointsPooling =
                                        majorAccount['secondaryInstaller']
                                }
                            }
                        }

                        // console.log('FINAL INPUTS', inputs)

                        let pointsGoTo = null
                        if (asp?.privilegeNavisionId)
                            pointsGoTo = asp.privilegeNavisionId
                        if (pointsPoolingAsp?.privilegeNavisionId)
                            pointsGoTo = pointsPoolingAsp.privilegeNavisionId
                        if (
                            majorAccount &&
                            majorAccountsPointsPooling?.privilegeNavisionId
                        )
                            pointsGoTo =
                                majorAccountsPointsPooling.privilegeNavisionId

                        let isPointsPooling = false
                        if (
                            asp &&
                            asp.privilegeNavisionId &&
                            pointsGoTo !== asp.privilegeNavisionId
                        )
                            isPointsPooling = true

                        const { data } = await createStagingRecord({
                            variables: {
                                payload: {
                                    ...(majorAccount && {
                                        majorAccount: majorAccount.account,
                                    }),
                                    ...(majorAccount &&
                                        aspData &&
                                        majorAccount.primaryInstallerNavisionId &&
                                        majorAccount.primaryInstallerNavisionId ===
                                            aspData.installerNavisionId && {
                                            majorAccountEnrollmentType:
                                                'primary',
                                        }),
                                    ...(majorAccount &&
                                        aspData &&
                                        majorAccount.secondaryInstallerNavisionId &&
                                        majorAccount.secondaryInstallerNavisionId ===
                                            aspData.installerNavisionId && {
                                            majorAccountEnrollmentType:
                                                'secondary',
                                        }),
                                    memberType: 'installer',
                                    ...aspData,
                                    pointsGoTo,

                                    // contactName: inputs.contactName.trim(),
                                    // contactTitle: inputs.contactTitle.trim(),
                                    // ossNum: inputs.ossNum.trim(),
                                    // ossTitle: inputs.ossTitle.trim(),
                                    // ossName: inputs.ossName.trim(),
                                    // ossEmail: inputs.ossEmail.trim(),

                                    ossNumber: salesRep
                                        ? salesRep.number
                                        : inputs.ossNumber,
                                    ossTitle: salesRep
                                        ? salesRep.title
                                        : inputs.ossTitle,
                                    ossName: salesRep
                                        ? salesRep.fullName
                                        : inputs.ossName,
                                    ossEmail: salesRep
                                        ? salesRep.email
                                        : inputs.ossEmail,

                                    ...(user.userType === 'SALES_REP' && {
                                        ossName:
                                            salesRep && salesRep.fullName
                                                ? salesRep.fullName
                                                : inputs.salesRepFirstName +
                                                  ' ' +
                                                  inputs.salesRepLastName,
                                        ossEmail: salesRep && salesRep.email,
                                        ossTitle: 'Sales Rep',
                                    }),

                                    storeId: Number(primaryStore.id),
                                    storeNavisionId: primaryStore.navisionId,
                                    storeNum: primaryStore.napaNumber,
                                    arNum: primaryStore.arNumber,
                                    storeNum2: customerAccounts[1]
                                        ? customerAccounts[1].napaNumber
                                        : '',
                                    arNum2: customerAccounts[1]
                                        ? customerAccounts[1].arNumber.trim()
                                        : '',
                                    storeNum3: customerAccounts[2]
                                        ? customerAccounts[2].napaNumber
                                        : '',
                                    arNum3: customerAccounts[2]
                                        ? customerAccounts[2].arNumber.trim()
                                        : '',
                                    storeNum4: customerAccounts[3]
                                        ? customerAccounts[3].napaNumber
                                        : '',
                                    arNum4: customerAccounts[3]
                                        ? customerAccounts[3].arNumber.trim()
                                        : '',
                                    isInPrivilege:
                                        !aspNotFound && asp ? true : false,
                                    tempId:
                                        !aspNotFound && asp
                                            ? Number(asp.id)
                                            : null,
                                    privilegeNavisionId:
                                        !aspNotFound && asp
                                            ? asp.privilegeNavisionId
                                            : null,
                                    privilegeBenefit: [
                                        'TRIP',
                                        'BOTH_TRIPS',
                                    ].includes(selectedProgram)
                                        ? `Trip ${trip.location}`
                                        : 'Gift Card/Certificates',
                                    tripId: ['TRIP', 'BOTH_TRIPS'].includes(
                                        selectedProgram,
                                    )
                                        ? trip.id
                                        : null,

                                    ...(['TRIP', 'BOTH_TRIPS'].includes(
                                        selectedProgram,
                                    ) && { tripSlug: trip.slug }),
                                    ...(selectedProgram === 'BOTH_TRIPS' && {
                                        secondTrip,
                                    }),
                                    // ...(selectedProgram === 'TRIP' && {
                                    //     tripSlug:
                                    //         !majorAccount?.primaryBenefitSelected ||
                                    //         !majorAccount ||
                                    //         (majorAccount?.secondaryInstallerNavisionId &&
                                    //             majorAccount?.secondaryInstallerNavisionId ===
                                    //                 asp?.installerNavisionId)
                                    //             ? inputs.program
                                    //             : null,
                                    // }),
                                    // ...(selectedProgram === 'BOTH_TRIPS' && {
                                    //     tripSlug: trip.slug,
                                    // }),
                                    tripLocation: [
                                        'TRIP',
                                        'BOTH_TRIPS',
                                    ].includes(selectedProgram)
                                        ? trip.location
                                        : null,
                                    tripTravellersNum:
                                        !isPointsPooling &&
                                        ['TRIP', 'BOTH_TRIPS'].includes(
                                            selectedProgram,
                                        ) &&
                                        (!majorAccount?.primaryBenefitSelected ||
                                            !majorAccount)
                                            ? Number(
                                                  inputs.totalTravellers19Plus,
                                              ) +
                                              Number(
                                                  inputs.totalTravellersUnder19,
                                              )
                                            : 0,
                                    tripTravellersNumKids:
                                        !isPointsPooling &&
                                        ['TRIP', 'BOTH_TRIPS'].includes(
                                            selectedProgram,
                                        ) &&
                                        (!majorAccount?.primaryBenefitSelected ||
                                            !majorAccount)
                                            ? Number(
                                                  inputs.totalTravellersUnder19,
                                              )
                                            : 0,
                                    extension: extension,
                                    extensionId: extensionId,
                                    extensionName: extensionName,
                                    extensionTravellersNum: isPointsPooling
                                        ? 0
                                        : extensionTotalTravellers19Plus,
                                    extensionTravellersNumKids: isPointsPooling
                                        ? 0
                                        : extensionTotalTravellersUnder19,
                                    promoOption: '',
                                    promoNavisionId: '',
                                    comments: commentsArray.join('\\n'),
                                    departureCity: inputs.departureCity || '0',
                                },
                            },
                        })

                        refetchMajorAccount()

                        if (!salesRep) {
                            setSalesRep({
                                fullName: inputs.ossName,
                                number: inputs.ossNumber,
                                title: inputs.ossTitle,
                                email: inputs.ossEmail,
                            })
                        }

                        if (
                            user.userType === 'SALES_REP' &&
                            inputs.salesRepFirstName &&
                            inputs.salesRepLastName
                        ) {
                            await updateSalesRep({
                                variables: {
                                    payload: {
                                        firstName: inputs.salesRepFirstName,
                                        lastName: inputs.salesRepLastName,
                                    },
                                },
                            })
                        }

                        history.replace(
                            `/enrollment/staging/${data.privilege_createStagingRecord.id}`,
                            { complete: true },
                        )
                    } catch (err) {
                        console.log(err)
                    }
                }}
                reinitialize={[asp]}
                init={{
                    ...(ossNumber && {
                        ossNumber: { value: ossNumber, rawValue: ossNumber },
                    }),
                    ...(ossName && {
                        ossName: { value: ossName, rawValue: ossName },
                    }),
                    ...(ossTitle && {
                        ossTitle: { value: ossTitle, rawValue: ossTitle },
                    }),
                    ...(ossEmail && {
                        ossEmail: { value: ossEmail, rawValue: ossEmail },
                    }),
                    ...(salesRepFirstName && {
                        salesRepFirstName: {
                            value: salesRepFirstName,
                            rawValue: salesRepFirstName,
                        },
                    }),
                    ...(salesRepLastName && {
                        salesRepLastName: {
                            value: salesRepLastName,
                            rawValue: salesRepLastName,
                        },
                    }),
                    ...(asp && {
                        businessName: {
                            value: asp.businessName,
                            rawValue: asp.businessName,
                        },
                        legalName: {
                            value: asp.legalName,
                            rawValue: asp.legalName,
                        },
                        phone: {
                            value: asp.phone,
                            rawValue: asp.phone,
                        },
                        contactName: {
                            value: asp.contactName,
                            rawValue: asp.contactName,
                        },
                        address: {
                            value: asp.address,
                            rawValue: asp.address,
                        },
                        city: {
                            value: asp.city,
                            rawValue: asp.city,
                        },
                        province: {
                            value: asp.province,
                            rawValue: asp.province,
                        },
                        postal: {
                            value: asp.postal,
                            rawValue: asp.postal,
                        },
                        language: {
                            value: asp.language,
                            rawValue: asp.language,
                        },
                        ownerName: {
                            value: asp.ownerName,
                            rawValue: asp.ownerName,
                        },
                        email: {
                            value: asp.privilegeEmail,
                            rawValue: asp.privilegeEmail,
                        },
                        cellPhone: {
                            value: asp.cellPhone,
                            rawValue: asp.cellPhone,
                        },
                        navisionId: {
                            value: asp.installerNavisionId,
                            rawValue: asp.installerNavisionId,
                        },
                    }),
                    // ...(majorAccount?.primaryBenefitSelected && {
                    //     program: {
                    //         value: majorAccount.primaryBenefitSelected,
                    //         rawValue: majorAccount.primaryBenefitSelected,
                    //     },
                    // }),
                    // ...(storeMajorAccount?.allowStoreEnrollments?.length ===
                    //     1 &&
                    //     storeMajorAccount?.allowStoreEnrollments[0] ===
                    //         'GIFT_CARD' && {
                    //         program: {
                    //             value: 'GIFT_CARD',
                    //             rawValue: 'GIFT_CARD',
                    //         },
                    //     }),
                }}
            >
                {({ inputs, setInput }) => {
                    let trip =
                        inputs.program &&
                        privilegeTrips &&
                        privilegeTrips.trips.find(
                            (trip) => trip.slug === inputs.program.rawValue,
                        )
                    if (
                        selectedProgram === 'BOTH_TRIPS' &&
                        privilegeTrips?.trips
                    )
                        trip = privilegeTrips.trips[0]

                    let isModified = false
                    if (!aspNotFound && asp) {
                        if (
                            inputs.legalName &&
                            asp.legalName.length > 0 &&
                            asp.legalName !== inputs.legalName.rawValue
                        )
                            isModified = true
                        if (
                            inputs.businessName &&
                            asp.businessName !== inputs.businessName.rawValue
                        )
                            isModified = true
                        if (
                            inputs.address &&
                            asp.address !== inputs.address.rawValue
                        )
                            isModified = true
                        if (inputs.city && asp.city !== inputs.city.rawValue)
                            isModified = true
                        if (
                            inputs.province &&
                            asp.province !== inputs.province.rawValue
                        )
                            isModified = true
                        if (
                            inputs.postal &&
                            asp.postal !== inputs.postal.rawValue
                        )
                            isModified = true
                        if (
                            inputs.ownerName &&
                            asp.ownerName !== inputs.ownerName.rawValue
                        )
                            isModified = true
                        // if (inputs.contactName && asp.contactName !== inputs.contactName.rawValue) isModified = true
                        if (inputs.phone && asp.phone !== inputs.phone.rawValue)
                            isModified = true
                        // if (asp.cellPhone !== inputs.cellPhone) isModified = true
                        if (
                            inputs.email &&
                            asp.privilegeEmail !== inputs.email.rawValue
                        )
                            isModified = true
                        if (
                            inputs.language &&
                            asp.language !== inputs.language.rawValue
                        )
                            isModified = true
                    }

                    let majorAccountsPointsPooling = null
                    if (majorAccount && inputs.program) {
                        majorAccountsPointsPooling =
                            majorAccount['primaryInstaller']

                        if (
                            majorAccount.allowBothBenefits &&
                            inputs.program.rawValue === 'GIFT_CARD'
                        ) {
                            majorAccountsPointsPooling =
                                majorAccount['secondaryInstaller']
                        }
                    }

                    return (
                        <>
                            {Object.keys(errors).length > 0 && (
                                <div
                                    style={{
                                        padding: '2rem',
                                        background: 'rgba(255,0,0,0.2)',
                                        borderRadius: '4px',
                                        color: '#880000',
                                        fontSize: '1.4rem',
                                    }}
                                >
                                    <p>
                                        {t(
                                            'enrollmentErrorsHelperText',
                                            'There was a problem processing this registration, please review and try again.',
                                        )}
                                    </p>
                                    {errors && errors.emailDomain && (
                                        <p>
                                            {t(
                                                'invalidOwnerEmail',
                                                'Owner email cannot contain:',
                                            )}{' '}
                                            {company === 'NAPA'
                                                ? '@napacanada.com'
                                                : '@altrom.com, @autocamping.ca'}
                                        </p>
                                    )}
                                    {/* <ul>
                                        { errors.enteredByName && <li>{ t('enteredByName', 'Full Name') }</li> }
                                        { errors.enteredByJobTitle && <li>{ t('enteredByJobTitle', 'Job Title') }</li> }

                                        { errors.businessName && <li>{ t('businessName', 'Business Name') }</li> }
                                        { errors.legalName && <li>{ t('legalName', 'Legal Name') }</li> }
                                        { errors.phone && <li>{ t('phone', 'Phone') }</li> }

                                        { errors.address && <li>{ t('address', 'Address') }</li> }
                                        { errors.city && <li>{ t('city', 'City') }</li> }
                                        { errors.province && <li>{ t('province', 'Province') }</li> }
                                        { errors.postal && <li>{ t('postalCode', 'Postal Code') }</li> }

                                        { errors.language && <li>{ t('language', 'Language') }</li> }
                                        { errors.ownerName && <li>{ t('ownerName', 'Owner Name') }</li> }
                                        { errors.email && <li>{ t('email', 'Owner Email') }</li> }

                                        { errors.cellPhone && <li>{ t('cell', 'Cell Phone') }</li> }

                                        { errors.program && <li>{ t('programSelection', 'Program Selections') }</li> }
                                        { errors.departureCity && <li>{ t('departureCity', 'Departure City') }</li> }
                                        { errors.totalTravellers && <li>{ t('totalTravellers', 'Total Travellers') }</li> }
                                        { errors.totalTravellersUnder19 && <li>{ t('totalTravellersUnder19', 'Number of Children Only') }</li> }

                                        { errors.primaryDCStore && <li>{ t('primaryDCStoreNumber', 'Primary DC-Store Number') }</li> }
                                        { errors.primaryARNumber && <li>{ t('primaryARNumber', 'Primary Accounts Receivable #') }</li> }
                                    </ul> */}
                                </div>
                            )}

                            {/* Hide sales rep info section if current user is one */}
                            {userType === 'SALES_REP' &&
                                salesRep &&
                                !salesRep.fullName && (
                                    <>
                                        <h2>{t('Sales rep information')}</h2>
                                        <div
                                            style={{
                                                display: 'grid',
                                                gridTemplateColumns:
                                                    '1fr 1fr 1fr',
                                                gap: '3rem',
                                            }}
                                        >
                                            <Field
                                                label={t('Your first name')}
                                                name="salesRepFirstName"
                                                required
                                                error={errors.salesRepFirstName}
                                                errorMessage={t(
                                                    'Please enter your first name',
                                                )}
                                                defaultValue={salesRepFirstName}
                                                onChange={(e) =>
                                                    setSalesRepFirstName(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            <Field
                                                label={t('Your last name')}
                                                name="salesRepLastName"
                                                required
                                                error={errors.salesRepLastName}
                                                errorMessage={t(
                                                    'Please enter your last name',
                                                )}
                                                defaultValue={salesRepLastName}
                                                onChange={(e) =>
                                                    setSalesRepLastName(
                                                        e.target.value,
                                                    )
                                                }
                                            />
                                            <div className="fakeField fakeField--disabled">
                                                <div className="fakeFieldLabel">
                                                    {t('Your email')}
                                                </div>
                                                <div className="fakeFieldContent">
                                                    {salesRep.email}
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            {userType !== 'SALES_REP' && (
                                <>
                                    <h2
                                        className="new-installer-subheading"
                                        style={{ marginTop: 0 }}
                                    >
                                        {t(
                                            'repResponsibleSubheading',
                                            'Rep Responsible For Account',
                                        )}
                                    </h2>
                                    {salesRep ? (
                                        <SalesRep
                                            salesRep={salesRep}
                                            onClear={() => setSalesRep()}
                                        />
                                    ) : (
                                        <>
                                            <div
                                                className="grid"
                                                style={{
                                                    gridTemplateColumns:
                                                        'repeat(4, 1fr)',
                                                    gap: '3rem',
                                                }}
                                            >
                                                <Field
                                                    label={t(
                                                        'salesRepNumber',
                                                        'Sales Rep Number',
                                                    )}
                                                    name="ossNumber"
                                                    defaultValue={ossNumber}
                                                    onChange={(e) =>
                                                        setOssNumber(
                                                            e.target.value,
                                                        )
                                                    }
                                                    // placeholder={ t('salesRepNumberPlaceholder', '(If Assigned)') }
                                                    // formatting={['uppercase']}
                                                />
                                                <Field
                                                    label={t(
                                                        'enteredByName',
                                                        'Full Name',
                                                    )}
                                                    name="ossName"
                                                    required
                                                    error={errors.ossName}
                                                    errorMessage={t(
                                                        'enteredByNameError',
                                                        'Please enter a full name.',
                                                    )}
                                                    defaultValue={ossName}
                                                    onChange={(e) =>
                                                        setOssName(
                                                            e.target.value,
                                                        )
                                                    }
                                                    // formatting={['uppercase']}
                                                />
                                                <Field
                                                    label={t(
                                                        'enteredByJobTitle',
                                                        'Job Title',
                                                    )}
                                                    name="ossTitle"
                                                    required
                                                    error={errors.ossTitle}
                                                    errorMessage={t(
                                                        'enteredByJobTitleError',
                                                        'Please enter a job title.',
                                                    )}
                                                    defaultValue={ossTitle}
                                                    onChange={(e) =>
                                                        setOssTitle(
                                                            e.target.value,
                                                        )
                                                    }
                                                    // formatting={['uppercase']}
                                                />
                                                <Field
                                                    label={t(
                                                        'enteredByEmail',
                                                        'Email',
                                                    )}
                                                    type="email"
                                                    name="ossEmail"
                                                    error={errors.ossEmail}
                                                    errorMessage={t(
                                                        'emailError',
                                                        'Please enter a valid email.',
                                                    )}
                                                    defaultValue={ossEmail}
                                                    onChange={(e) =>
                                                        setOssEmail(
                                                            e.target.value,
                                                        )
                                                    }
                                                    // formatting={['uppercase']}
                                                />
                                            </div>
                                            <p
                                                style={{
                                                    textAlign: 'right',
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {t(
                                                    'emailNoticeLine1',
                                                    'Program emails are automatically delivered to the store manager.',
                                                )}
                                                <br />
                                                {t(
                                                    'emailNoticeLine2',
                                                    'Enter email address to receive a copy of all correspondence with this customer. (Not including sensitive information.)',
                                                )}
                                            </p>
                                        </>
                                    )}
                                    <HR />
                                </>
                            )}

                            <h2>{t('registerHeading', 'Enroll a Customer')}</h2>
                            {!asp && !aspNotFound && (
                                <Autocomplete>
                                    {({
                                        index,
                                        setIndex,
                                        isOpen,
                                        setIsOpen,
                                    }) => {
                                        function selectAsp(index) {
                                            const selectedASP =
                                                aspData
                                                    .privilege_tempMemberSearch[
                                                    index
                                                ]
                                            setAsp(selectedASP)

                                            setIndex(index)
                                            setIsOpen()

                                            // Reset state
                                            setSelectedProgram()
                                            setPointsPoolingAsp()
                                            setIgnorePreviousPointsPooling()
                                            setCustomerAccounts([])
                                            setDeparture()
                                        }

                                        return (
                                            <div className="autocomplete__wrapper">
                                                <Field
                                                    name="asp"
                                                    label={t(
                                                        'Search business name, address, owner name, phone number, etc.',
                                                    )}
                                                    autoComplete="off"
                                                    required
                                                    error={errors.asp}
                                                    errorMessage={t(
                                                        `Which customer would you like to sign up?`,
                                                    )}
                                                    onFocus={() =>
                                                        setIsOpen(true)
                                                    }
                                                    onKeyPress={(e) => {
                                                        if (e.key === 'Enter')
                                                            e.preventDefault()
                                                        if (
                                                            aspData &&
                                                            aspData.privilege_tempMemberSearch
                                                        ) {
                                                            if (
                                                                e.key ===
                                                                'ArrowDown'
                                                            )
                                                                setIndex(
                                                                    (index) =>
                                                                        index ===
                                                                        aspData
                                                                            .privilege_tempMemberSearch
                                                                            .length -
                                                                            1
                                                                            ? aspData
                                                                                  .privilege_tempMemberSearch
                                                                                  .length -
                                                                              1
                                                                            : index +
                                                                              1,
                                                                )
                                                            if (
                                                                e.key ===
                                                                'ArrowUp'
                                                            )
                                                                setIndex(
                                                                    (index) =>
                                                                        index ===
                                                                        0
                                                                            ? 0
                                                                            : index -
                                                                              1,
                                                                )
                                                            if (
                                                                e.key ===
                                                                'Enter'
                                                            ) {
                                                                // disable keypress event if a registration lock is in place
                                                                const installer =
                                                                    aspData
                                                                        .privilege_tempMemberSearch[
                                                                        index
                                                                    ]
                                                                if (
                                                                    installer.registrationLock ||
                                                                    ([
                                                                        'STORE',
                                                                        'SALES_REP',
                                                                    ].includes(
                                                                        userType,
                                                                    ) &&
                                                                        installer.isGroupMember) ||
                                                                    installer.isRegistered
                                                                )
                                                                    return false
                                                                selectAsp(index)
                                                            }
                                                            if (
                                                                e.key !==
                                                                'Enter'
                                                            ) {
                                                                setIsOpen(true)
                                                            }
                                                        }
                                                    }}
                                                    onChange={(e) => {
                                                        // if (e.target.value.length > 3) {
                                                        const search =
                                                            e.target.value
                                                                .split(' ')
                                                                .map((i) =>
                                                                    i.length > 1
                                                                        ? i
                                                                        : '',
                                                                )
                                                                .join(' ')
                                                                .replace(
                                                                    /[^a-zA-Z0-9 ]+/g,
                                                                    '',
                                                                )
                                                        setAsp()
                                                        aspSearch({
                                                            variables: {
                                                                search: `${(
                                                                    search || ''
                                                                ).trim()}*`,
                                                            },
                                                        })
                                                        setIndex(-1)
                                                        // }
                                                    }}
                                                />
                                                {(aspLoading ||
                                                    (aspData &&
                                                        aspData.privilege_tempMemberSearch &&
                                                        isOpen)) && (
                                                    <div className="autocompleteList__container">
                                                        {aspLoading && (
                                                            <div className="autocompleteList__loading">
                                                                <Loader
                                                                    type="ball-beat"
                                                                    text={t(
                                                                        'autocompleteLoaderText',
                                                                        'Loading customers',
                                                                    )}
                                                                />
                                                            </div>
                                                        )}
                                                        {aspData &&
                                                            aspData.privilege_tempMemberSearch && (
                                                                <ul>
                                                                    {aspData.privilege_tempMemberSearch.map(
                                                                        (
                                                                            installer,
                                                                            i,
                                                                        ) => {
                                                                            let blockGroupEnrollment =
                                                                                installer.isGroupMember
                                                                            if (
                                                                                storeMajorAccount?.account ===
                                                                                installer.groupName
                                                                            )
                                                                                blockGroupEnrollment = false

                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        installer.id
                                                                                    }
                                                                                    className={`hasIcon zeroPadding ${
                                                                                        index ===
                                                                                        i
                                                                                            ? 'selected'
                                                                                            : ''
                                                                                    } ${
                                                                                        installer.registrationLock ||
                                                                                        ([
                                                                                            'STORE',
                                                                                            'SALES_REP',
                                                                                        ].includes(
                                                                                            userType,
                                                                                        ) &&
                                                                                            blockGroupEnrollment)
                                                                                            ? 'autocomplete--disabled'
                                                                                            : ''
                                                                                    } ${
                                                                                        installer.isRegistered
                                                                                            ? 'autocomplete--isRegistered'
                                                                                            : ''
                                                                                    }`}
                                                                                    onClick={() => {
                                                                                        setIndex(
                                                                                            i,
                                                                                        )
                                                                                        selectAsp(
                                                                                            i,
                                                                                        )
                                                                                    }}
                                                                                >
                                                                                    <div className="autocomplete__icon">
                                                                                        <i className="material-icons">
                                                                                            {installer.isGroupMember
                                                                                                ? 'groups'
                                                                                                : installer.isRegistered
                                                                                                ? 'how_to_reg'
                                                                                                : 'person'}
                                                                                        </i>
                                                                                    </div>
                                                                                    <div className="autocomplete__infoContainer">
                                                                                        <div className="autocomplete__info">
                                                                                            {
                                                                                                installer.businessName
                                                                                            }
                                                                                            <div className="aspAddress">
                                                                                                {
                                                                                                    installer.address
                                                                                                }{' '}
                                                                                                {
                                                                                                    installer.city
                                                                                                }

                                                                                                ,{' '}
                                                                                                {
                                                                                                    installer.province
                                                                                                }{' '}
                                                                                                {
                                                                                                    installer.postal
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="autocomplete__description">
                                                                                            {installer.isRegistered &&
                                                                                                t(
                                                                                                    `Enrolled`,
                                                                                                )}
                                                                                            {!installer.isRegistered &&
                                                                                                installer.isGroupMember &&
                                                                                                t(
                                                                                                    `Major account`,
                                                                                                )}
                                                                                            {!installer.isRegistered &&
                                                                                                installer.registrationLock &&
                                                                                                t(
                                                                                                    `In progress`,
                                                                                                )}
                                                                                        </div>
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        },
                                                                    )}
                                                                    <li
                                                                        className="notFound"
                                                                        onClick={() => {
                                                                            setAspNotFound(
                                                                                true,
                                                                            )
                                                                            setAsp(
                                                                                {
                                                                                    id: uuid(),
                                                                                },
                                                                            )
                                                                        }}
                                                                    >
                                                                        {t(
                                                                            `Can't find your customer?`,
                                                                        )}
                                                                    </li>
                                                                </ul>
                                                            )}
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    }}
                                </Autocomplete>
                            )}

                            <RegistrationLock
                                asp={asp}
                                onRegistrationLock={(isLocked) =>
                                    setRegistrationLocked(isLocked)
                                }
                            />

                            {asp && (
                                <>
                                    <Installer
                                        installer={asp}
                                        noClose={aspId}
                                        onClear={() => {
                                            setAsp()
                                            return setAspNotFound()
                                        }}
                                    />

                                    <div
                                        className={`businessInformation__container`}
                                    >
                                        {registrationLocked && (
                                            <div className="businessInformation__registrationLocked">
                                                {t(
                                                    'Someone else is currently registering this ASP.',
                                                )}
                                            </div>
                                        )}

                                        <div className="grid grid-one-third">
                                            <div>
                                                <h2 className="new-installer-subheading">
                                                    {t(
                                                        'newASPBusinessInfoSubheading',
                                                        'Business Information',
                                                    )}
                                                </h2>
                                                {!aspNotFound && (
                                                    <Field
                                                        name="navisionId"
                                                        label={t(
                                                            'ntNumber',
                                                            'NT Number',
                                                        )}
                                                        disabled
                                                        noOptional
                                                    />
                                                )}
                                                <Field
                                                    name="businessName"
                                                    label={t('Business name')}
                                                    required
                                                    error={errors.businessName}
                                                    errorMessage={t(
                                                        'businessNameError',
                                                        'Please enter a business name.',
                                                    )}
                                                />
                                                <Field
                                                    name="legalName"
                                                    label={t('Legal name')}
                                                />
                                                <Field
                                                    name="phone"
                                                    type="tel"
                                                    label={t('Phone')}
                                                    required
                                                    error={errors.phone}
                                                    errorMessage={t(
                                                        'phoneError',
                                                        'Please enter a valid phone number: ie. 555-555-5555',
                                                    )}
                                                />
                                                <Field
                                                    name="contactName"
                                                    label={t('Manager name')}
                                                    error={errors.contactName}
                                                    errorMessage={t(
                                                        'managerNameError',
                                                        'Please enter a manager name.',
                                                    )}
                                                />
                                            </div>

                                            <div>
                                                <h2 className="new-installer-subheading">
                                                    {t(
                                                        'newASPLocationInfoSubheading',
                                                        'Location Information',
                                                    )}
                                                </h2>
                                                {/* TODO: Get REACT_APP_ADDRESSCOMPLETE_API_KEY into env vars to copy working implementation */}
                                                <Field
                                                    name="address"
                                                    label={t('Address')}
                                                    required
                                                    error={errors.address}
                                                    errorMessage={t(
                                                        'addressError',
                                                        'Please enter a valid address: ie. 123 Main St',
                                                    )}
                                                />
                                                <Field
                                                    name="city"
                                                    label={t('City')}
                                                    required
                                                    error={errors.city}
                                                    errorMessage={t(
                                                        'cityError',
                                                        'Please enter a City',
                                                    )}
                                                />
                                                <Field
                                                    name="province"
                                                    label={t('Province')}
                                                    noOptional
                                                    type="select"
                                                    options={[
                                                        ['AB', 'Alberta'],
                                                        [
                                                            'BC',
                                                            'British Columbia',
                                                        ],
                                                        ['MB', 'Manitoba'],
                                                        ['NB', 'New Brunswick'],
                                                        [
                                                            'NL',
                                                            'Newfoundland and Labrador',
                                                        ],
                                                        [
                                                            'NT',
                                                            'Northwest Territories',
                                                        ],
                                                        ['NS', 'Nova Scotia'],
                                                        ['NU', 'Nunavut'],
                                                        ['ON', 'Ontario'],
                                                        [
                                                            'PE',
                                                            'Prince Edward Island',
                                                        ],
                                                        ['QC', 'Quebec'],
                                                        ['SK', 'Saskatchewan'],
                                                        ['YT', 'Yukon'],
                                                    ]}
                                                    error={errors.province}
                                                    errorMessage={t(
                                                        'provinceError',
                                                        'Please select a Province',
                                                    )}
                                                />
                                                <Field
                                                    name="postal"
                                                    label={t('Postal code')}
                                                    required
                                                    error={errors.postal}
                                                    errorMessage={t(
                                                        'postalCodeError',
                                                        'Please enter a valid postal code: ie. A1A 1A1',
                                                    )}
                                                />
                                            </div>

                                            <div>
                                                <h2 className="new-installer-subheading">
                                                    {t(
                                                        'newASPContactInfoSubheading',
                                                        'Contact Information',
                                                    )}
                                                </h2>
                                                <Field
                                                    name="language"
                                                    label={t('Language')}
                                                    noOptional
                                                    type="select"
                                                    options={[
                                                        ['E', 'English'],
                                                        ['F', 'French'],
                                                    ]}
                                                    error={errors.language}
                                                    errorMessage={t(
                                                        'languageError',
                                                        'Please select a Language.',
                                                    )}
                                                />
                                                <Field
                                                    name="ownerName"
                                                    label={t('Owner name')}
                                                    required
                                                    error={errors.ownerName}
                                                    errorMessage={t(
                                                        'ownerNameError',
                                                        'Please enter an owner name.',
                                                    )}
                                                />
                                                <Field
                                                    name="email"
                                                    type="email"
                                                    label={t('Owner email')}
                                                    required
                                                    error={errors.email}
                                                    errorMessage={t(
                                                        'emailError',
                                                        'Please enter a valid email.',
                                                    )}
                                                />
                                                <Field
                                                    name="cellPhone"
                                                    type="tel"
                                                    label={t('Cell phone')}
                                                    error={errors.cellPhone}
                                                    errorMessage={t(
                                                        'cellPhoneError',
                                                        'Please enter a valid phone number: ie. 555-555-5555',
                                                    )}
                                                />
                                            </div>
                                        </div>

                                        {!aspNotFound && (
                                            <div className="note">
                                                {t(
                                                    "Please verify your customer's information, and make any necessary changes above.",
                                                )}
                                            </div>
                                        )}

                                        {isModified && (
                                            <div className="note note--exclaim">
                                                <Trans i18nKey="noticeOfModificationLink">
                                                    Your customer's information
                                                    has been modified. Please
                                                    submit a{' '}
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="https://napa-privilege.nyc3.cdn.digitaloceanspaces.com/assets/NAPA-AAG%20Notice%20of%20Modification%20form_OCT2021.pdf"
                                                    >
                                                        notice of modification
                                                        form
                                                    </a>
                                                    .
                                                </Trans>
                                            </div>
                                        )}
                                    </div>

                                    <HR />

                                    {/* Start program selection dropdown */}
                                    <div>
                                        <h2 className="new-installer-subheading">
                                            {t(
                                                'programSelectionSubheading',
                                                'Privilege Membership Benefit',
                                            )}
                                        </h2>
                                        <div className="grid grid-one-third">
                                            <Field
                                                type="select"
                                                name="program"
                                                label={t(
                                                    'programSelection',
                                                    'Select a membership benefit',
                                                )}
                                                options={[
                                                    ...trips,
                                                    ...(allowGiftCardEnrollment
                                                        ? [
                                                              [
                                                                  'GIFT_CARD',
                                                                  t(
                                                                      'rewards',
                                                                      'Rewards:',
                                                                  ) +
                                                                      ' ' +
                                                                      t(
                                                                          'Gift Cards/Certificates',
                                                                      ),
                                                              ],
                                                          ]
                                                        : []),
                                                ]}
                                                required
                                                onChange={(e) =>
                                                    setSelectedProgram(
                                                        e.target.value ===
                                                            'GIFT_CARD'
                                                            ? 'GIFT_CARD'
                                                            : e.target.value ===
                                                              'BOTH_TRIPS'
                                                            ? 'BOTH_TRIPS'
                                                            : e.target.value !==
                                                              ''
                                                            ? 'TRIP'
                                                            : '',
                                                    )
                                                }
                                                error={errors.program}
                                                errorMessage={t(
                                                    'programSelectionError',
                                                    'Please select a membership benefit.',
                                                )}
                                                // noBlankOption={
                                                //     !allowTripTravellerSelection ||
                                                //     storeMajorAccount
                                                //         ?.allowStoreEnrollments
                                                //         ?.length === 1
                                                // }
                                            />
                                            {selectedProgram === 'TRIP' &&
                                                allowTripTravellerSelection && (
                                                    <>
                                                        <Field
                                                            type="numeric"
                                                            name="totalTravellers19Plus"
                                                            label={t(
                                                                'totalTravellers',
                                                                'Number of Travelling Adults (19 and Over)',
                                                            )}
                                                            required
                                                            error={
                                                                errors.totalTravellers19Plus
                                                            }
                                                            errorMessage={t(
                                                                'totalTravellersError',
                                                                'How many adults (19 and over) will be travelling?',
                                                            )}
                                                        />
                                                        <Field
                                                            type="numeric"
                                                            name="totalTravellersUnder19"
                                                            label={t(
                                                                'totalTravellersUnder19',
                                                                'Number of Travelling Children (Under 19)',
                                                            )}
                                                            required
                                                            error={
                                                                errors.totalTravellersUnder19
                                                            }
                                                            errorMessage={t(
                                                                'totalTravellersUnder19Error',
                                                                'How many children (under 19) will be travelling?',
                                                            )}
                                                        />
                                                    </>
                                                )}
                                        </div>
                                        {selectedProgram === 'BOTH_TRIPS' && (
                                            <>
                                                {privilegeTrips?.trips?.map(
                                                    (trip, i) => {
                                                        let totalTravellers19Plus = 0
                                                        let totalTravellersUnder19 = 0

                                                        if (
                                                            inputs
                                                                ?.totalTravellers19Plus
                                                                ?.rawValue &&
                                                            i === 0
                                                        )
                                                            totalTravellers19Plus =
                                                                inputs
                                                                    .totalTravellers19Plus
                                                                    .rawValue

                                                        if (
                                                            inputs
                                                                ?.totalTravellersUnder19
                                                                ?.rawValue &&
                                                            i === 0
                                                        )
                                                            totalTravellersUnder19 =
                                                                inputs
                                                                    .totalTravellersUnder19
                                                                    .rawValue

                                                        if (
                                                            i > 0 &&
                                                            inputs &&
                                                            inputs[
                                                                'totalTravellers19Plus:alternate'
                                                            ] &&
                                                            inputs[
                                                                'totalTravellers19Plus:alternate'
                                                            ][i] &&
                                                            inputs[
                                                                'totalTravellers19Plus:alternate'
                                                            ][i].rawValue
                                                        )
                                                            totalTravellers19Plus =
                                                                inputs[
                                                                    'totalTravellers19Plus:alternate'
                                                                ][i].rawValue
                                                        if (
                                                            i > 0 &&
                                                            inputs &&
                                                            inputs[
                                                                'totalTravellersUnder19:alternate'
                                                            ] &&
                                                            inputs[
                                                                'totalTravellersUnder19:alternate'
                                                            ][i] &&
                                                            inputs[
                                                                'totalTravellersUnder19:alternate'
                                                            ][i].rawValue
                                                        )
                                                            totalTravellersUnder19 =
                                                                inputs[
                                                                    'totalTravellersUnder19:alternate'
                                                                ][i].rawValue

                                                        return (
                                                            <>
                                                                <div className="grid grid-one-third">
                                                                    <div className="totalTravellers">
                                                                        {
                                                                            trip[
                                                                                `location_${i18n.language}`
                                                                            ]
                                                                        }
                                                                    </div>
                                                                    <Field
                                                                        type="numeric"
                                                                        name={`${
                                                                            i >
                                                                            0
                                                                                ? 'totalTravellers19Plus:alternate'
                                                                                : 'totalTravellers19Plus'
                                                                        }`}
                                                                        label={t(
                                                                            'totalTravellers',
                                                                            'Number of Travelling Adults (19 and Over)',
                                                                        )}
                                                                        required
                                                                        id={i}
                                                                        error={
                                                                            i >
                                                                            0
                                                                                ? errors[
                                                                                      'totalTravellers19Plus:alternate'
                                                                                  ]
                                                                                : errors.totalTravellers19Plus
                                                                        }
                                                                        errorMessage={t(
                                                                            'totalTravellersError',
                                                                            'How many adults (19 and over) will be travelling?',
                                                                        )}
                                                                    />
                                                                    <Field
                                                                        type="numeric"
                                                                        name={`${
                                                                            i >
                                                                            0
                                                                                ? 'totalTravellersUnder19:alternate'
                                                                                : 'totalTravellersUnder19'
                                                                        }`}
                                                                        label={t(
                                                                            'totalTravellersUnder19',
                                                                            'Number of Travelling Children (Under 19)',
                                                                        )}
                                                                        required
                                                                        id={i}
                                                                        error={
                                                                            i >
                                                                            0
                                                                                ? errors[
                                                                                      'totalTravellersUnder19:alternate'
                                                                                  ]
                                                                                : errors.totalTravellersUnder19
                                                                        }
                                                                        errorMessage={t(
                                                                            'totalTravellersUnder19Error',
                                                                            'How many children (under 19) will be travelling?',
                                                                        )}
                                                                    />
                                                                </div>
                                                                <div className="totalTravellers">
                                                                    <Trans key="totalTravellersToExtension">
                                                                        {t(
                                                                            'totalTravellersTo',
                                                                            'Total travellers to ',
                                                                        )}
                                                                        {
                                                                            trip[
                                                                                `location_${i18n.language}`
                                                                            ]
                                                                        }
                                                                        :
                                                                    </Trans>{' '}
                                                                    {Number(
                                                                        totalTravellers19Plus,
                                                                    ) +
                                                                        Number(
                                                                            totalTravellersUnder19,
                                                                        )}
                                                                </div>
                                                            </>
                                                        )
                                                    },
                                                )}
                                            </>
                                        )}
                                        {selectedProgram === 'TRIP' &&
                                            trip &&
                                            inputs?.totalTravellers19Plus && (
                                                <div className="totalTravellers">
                                                    <Trans key="totalTravellersToExtension">
                                                        {t(
                                                            'totalTravellersTo',
                                                            'Total travellers to ',
                                                        )}
                                                        {
                                                            trip[
                                                                `location_${i18n.language}`
                                                            ]
                                                        }
                                                        :
                                                    </Trans>{' '}
                                                    {Number(
                                                        inputs
                                                            ?.totalTravellers19Plus
                                                            ?.rawValue || 0,
                                                    ) +
                                                        Number(
                                                            inputs
                                                                ?.totalTravellersUnder19
                                                                ?.rawValue || 0,
                                                        )}
                                                </div>
                                            )}
                                        {trip &&
                                            trip.extensions &&
                                            trip.extensions.length > 0 &&
                                            (!majorAccount?.primaryBenefitSelected ||
                                                !majorAccount ||
                                                (majorAccount?.secondaryInstallerNavisionId &&
                                                    majorAccount?.secondaryInstallerNavisionId ===
                                                        asp?.installerNavisionId)) && (
                                                <>
                                                    <h3>
                                                        {t(
                                                            'tripExtensionsHeading',
                                                            'Optional Trip Extension(s)',
                                                        )}
                                                    </h3>
                                                    {trip.extensions.map(
                                                        (extension, i) => {
                                                            // console.log(
                                                            //     'EXTENSION',
                                                            //     extension,
                                                            // )
                                                            return (
                                                                <React.Fragment
                                                                    key={i}
                                                                >
                                                                    <div className="grid grid-one-third">
                                                                        <Field
                                                                            type="checkbox"
                                                                            name="tripExtension"
                                                                            id={
                                                                                extension.id
                                                                            }
                                                                            label={
                                                                                extension[
                                                                                    `extension_${i18n.language}`
                                                                                ]
                                                                            }
                                                                            noOptional
                                                                        />
                                                                        {inputs.tripExtension &&
                                                                            inputs
                                                                                .tripExtension[
                                                                                extension
                                                                                    .id
                                                                            ] &&
                                                                            inputs
                                                                                .tripExtension[
                                                                                extension
                                                                                    .id
                                                                            ]
                                                                                .rawValue ===
                                                                                true && (
                                                                                <>
                                                                                    <Field
                                                                                        type="numeric"
                                                                                        name="extensionTotalTravellers19Plus"
                                                                                        id={
                                                                                            extension.id
                                                                                        }
                                                                                        label={t(
                                                                                            'totalTravellers',
                                                                                            'Number of Travelling Adults (19 and Over)',
                                                                                        )}
                                                                                        required
                                                                                        error={
                                                                                            errors.extensionTotalTravellers19Plus &&
                                                                                            errors
                                                                                                .extensionTotalTravellers19Plus[
                                                                                                extension
                                                                                                    .id
                                                                                            ]
                                                                                        }
                                                                                        errorMessage={t(
                                                                                            'totalTravellersError',
                                                                                            'How many adults (19 and over) will be travelling?',
                                                                                        )}
                                                                                    />
                                                                                    <Field
                                                                                        type="numeric"
                                                                                        name="extensionTotalTravellersUnder19"
                                                                                        id={
                                                                                            extension.id
                                                                                        }
                                                                                        label={t(
                                                                                            'totalTravellersUnder19',
                                                                                            'Number of Travelling Children (Under 19)',
                                                                                        )}
                                                                                        required
                                                                                        error={
                                                                                            errors.extensionTotalTravellersUnder19 &&
                                                                                            errors
                                                                                                .extensionTotalTravellersUnder19[
                                                                                                extension
                                                                                                    .id
                                                                                            ]
                                                                                        }
                                                                                        errorMessage={t(
                                                                                            'totalTravellersUnder19Error',
                                                                                            'How many children (under 19) will be travelling?',
                                                                                        )}
                                                                                    />
                                                                                </>
                                                                            )}
                                                                    </div>
                                                                    {inputs.extensionTotalTravellers19Plus &&
                                                                        inputs
                                                                            .extensionTotalTravellers19Plus[
                                                                            extension
                                                                                .id
                                                                        ] && (
                                                                            <div className="totalTravellers">
                                                                                <Trans key="totalTravellersToExtension">
                                                                                    {t(
                                                                                        'totalTravellersTo',
                                                                                        'Total travellers to ',
                                                                                    )}
                                                                                    {
                                                                                        extension[
                                                                                            `extension_${i18n.language}`
                                                                                        ]
                                                                                    }

                                                                                    :
                                                                                </Trans>{' '}
                                                                                {Number(
                                                                                    inputs
                                                                                        .extensionTotalTravellers19Plus[
                                                                                        extension
                                                                                            .id
                                                                                    ]
                                                                                        .rawValue ||
                                                                                        0,
                                                                                ) +
                                                                                    Number(
                                                                                        inputs
                                                                                            .extensionTotalTravellersUnder19[
                                                                                            extension
                                                                                                .id
                                                                                        ]
                                                                                            .rawValue ||
                                                                                            0,
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                </React.Fragment>
                                                            )
                                                        },
                                                    )}
                                                </>
                                            )}
                                        {/* Additional trip selection fields */}
                                        {['TRIP', 'BOTH_TRIPS'].includes(
                                            selectedProgram,
                                        ) &&
                                            (!majorAccount?.primaryBenefitSelected ||
                                                !majorAccount ||
                                                (majorAccount?.secondaryInstallerNavisionId &&
                                                    majorAccount?.secondaryInstallerNavisionId ===
                                                        asp?.installerNavisionId)) && (
                                                <>
                                                    <div>
                                                        <h3 className="new-installer-subheading">
                                                            {t(
                                                                'tripDepartureCitySubheading',
                                                                'Departing From',
                                                            )}
                                                        </h3>
                                                        {/* <div className='grid grid-one-third'> */}
                                                        <Field
                                                            type="select"
                                                            name="departureCity"
                                                            label={t(
                                                                'departureCity',
                                                                'Departing From',
                                                            )}
                                                            required
                                                            options={
                                                                departureCities
                                                            }
                                                            error={
                                                                errors.departureCity
                                                            }
                                                            errorMessage={t(
                                                                'departureCityError',
                                                                'Please select a departure location.',
                                                            )}
                                                            onChange={(e) => {
                                                                const departureCity =
                                                                    departureCitiesData &&
                                                                    departureCitiesData.privilege_departureCities.find(
                                                                        (
                                                                            departureCity,
                                                                        ) =>
                                                                            Number(
                                                                                departureCity.navisionId,
                                                                            ) ===
                                                                            Number(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                            ),
                                                                    )
                                                                return setDeparture(
                                                                    departureCity,
                                                                )
                                                            }}
                                                        />
                                                        {/* </div> */}
                                                        {departure &&
                                                            departure.navisionId !==
                                                                17 && (
                                                                <div className="mapContainer">
                                                                    <div className="airportInfo">
                                                                        {
                                                                            departure[
                                                                                `airportName_${i18n.language}`
                                                                            ]
                                                                        }{' '}
                                                                        (
                                                                        {
                                                                            departure.airportCode
                                                                        }
                                                                        )
                                                                        {departureDistance && (
                                                                            <div className="airportData">
                                                                                {/* <Trans i18nKey='departureDistance'> */}
                                                                                <Formatting
                                                                                    number
                                                                                >
                                                                                    {
                                                                                        departureDistance.distance
                                                                                    }
                                                                                </Formatting>{' '}
                                                                                {
                                                                                    departureDistance.distanceUnit
                                                                                }{' '}
                                                                                {t(
                                                                                    'departureDistanceAwayFrom',
                                                                                    'away from',
                                                                                )}{' '}
                                                                                <span>
                                                                                    {inputs
                                                                                        .businessName
                                                                                        .value ||
                                                                                        t(
                                                                                            'your customer',
                                                                                        )}
                                                                                </span>
                                                                                {/* </Trans> */}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <Map
                                                                        latitude={
                                                                            departure.latitude
                                                                        }
                                                                        longitude={
                                                                            departure.longitude
                                                                        }
                                                                    />
                                                                </div>
                                                            )}
                                                    </div>
                                                </>
                                            )}
                                    </div>
                                    {/* End program selection dropdown */}

                                    <HR />

                                    <div>
                                        <h2>{t(`Accounts Receivable`)}</h2>
                                        <h3>
                                            {t(
                                                `Please list your customer's AR number(s).`,
                                            )}
                                        </h3>
                                        {customerAccounts &&
                                            customerAccounts.map((store, i) => {
                                                return (
                                                    <li
                                                        key={store.id}
                                                        className="purchaseAccount__container"
                                                    >
                                                        {!store.locked && (
                                                            <button
                                                                type="button"
                                                                className="clearCustomerAccountNumber"
                                                                onClick={() =>
                                                                    setCustomerAccounts(
                                                                        (
                                                                            customerAccounts,
                                                                        ) => {
                                                                            customerAccounts.splice(
                                                                                i,
                                                                                1,
                                                                            )
                                                                            return [
                                                                                ...customerAccounts,
                                                                            ]
                                                                        },
                                                                    )
                                                                }
                                                            >
                                                                <i className="material-icons">
                                                                    close
                                                                </i>
                                                            </button>
                                                        )}
                                                        <div className="storeInfo__container">
                                                            <div>
                                                                <div className="storeName">
                                                                    {
                                                                        store.businessName
                                                                    }{' '}
                                                                    (
                                                                    {
                                                                        store.navisionId
                                                                    }{' '}
                                                                    /{' '}
                                                                    {
                                                                        store.napaNumber
                                                                    }
                                                                    )
                                                                </div>
                                                                <div className="storeAddress">
                                                                    {
                                                                        store.address
                                                                    }
                                                                    ,{' '}
                                                                    {store.city}
                                                                    ,{' '}
                                                                    {
                                                                        store.province
                                                                    }{' '}
                                                                    {
                                                                        store.postal
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Field
                                                            name="accountNumber"
                                                            id={store.id}
                                                            label={t(
                                                                'Accounts Receivable #',
                                                            )}
                                                            required
                                                            className="store__accountsReceivable"
                                                            defaultValue={
                                                                store.arNumber
                                                            }
                                                            errorMessage={t(
                                                                'Please enter a valid accounts receivable number.',
                                                            )}
                                                            error={
                                                                errors.accountNumber &&
                                                                errors
                                                                    .accountNumber[
                                                                    i
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                setCustomerAccounts(
                                                                    (
                                                                        customerAccounts,
                                                                    ) => {
                                                                        const newCustomerAccounts =
                                                                            customerAccounts
                                                                        newCustomerAccounts[
                                                                            i
                                                                        ].arNumber =
                                                                            e.target.value
                                                                        return [
                                                                            ...newCustomerAccounts,
                                                                        ]
                                                                    },
                                                                )
                                                            }
                                                        />
                                                    </li>
                                                )
                                            })}

                                        {customerAccounts.length < 4 && (
                                            <>
                                                <h3>
                                                    {t(
                                                        'additionalCustomerAccounts',
                                                        'Are there additional stores servicing this customer?',
                                                    )}
                                                </h3>
                                                <Autocomplete>
                                                    {({
                                                        index,
                                                        setIndex,
                                                        isOpen,
                                                        setIsOpen,
                                                    }) => {
                                                        function selectStore(
                                                            index,
                                                        ) {
                                                            const {
                                                                __typename,
                                                                ...selectedStore
                                                            } =
                                                                storeSearchData
                                                                    .storeSearch[
                                                                    index
                                                                ]

                                                            setCustomerAccounts(
                                                                (
                                                                    customerAccounts,
                                                                ) => [
                                                                    ...customerAccounts,
                                                                    {
                                                                        ...selectedStore,
                                                                        id: nanoid(),
                                                                    },
                                                                ],
                                                            )
                                                            setIndex(index)
                                                            setIsOpen(false)
                                                            setInput({
                                                                storeSearch: {
                                                                    value: '',
                                                                    rawValue:
                                                                        '',
                                                                },
                                                            })
                                                        }

                                                        return (
                                                            <div className="autocomplete__wrapper">
                                                                <Field
                                                                    name="storeSearch"
                                                                    label={t(
                                                                        'Search DC-STORE, NS #, business name, address, owner name, phone number, etc.',
                                                                    )}
                                                                    autoComplete="off"
                                                                    noOptional
                                                                    onFocus={() =>
                                                                        setIsOpen(
                                                                            true,
                                                                        )
                                                                    }
                                                                    onKeyPress={(
                                                                        e,
                                                                    ) => {
                                                                        if (
                                                                            e.key ===
                                                                            'Enter'
                                                                        )
                                                                            e.preventDefault()
                                                                        if (
                                                                            storeSearchData &&
                                                                            storeSearchData.storeSearch
                                                                        ) {
                                                                            if (
                                                                                e.key ===
                                                                                'ArrowDown'
                                                                            )
                                                                                setIndex(
                                                                                    (
                                                                                        index,
                                                                                    ) =>
                                                                                        index ===
                                                                                        storeSearchData
                                                                                            .storeSearch
                                                                                            .length -
                                                                                            1
                                                                                            ? storeSearchData
                                                                                                  .storeSearch
                                                                                                  .length -
                                                                                              1
                                                                                            : index +
                                                                                              1,
                                                                                )
                                                                            if (
                                                                                e.key ===
                                                                                'ArrowUp'
                                                                            )
                                                                                setIndex(
                                                                                    (
                                                                                        index,
                                                                                    ) =>
                                                                                        index ===
                                                                                        0
                                                                                            ? 0
                                                                                            : index -
                                                                                              1,
                                                                                )
                                                                            if (
                                                                                e.key ===
                                                                                'Enter'
                                                                            ) {
                                                                                // disable keypress event if a registration lock is in place
                                                                                // const installer = aspData.privilege_tempMemberSearch[index]
                                                                                // if(installer.registrationLock || (userType === 'STORE' && installer.isGroupMember) || (installer.isRegistered)) return false
                                                                                selectStore(
                                                                                    index,
                                                                                )
                                                                            }
                                                                            if (
                                                                                e.key !==
                                                                                'Enter'
                                                                            ) {
                                                                                setIsOpen(
                                                                                    true,
                                                                                )
                                                                            }
                                                                        }
                                                                    }}
                                                                    onChange={(
                                                                        e,
                                                                    ) => {
                                                                        // if (e.target.value.length > 3) {
                                                                        const search =
                                                                            e.target.value
                                                                                .split(
                                                                                    ' ',
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        i,
                                                                                    ) =>
                                                                                        i.length >
                                                                                        1
                                                                                            ? i
                                                                                            : '',
                                                                                )
                                                                                .join(
                                                                                    ' ',
                                                                                )
                                                                                .replace(
                                                                                    /-/g,
                                                                                    ' ',
                                                                                )
                                                                                .replace(
                                                                                    /[^a-zA-Z0-9 ]+/g,
                                                                                    '',
                                                                                )
                                                                        // setAsp()
                                                                        storeSearch(
                                                                            {
                                                                                variables:
                                                                                    {
                                                                                        search: `${(
                                                                                            search ||
                                                                                            ''
                                                                                        ).trim()}*`,
                                                                                    },
                                                                            },
                                                                        )
                                                                        setIndex(
                                                                            -1,
                                                                        )
                                                                        // }
                                                                    }}
                                                                />
                                                                {(storeSearchLoading ||
                                                                    (storeSearchData &&
                                                                        storeSearchData.storeSearch &&
                                                                        isOpen)) && (
                                                                    <div className="autocompleteList__container">
                                                                        {storeSearchLoading && (
                                                                            <div className="autocompleteList__loading">
                                                                                <Loader
                                                                                    type="ball-beat"
                                                                                    text={t(
                                                                                        'autocompleteLoaderText',
                                                                                        'Loading customers',
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        {storeSearchData &&
                                                                            storeSearchData.storeSearch && (
                                                                                <ul>
                                                                                    {storeSearchData.storeSearch.map(
                                                                                        (
                                                                                            store,
                                                                                            i,
                                                                                        ) => (
                                                                                            <li
                                                                                                key={
                                                                                                    store.id
                                                                                                }
                                                                                                className={`hasIcon zeroPadding ${
                                                                                                    index ===
                                                                                                    i
                                                                                                        ? 'selected'
                                                                                                        : ''
                                                                                                }`}
                                                                                                onClick={() => {
                                                                                                    setIndex(
                                                                                                        i,
                                                                                                    )
                                                                                                    selectStore(
                                                                                                        i,
                                                                                                    )
                                                                                                }}
                                                                                            >
                                                                                                <div className="autocomplete__icon">
                                                                                                    <i className="material-icons">
                                                                                                        business
                                                                                                    </i>
                                                                                                </div>
                                                                                                <div className="autocomplete__infoContainer">
                                                                                                    <div className="autocomplete__info">
                                                                                                        {
                                                                                                            store.businessName
                                                                                                        }
                                                                                                        <div className="aspAddress">
                                                                                                            {
                                                                                                                store.address
                                                                                                            }{' '}
                                                                                                            {
                                                                                                                store.city
                                                                                                            }

                                                                                                            ,{' '}
                                                                                                            {
                                                                                                                store.province
                                                                                                            }{' '}
                                                                                                            {
                                                                                                                store.postal
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </li>
                                                                                        ),
                                                                                    )}
                                                                                    {storeSearchData
                                                                                        .storeSearch
                                                                                        .length ===
                                                                                        0 && (
                                                                                        <li className="notFound noClick">
                                                                                            {t(
                                                                                                'storeSearchNoResultsFound',
                                                                                                `Sorry, we can't find any stores using those search terms.`,
                                                                                            )}
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                            )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )
                                                    }}
                                                </Autocomplete>
                                            </>
                                        )}
                                    </div>
                                    {majorAccount &&
                                        displayPointsPooling &&
                                        inputs.program && (
                                            <>
                                                <HR />
                                                <div
                                                    style={{
                                                        className: 'grid',
                                                        gridColumnStart: 1,
                                                        gridColumnEnd: 'span 3',
                                                    }}
                                                >
                                                    <h2 className="new-installer-subheading">
                                                        {t(
                                                            'pointsPoolingSubheading',
                                                            'Points pooling for multi-branch or corporate accounts',
                                                        )}
                                                    </h2>
                                                    <Installer
                                                        installer={
                                                            majorAccountsPointsPooling
                                                        }
                                                        noClose
                                                    />
                                                </div>
                                            </>
                                        )}
                                    {(!majorAccount ||
                                        (majorAccount &&
                                            !majorAccount.requirePrimaryEnrollment)) && (
                                        <>
                                            <HR />
                                            <div
                                                style={{
                                                    className: 'grid',
                                                    gridColumnStart: 1,
                                                    gridColumnEnd: 'span 3',
                                                }}
                                            >
                                                <h2 className="new-installer-subheading">
                                                    {t(
                                                        'pointsPoolingSubheading',
                                                        'Points pooling for multi-branch or corporate accounts',
                                                    )}{' '}
                                                    {t(
                                                        'pointsPoolingHelper',
                                                        '(Optional)',
                                                    )}
                                                </h2>

                                                {asp &&
                                                asp.previousPointsGoTo &&
                                                !ignorePreviousPointsPooling ? (
                                                    <>
                                                        <Installer
                                                            installer={
                                                                asp.previousPointsGoTo
                                                            }
                                                            alternate
                                                            noClose
                                                        />
                                                        <h3>
                                                            {t(
                                                                'Would you like to pool points to the same location as last campaign?',
                                                            )}
                                                        </h3>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <button
                                                                type="button"
                                                                className="pointsPooling pointsPooling--no"
                                                                onClick={() =>
                                                                    setIgnorePreviousPointsPooling(
                                                                        true,
                                                                    )
                                                                }
                                                            >
                                                                {t('NO')}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="pointsPooling pointsPooling--yes"
                                                                onClick={() => {
                                                                    setIgnorePreviousPointsPooling(
                                                                        true,
                                                                    )
                                                                    setPointsPoolingAsp(
                                                                        asp.previousPointsGoTo,
                                                                    )
                                                                }}
                                                            >
                                                                {t('YES')}
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            // poolPoints === 'YES' && (
                                                            <>
                                                                <h3>
                                                                    {t(
                                                                        'Select a primary points pooling location. Leave empty if you do not wish to pool points to another location.',
                                                                    )}
                                                                </h3>
                                                                {!pointsPoolingAsp &&
                                                                    !pointsPoolingAspNotFound && (
                                                                        <Autocomplete>
                                                                            {({
                                                                                index,
                                                                                setIndex,
                                                                                isOpen,
                                                                                setIsOpen,
                                                                            }) => {
                                                                                function selectAsp(
                                                                                    index,
                                                                                ) {
                                                                                    const selectedASP =
                                                                                        pointsPoolingAspData
                                                                                            .privilege_tempMemberSearch[
                                                                                            index
                                                                                        ]
                                                                                    setPointsPoolingAsp(
                                                                                        selectedASP,
                                                                                    )

                                                                                    setIndex(
                                                                                        index,
                                                                                    )
                                                                                    setIsOpen()
                                                                                }

                                                                                return (
                                                                                    <div className="autocomplete__wrapper">
                                                                                        <Field
                                                                                            name="pointsPoolingAsp"
                                                                                            label={t(
                                                                                                'Search business name, address, owner name, phone number, etc.',
                                                                                            )}
                                                                                            autoComplete="off"
                                                                                            required
                                                                                            // error={ errors.asp }
                                                                                            // errorMessage={ t(`What automotive service provider did you use?`) }
                                                                                            onFocus={() =>
                                                                                                setIsOpen(
                                                                                                    true,
                                                                                                )
                                                                                            }
                                                                                            // onBlur={ () => {
                                                                                            //     setTimeout(() => {
                                                                                            //         setIsOpen(false)
                                                                                            //     }, 100)
                                                                                            // }}
                                                                                            onKeyPress={(
                                                                                                e,
                                                                                            ) => {
                                                                                                if (
                                                                                                    e.key ===
                                                                                                    'Enter'
                                                                                                )
                                                                                                    e.preventDefault()
                                                                                                if (
                                                                                                    pointsPoolingAspData &&
                                                                                                    pointsPoolingAspData.privilege_tempMemberSearch
                                                                                                ) {
                                                                                                    if (
                                                                                                        e.key ===
                                                                                                        'ArrowDown'
                                                                                                    )
                                                                                                        setIndex(
                                                                                                            (
                                                                                                                index,
                                                                                                            ) =>
                                                                                                                index ===
                                                                                                                pointsPoolingAspData
                                                                                                                    .privilege_tempMemberSearch
                                                                                                                    .length -
                                                                                                                    1
                                                                                                                    ? pointsPoolingAspData
                                                                                                                          .privilege_tempMemberSearch
                                                                                                                          .length -
                                                                                                                      1
                                                                                                                    : index +
                                                                                                                      1,
                                                                                                        )
                                                                                                    if (
                                                                                                        e.key ===
                                                                                                        'ArrowUp'
                                                                                                    )
                                                                                                        setIndex(
                                                                                                            (
                                                                                                                index,
                                                                                                            ) =>
                                                                                                                index ===
                                                                                                                0
                                                                                                                    ? 0
                                                                                                                    : index -
                                                                                                                      1,
                                                                                                        )
                                                                                                    if (
                                                                                                        e.key ===
                                                                                                        'Enter'
                                                                                                    ) {
                                                                                                        selectAsp(
                                                                                                            index,
                                                                                                        )
                                                                                                    }
                                                                                                    if (
                                                                                                        e.key !==
                                                                                                        'Enter'
                                                                                                    ) {
                                                                                                        setIsOpen(
                                                                                                            true,
                                                                                                        )
                                                                                                    }
                                                                                                }
                                                                                            }}
                                                                                            onChange={(
                                                                                                e,
                                                                                            ) => {
                                                                                                if (
                                                                                                    e
                                                                                                        .target
                                                                                                        .value
                                                                                                        .length >
                                                                                                    3
                                                                                                ) {
                                                                                                    const search =
                                                                                                        e.target.value
                                                                                                            .split(
                                                                                                                ' ',
                                                                                                            )
                                                                                                            .map(
                                                                                                                (
                                                                                                                    i,
                                                                                                                ) =>
                                                                                                                    i.length >
                                                                                                                    1
                                                                                                                        ? i
                                                                                                                        : '',
                                                                                                            )
                                                                                                            .join(
                                                                                                                ' ',
                                                                                                            )
                                                                                                            .replace(
                                                                                                                /[^a-zA-Z0-9 ]+/g,
                                                                                                                '',
                                                                                                            )
                                                                                                    setPointsPoolingAsp()
                                                                                                    pointsPoolingAspSearch(
                                                                                                        {
                                                                                                            variables:
                                                                                                                {
                                                                                                                    search: `${
                                                                                                                        majorAccount
                                                                                                                            ? `@groupName:{${majorAccount?.account.replace(
                                                                                                                                  /-/g,
                                                                                                                                  '\\-',
                                                                                                                              )}} `
                                                                                                                            : ''
                                                                                                                    }${(
                                                                                                                        search ||
                                                                                                                        ''
                                                                                                                    ).trim()}*`,
                                                                                                                },
                                                                                                        },
                                                                                                    )
                                                                                                    setIndex(
                                                                                                        -1,
                                                                                                    )
                                                                                                }
                                                                                            }}
                                                                                        />
                                                                                        {/* { pointsPoolingAspLoading && <div className='autocompleteList__container'>{ t("Loading...") }</div> } */}
                                                                                        {(pointsPoolingAspLoading ||
                                                                                            (pointsPoolingAspData &&
                                                                                                pointsPoolingAspData.privilege_tempMemberSearch &&
                                                                                                isOpen)) && (
                                                                                            <div className="autocompleteList__container">
                                                                                                {pointsPoolingAspLoading && (
                                                                                                    <div className="autocompleteList__loading">
                                                                                                        <Loader
                                                                                                            type="ball-beat"
                                                                                                            text={t(
                                                                                                                'autocompleteLoaderText',
                                                                                                                'Loading customers',
                                                                                                            )}
                                                                                                        />
                                                                                                    </div>
                                                                                                )}
                                                                                                {pointsPoolingAspData &&
                                                                                                    pointsPoolingAspData.privilege_tempMemberSearch && (
                                                                                                        <ul>
                                                                                                            {pointsPoolingAspData.privilege_tempMemberSearch.map(
                                                                                                                (
                                                                                                                    installer,
                                                                                                                    i,
                                                                                                                ) => (
                                                                                                                    <li
                                                                                                                        key={
                                                                                                                            installer.id
                                                                                                                        }
                                                                                                                        className={`hasIcon zeroPadding ${
                                                                                                                            index ===
                                                                                                                            i
                                                                                                                                ? 'selected'
                                                                                                                                : ''
                                                                                                                        } ${
                                                                                                                            installer.isGroupMember
                                                                                                                                ? 'autocomplete--disabled'
                                                                                                                                : ''
                                                                                                                        }`}
                                                                                                                        onClick={() => {
                                                                                                                            setIndex(
                                                                                                                                i,
                                                                                                                            )
                                                                                                                            selectAsp(
                                                                                                                                i,
                                                                                                                            )
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div className="autocomplete__icon">
                                                                                                                            <i className="material-icons">
                                                                                                                                {installer.isGroupMember
                                                                                                                                    ? 'groups'
                                                                                                                                    : installer.isRegistered
                                                                                                                                    ? 'how_to_reg'
                                                                                                                                    : 'person'}
                                                                                                                            </i>
                                                                                                                        </div>
                                                                                                                        <div className="autocomplete__infoContainer">
                                                                                                                            <div className="autocomplete__info">
                                                                                                                                {
                                                                                                                                    installer.businessName
                                                                                                                                }
                                                                                                                                <div className="aspAddress">
                                                                                                                                    {
                                                                                                                                        installer.address
                                                                                                                                    }{' '}
                                                                                                                                    {
                                                                                                                                        installer.city
                                                                                                                                    }

                                                                                                                                    ,{' '}
                                                                                                                                    {
                                                                                                                                        installer.province
                                                                                                                                    }{' '}
                                                                                                                                    {
                                                                                                                                        installer.postal
                                                                                                                                    }
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="autocomplete__description">
                                                                                                                                {installer.isRegistered &&
                                                                                                                                    t(
                                                                                                                                        `Enrolled`,
                                                                                                                                    )}
                                                                                                                                {!installer.isRegistered &&
                                                                                                                                    installer.isGroupMember &&
                                                                                                                                    t(
                                                                                                                                        `Major account`,
                                                                                                                                    )}
                                                                                                                                {!installer.isRegistered &&
                                                                                                                                    !installer.isGroupMember &&
                                                                                                                                    t(
                                                                                                                                        'Not Enrolled',
                                                                                                                                    )}
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </li>
                                                                                                                ),
                                                                                                            )}
                                                                                                            {pointsPoolingAspData
                                                                                                                .privilege_tempMemberSearch
                                                                                                                .length ===
                                                                                                                0 && (
                                                                                                                <li className="notFound noClick">
                                                                                                                    {t(
                                                                                                                        `Sorry, we can't find any ASP's using those search terms.`,
                                                                                                                    )}
                                                                                                                </li>
                                                                                                            )}
                                                                                                        </ul>
                                                                                                    )}
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                )
                                                                            }}
                                                                        </Autocomplete>
                                                                    )}
                                                            </>
                                                            // )
                                                        }

                                                        {pointsPoolingAsp && (
                                                            <Installer
                                                                installer={
                                                                    pointsPoolingAsp
                                                                }
                                                                onClear={() =>
                                                                    setPointsPoolingAsp()
                                                                }
                                                            />
                                                        )}
                                                        {1 == 2 &&
                                                            pointsPoolingAsp &&
                                                            !pointsPoolingAsp.isRegistered && (
                                                                <div className="note note--exclaim">
                                                                    This ASP is
                                                                    not yet
                                                                    enrolled.
                                                                    This
                                                                    location
                                                                    must be
                                                                    enrolled
                                                                    before we
                                                                    can pool
                                                                    points to
                                                                    this
                                                                    account.
                                                                </div>
                                                            )}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    <HR />

                                    <Submit
                                        fullWidth
                                        context={'yellow'}
                                        style={{ marginTop: '3rem' }}
                                        disabled={1 === 2}
                                    >
                                        {t('Complete enrollment')}
                                    </Submit>
                                </>
                            )}
                        </>
                    )
                }}
            </Form>
        </div>
    )
}
