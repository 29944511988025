import gql from 'graphql-tag'

export const CREATE_STAGING_RECORD = gql`
    mutation CreateStagingRecord(
        $payload: Privilege_CreateStagingRecordPayload!
    ) {
        privilege_createStagingRecord(payload: $payload) {
            id
        }
    }
`

export const GET_STAGING_RECORD = gql`
    query StagingRecord($id: ID!) {
        privilege_record: privilege_stagingRecord(id: $id) {
            id
            privilegeMemberId
            privilegeNavisionId
            installerNavisionId
            memberType
            status
            isGroupMember
            groupName
            legalName
            businessName
            ownerName
            contactName
            cellPhone
            comments
            address
            city
            province
            postal
            phone
            email
            privilegeEmail
            website
            installerBanner
            privilegeBenefit
            isTrip
            tripLocations
            dcNum
            storeId
            storeNavisionId
            storeNum
            arNum
            storeNum2
            arNum2
            storeNum3
            arNum3
            language
            latitude
            longitude
            ossName
            ossTitle
            ossEmail
            pointsGoTo
            enteredDate
            pointsGoToAccount {
                id
                businessName
                address
                city
                province
                postal
                installerNavisionId
                privilegeNavisionId
                storeNavisionId
                memberId
                stagingId
                isMember
            }
        }
    }
`
