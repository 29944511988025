import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
// import { offsetLimitPagination } from '@apollo/client/utilities'

import { COMPANIES } from 'hooks/company'

const httpLink = createHttpLink({
    uri:
        process.env.NODE_ENV === 'development'
            ? 'http://localhost:4000/graphql'
            : 'https://napaapi.com/graphql',
})

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('accessToken')

    const company =
        process.env.NODE_ENV === 'production'
            ? COMPANIES[window.location.hostname]
            : process.env.REACT_APP_COMPANY

    return {
        headers: {
            ...headers,
            client: 'PRIVILEGE',
            company,
            authorization: token ? `Bearer ${token}` : '',
        },
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    name: `NAPA - Privilege (${process.env.NODE_ENV})`,
    version: '1.0.0',
    cache: new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    privilege_tempMembers: {
                        keyArgs: false,
                        merge(existing = {}, incoming, { args }) {
                            // compare search results
                            if (
                                existing &&
                                existing.search !== args.filter.search
                            )
                                return {
                                    ...incoming,
                                    search: args.filter.search,
                                }

                            const results = []
                            existing.results?.map((result) =>
                                results.push(result),
                            )

                            incoming.results.map((incomingResult) => {
                                const exists = results.find(
                                    (obj) => obj.__ref === incomingResult.__ref,
                                )
                                if (!exists) return results.push(incomingResult)
                                return null
                            })

                            return {
                                search: args.filter.search,
                                cursor: incoming.cursor,
                                count: incoming.count,
                                results,
                                __typename: incoming.__typename,
                            }
                        },
                    },
                    privilege_members: {
                        keyArgs: false,
                        merge(existing = {}, incoming, { args }) {
                            // compare search results
                            if (
                                existing &&
                                (existing.search + '').trim() !==
                                    (args.filter.search + '').trim()
                            )
                                return {
                                    ...incoming,
                                    search: args.filter.search,
                                }

                            const results = []
                            existing.results?.map((result) =>
                                results.push(result),
                            )

                            incoming.results.map((incomingResult) => {
                                const exists = results.find(
                                    (obj) => obj.__ref === incomingResult.__ref,
                                )
                                if (!exists) return results.push(incomingResult)
                                return null
                            })

                            return {
                                search: args.filter.search,
                                cursor: incoming.cursor,
                                count: incoming.count,
                                results,
                                __typename: incoming.__typename,
                            }
                        },
                    },
                    stores: {
                        keyArgs: ['filter'],
                        merge(existing = [], incoming = [], { args }) {
                            // console.log('MERGE',existing, incoming, args)

                            const results = []
                            existing?.map((result) => results.push(result))
                            incoming?.map((incomingResult) => {
                                const exists = results.find(
                                    (obj) => obj.__ref === incomingResult.__ref,
                                )
                                if (!exists) return results.push(incomingResult)
                                return null
                            })

                            return results
                        },
                    },
                },
            },
        },
    }),
    onError: ({ networkError }) => {
        if (networkError) {
            if (networkError.statusCode === 401) {
                localStorage.removeItem('accessToken')
                const refreshToken = localStorage.getItem('refreshToken')

                if (!refreshToken) {
                    client.resetStore()
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                }
            }
        }
    },
})

export default client
