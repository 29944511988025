import React, { createContext, useContext, useState, useEffect } from 'react'
import Loader from '../components/Loader'

const GlobalContext = createContext()

export default function GlobalProvider({ children }) {

    const [loading, setLoading] = useState(true)
    const [dc, setDC] = useState()
    const [store, setStore] = useState()
    const [bds, setBds] = useState()
    const [installer, setInstaller] = useState()
    const [ majorAccount, setMajorAccount ] = useState()

    useEffect(() => {
        if (dc || store || bds || installer || majorAccount) {
            localStorage.setItem('globalState', JSON.stringify({ dc, store, bds, installer, majorAccount }))
        }
    }, [dc, store, bds, installer, majorAccount])

    useEffect(() => {

        async function fetchFromLocalStorage() {
            const storage = localStorage.getItem('globalState') || null

            if (loading && storage) {

                const { dc: dcData, store: storeData, bds: bdsData, installer: installerData, majorAccount: majorAccountData } = JSON.parse(storage)

                await setDC(dc => {
                    if (!dc && dcData) return dcData
                    return
                })

                await setStore(store => {
                    if (!store && storeData) return storeData
                    return
                })

                await setBds(bds => {
                    if (!bds && bdsData) return bdsData
                    return
                })

                await setInstaller(installer => {
                    if (!installer && installerData) return installerData
                    return
                })

                await setMajorAccount(majorAccount => {
                    if (!majorAccount && majorAccountData) return majorAccountData
                    return
                })
    
                setLoading(false)
            }
            
            else {
                setLoading(false)
            }
        }

        fetchFromLocalStorage()

    }, [loading])

    function handleReset() {
        setDC()
        setStore()
        setBds()
        setInstaller()
        setMajorAccount()
        localStorage.removeItem('globalState')
    }

    return(
        <GlobalContext.Provider
            value={{

                dc,
                setDC: (dc) => {
                    setMajorAccount()
                    setDC(dc)
                },

                store,
                setStore: (store) => {
                    setMajorAccount()
                    setStore(store)
                },

                bds,
                setBds: (bds) => {
                    setMajorAccount()
                    setBds(bds)
                },
                
                installer,
                setInstaller: (installer) => {
                    setMajorAccount()
                    setInstaller(installer)
                },

                majorAccount,
                setMajorAccount: (majorAccount) => {
                    handleReset()
                    setMajorAccount(majorAccount)
                },
                
                resetGlobalState: () => handleReset()
            }}
        >
            {
                loading ? <Loader /> : children
            }
        </GlobalContext.Provider>
    )

}

function useGlobalState() {
    return useContext(GlobalContext)
}

export {
    useGlobalState
}