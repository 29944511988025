import './card.scss'
import { Link } from 'react-router-dom'

export default function Card({ children, type, className, style, to, onClick }) {

    const Element = to ? Link : 'div'

    return (
        <Element className={`card ${ type ? 'card--' + type : '' } ${ className ? className : '' } ${ onClick ? 'card--button' : '' }`} to={ to } onClick={ (e) => onClick && onClick(e) } style={ style }>
            { children }
        </Element>
    )

}

export function CardSection({ children, title, className = '', style }) {
    return (
        <div className={`cardSection ${ className }`} style={ style }>
            <div className='cardSection__title'>{ title }</div>
            { children }
        </div>
    )
}

export function CardIcon({ icon, children }) {
    return <div className='cardIcon'><i className='material-icons'>{ icon || children }</i></div>
}

export function CardStatus({ status, children }) {
    return <div className={`cardStatus ${ status ? `cardStatus--${ status }` : '' } ${ children ? `cardStatus--${ children }` : '' }`} />
}