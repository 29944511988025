import gql from 'graphql-tag'

export const GET_TEMP_MEMBERS = gql`
    query privilege_tempMemberSearch($search: String) {
        privilege_tempMemberSearch(search: $search) {
            id
            privilegeMemberId
            privilegeNavisionId
            installerNavisionId
            memberType
            status
            isGroupMember
            groupName
            legalName
            businessName
            ownerName
            contactName
            address
            city
            province
            postal
            phone
            email
            privilegeEmail
            website
            installerBanner
            privilegeBenefit
            tripLocations
            dcNum
            storeId
            storeNavisionId
            storeNum
            arNum
            storeNum2
            arNum2
            storeNum3
            arNum3
            language
            latitude
            longitude
            previousPointsGoTo {
                id
                privilegeMemberId
                privilegeNavisionId
                # privilegeSales: Float
                # privilegeEarnings: Float
                installerNavisionId
                memberType
                status
                isGroupMember
                groupName
                legalName
                businessName
                ownerName
                contactName
                address
                city
                province
                postal
                phone
                email
                privilegeEmail
                website
                installerBanner
                privilegeBenefit
                tripLocations
                dcNum
                storeId
                storeNavisionId
                storeNum
                arNum
                pointsGoTo
                isRegistered
                accountsPoolingPoints
                registrationLock
                language
            }
            pointsGoTo
            isRegistered
            accountsPoolingPoints
            registrationLock
        }
    }
`

export const REGISTRATION_LOCK = gql`
    query RegistrationLock($payload: Privilege_LockRegistrationPayload!) {
        privilege_lockRegistration(payload: $payload) {
            id
        }
    }
`

export const GET_PREVIOUS_ACCOUNTS_RECEIVABLE = gql`
    query PreviousAccountsReceivable($id: ID!) {
        privilege_previousAccountsReceivable(id: $id) {
            id
            navisionId
            napaNumber
            businessName
            address
            city
            province
            postal
            arNumber
        }
    }
`

export const GET_PREVIOUS_MEMBERS = gql`
    query GetPreviousMembers($filter: SearchFilter!) {
        privilege_tempMembers(filter: $filter) {
            cursor
            count
            results {
                id
                privilegeMemberId
                privilegeNavisionId
                installerNavisionId
                memberType
                status
                isGroupMember
                groupName
                legalName
                businessName
                ownerName
                contactName
                address
                city
                province
                postal
                phone
                email
                privilegeEmail
                website
                installerBanner
                privilegeBenefit
                tripLocations
                dcNum
                storeId
                storeNavisionId
                storeNum
                arNum
                storeNum2
                arNum2
                storeNum3
                arNum3
                language
                latitude
                longitude
                isRegistered
                isGroupRegistered
            }
        }
    }
`

export const GET_CURRENT_MEMBERS = gql`
    query GetCurrentMembers($filter: SearchFilter!, $offset: Int, $limit: Int) {
        privilege_members(filter: $filter, offset: $offset, limit: $limit) {
            cursor
            count
            results {
                id
                privilegeMemberId
                privilegeNavisionId
                installerNavisionId
                pointsGoTo
                memberType
                status
                isGroupMember
                groupName
                legalName
                businessName
                ownerName
                contactName
                address
                city
                province
                postal
                phone
                email
                privilegeEmail
                website
                installerBanner
                privilegeBenefit
                isTrip
                tripLocations
                dcNum
                storeId
                storeNavisionId
                storeNum
                arNum
                storeNum2
                arNum2
                storeNum3
                arNum3
                language
                latitude
                longitude
                enteredDate
                stagingId
                memberId
                isMember
                bothTrips

                totalSales
                totalEarnings
                totalRedemptions
                maxEarningCap

                redemptionCount
                visaCardsRedeemed
                visaCardTotal
                certificatesRedeemed
                certificateTotal
                targetEarnings
                isEarningTargetReached
                pointsGoToAccount {
                    id
                    installerNavisionId
                }

                installer {
                    user {
                        id
                        uuid
                        username
                        isActive
                        validationEmailSent
                        validationEmailResends
                        isValidEmail
                    }
                }
            }
        }
    }
`

export const GET_MEMBER_RECORD = gql`
    query MemberRecord($id: ID!) {
        privilege_record: privilege_memberRecord(id: $id) {
            id
            balance
            totalSales
            totalEarnings
            totalRedemptions
            memberType
            arNumber
            installerNavisionId
            privilegeNavisionId
            pointsGoTo
            privilegeBenefit
            status
            ossName
            storeNavisionId
            memberId
            stagingId
            isMember
            dateReceived
            privilegeBenefitDetails {
                id
                location
                location_en
                location_fr
                slug
                primaryTrip
                imageUrl
                travelDates_en
                travelDetails_en
                travelDates_fr
                travelDetails_fr
            }
            tripLocations
            isTrip
            enteredDate
            maxEarningCap
            privilegeEarningStart
            tripAttendeeCount
            tripAttendeeChildrenCount
            tripHasExtension
            extensionName
            extensionAttendeeCount
            extensionAttendeeChildrenCount
            extensionDetails {
                id
                tripId
                extension
                extension_en
                extension_fr
            }
            hasBothTrips
            secondaryMemberships {
                id
                privilegeBenefit
                privilegeBenefitDetails {
                    id
                    location
                    location_en
                    location_fr
                    slug
                    primaryTrip
                    imageUrl
                    travelDates_en
                    travelDetails_en
                    travelDates_fr
                    travelDetails_fr
                }
                tripLocations
                isTrip
                tripAttendeeCount
                tripAttendeeChildrenCount
                tripHasExtension
                extensionName
                extensionAttendeeCount
                extensionAttendeeChildrenCount
            }
            sales {
                id
                recordType
                arNumber
                pointsGoTo
                monthlySales
                month
                year
                totalPoints
                pointsRedeemable
                status
                creditReason
                installerNavisionId
                privilegeNavisionId
                storeNapaNumber
                # visaCardNumber: String
                enteredAt
                enteredBy
                statusAt
                statusBy
                reportedAt
                excludeFromReport
                salesCompany
            }
            installer {
                id
                navisionId
                ownerName
                businessName
                legalName
                address
                city
                province
                language
                email
                privilegeEmail
                postal
                phone
                cellPhone

                installerNumber
            }
            pointsGoToAccount {
                id
                businessName
                address
                city
                province
                postal
                installerNavisionId
                privilegeNavisionId
                storeNavisionId
                memberId
                stagingId
                isMember
            }
        }
    }
`
