import React from 'react'
import { ME } from '../../operations/Auth'
import { GET_BDSS } from '../../operations/Bds'
import { useTranslation } from 'react-i18next'
import Card, { CardSection } from '../../components/Card'
import { useGlobalState } from '../../hooks/useGlobalState'
import Loader from '../../components/Loader'
import { useQuery } from '@apollo/client'
import { useAuthentication } from 'hooks/useAuthentication'
import AccessLevelStats from 'components/AccessLevelStats'

export default function Bds() {
    // const { t } = useTranslation()

    const { error, loading, data } = useQuery(ME)

    if (loading) return <Loader />
    if (error) return `Error! ${error.message}`

    const { userPermissionGrants } = data.me

    if (!userPermissionGrants || userPermissionGrants.length < 1) {
        return null
    }

    const bdsIds = []
    userPermissionGrants.map((grant) => {
        return bdsIds.push(grant.username)
    })

    return <BdsQuery filter={{ filter: { email: { in: bdsIds } } }} />
}

function BdsQuery({ filter = {} }) {
    const { t } = useTranslation()
    const { setBds } = useGlobalState()
    const { user } = useAuthentication()

    let queryVars = filter

    const { error, loading, data } = useQuery(GET_BDSS, {
        variables: { ...queryVars },
    })

    if (loading) return <Loader />
    if (error) return `Error! ${error.message}`

    const bdsList = data?.bdss?.map((current) => current.id)

    return (
        <div className="contentContainer">
            {user.userType === 'DIRECTOR' && (
                <AccessLevelStats
                    level="DIRECTOR"
                    selector={bdsList?.join(',')}
                />
            )}
            <h1>{t('My Business Development Specialists')}</h1>
            {data?.bdss.map((bds) => {
                const { id } = bds
                return (
                    <div className="cardContainer" key={id}>
                        <Card onClick={() => setBds(bds)}>
                            <div
                                className="grid"
                                style={{
                                    gridTemplateColumns: `
                                    minmax(200px, 0.5fr)
                                    minmax(400px, 2fr)
                                    `,
                                }}
                            >
                                <CardSection title={t('bdsName', 'Name')}>
                                    {bds.firstName} {bds.lastName}
                                </CardSection>
                                <CardSection title={t('bdsEmail', 'Email')}>
                                    {bds.email}
                                </CardSection>
                            </div>
                        </Card>
                    </div>
                )
            })}
        </div>
    )
}
