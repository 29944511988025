import { useState } from 'react'
import { useQuery } from '@apollo/client'
import Card, { CardSection } from 'components/Card'
import Formatting from 'components/Formatting'
import Installer from 'components/Installer'
import Loader from 'components/Loader'
import NoData from 'components/NoData'
import Pill from 'components/Pill'
import { GET_STAGING_RECORD } from 'operations/Staging'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'
import Modal, { ModalClose } from 'components/Modal'
import { DateTime } from 'luxon'
import { Img } from 'components/Tueri'
import Button from 'components/Button'
import { FormatDate } from 'components/Format'
import { GET_MEMBER_RECORD } from 'operations/Members'

export default function Enrollment() {
    const history = useHistory()

    const { t } = useTranslation()

    const { id } = useParams()

    const location = useLocation()

    const [tab] = useState('EARNINGS')

    const [complete, setComplete] = useState(
        location.state && location.state.complete ? true : false,
    )

    const { error, loading, data } = useQuery(GET_STAGING_RECORD, {
        variables: { id: id },
    })

    if (error) return 'Error loading enrollment.'

    const asp = data && data.privilege_record

    const isStore = asp && asp.memberType && asp.memberType === 'store'
    const bothTrips =
        isStore && asp.comments && asp.comments.includes('BOTH TRIPS REQUESTED')

    function parseStagingStatus(value) {
        switch (value) {
            case 'PENDING':
                return t('enrollmentStatusPending', 'Pending')
            case 'ACCEPTED':
                return t('enrollmentStatusAccepted', 'Accepted')
            case 'REJECTED':
                return t('enrollmentStatusRejected', 'Rejected')
            case 'CHECKING':
                return t('enrollmentStatusChecking', 'Checking')
            default:
                return t('enrollmentStatusPending', 'Pending')
        }
    }

    if (id === 'store')
        return (
            <div className="contentContainer">
                <h1>{t('My Privilege Passport')}</h1>

                {complete && (
                    <Modal>
                        <ModalClose onClick={() => setComplete()} />
                        {/* <ModalTitle> */}
                        <h2>
                            {t(
                                'Thank you for submitting your Privilege enrollment!',
                            )}
                        </h2>
                        {/* </ModalTitle> */}
                    </Modal>
                )}

                {!loading && data && !asp && (
                    <>
                        <NoData>
                            {t(
                                'noStoreRegistration',
                                `Looks like you haven't registered for a trip yet.`,
                            )}
                        </NoData>
                        <Button
                            context="primary"
                            onClick={() => history.push('/register-store')}
                        >
                            Register now
                        </Button>
                    </>
                )}

                {!loading && data && asp && (
                    <>
                        <Card
                            style={{
                                display: 'flex',
                                gap: '2rem',
                                justifyContent: 'space-between',
                            }}
                        >
                            <CardSection title={t('Owner')}>
                                {isStore ? asp.ossName : asp.ownerName}
                                <br />
                                {isStore ? asp.ossEmail : asp.ownerEmail}
                            </CardSection>

                            <CardSection title={t('Phone')}>
                                <Formatting phone>
                                    {isStore ? asp.cellPhone : asp.phone}
                                </Formatting>
                            </CardSection>
                            <CardSection
                                title={t(
                                    'cardHeaderPrivBenefit',
                                    'Privilege benefit',
                                )}
                            >
                                {bothTrips ? (
                                    <div>{t('Both trips requested')}</div>
                                ) : (
                                    asp.privilegeBenefit
                                )}
                            </CardSection>
                            {asp.pointsGoTo &&
                                asp.pointsGoTo !== asp.privilegeNavisionId && (
                                    <CardSection title={t('Points pooling')}>
                                        {
                                            asp.pointsGoToAccount
                                                .installerNavisionId
                                        }
                                    </CardSection>
                                )}
                            <CardSection title={t('Enrolled')}>
                                <FormatDate format="DATE_FULL">
                                    {DateTime.fromMillis(asp.enteredDate)}
                                </FormatDate>
                            </CardSection>
                            <CardSection title={t('Status')}>
                                <Pill>{parseStagingStatus(asp.status)}</Pill>
                            </CardSection>
                        </Card>

                        {/* <div style={{ display: 'flex', gap: '2rem', marginBottom: '2rem', marginTop: '4rem' }}>
                        <button type='button' className={`tabButton ${ tab && tab === 'EARNINGS' ? 'tabButton--selected' : '' }`} onClick={() => setTab('EARNINGS')}>{ t('Earnings') }</button>
                        <button type='button' className={`tabButton ${ tab && tab === 'REWARDS' ? 'tabButton--selected' : '' }`}  onClick={() => setTab('REWARDS')}>{ t('Rewards') }</button>
                    </div> */}

                        {tab === 'EARNINGS' && (
                            <>
                                <h2>{t('Earnings')}</h2>
                                <NoData>
                                    {t(
                                        'noEarningsActivity',
                                        'No earnings to display',
                                    )}
                                </NoData>
                            </>
                        )}

                        {tab === 'REWARDS' && (
                            <>
                                <h2>{t('Rewards')}</h2>
                                <div
                                    style={{
                                        position: 'relative',
                                        height: '320px',
                                    }}
                                >
                                    <Img
                                        src={`https://cdn.tueri.io/68719476737/napaprivilege/TRIP/florida/iStock-1001603158.jpg`}
                                        alt="Florida"
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        )

    console.log({ data })

    return (
        <div className="contentContainer">
            {complete && (
                <Modal>
                    <ModalClose onClick={() => setComplete()} />
                    {/* <ModalTitle> */}
                    <h2>
                        {t(
                            'Thank you for submitting your Privilege enrollment!',
                        )}
                    </h2>
                    {/* </ModalTitle> */}
                </Modal>
            )}

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <h1>{t('Customer')}</h1>
            </div>

            {loading && <Loader />}

            {!loading && data && !asp && (
                <NoData>{t('No enrollment to display')}</NoData>
            )}

            {!loading && data && asp && (
                <>
                    <Installer installer={asp} noClose />

                    <Card
                        style={{
                            display: 'flex',
                            gap: '2rem',
                            justifyContent: 'space-between',
                        }}
                    >
                        <CardSection title={t('Owner')}>
                            {isStore ? asp.ossName : asp.ownerName}
                            <br />
                            {isStore ? asp.ossEmail : asp.ownerEmail}
                        </CardSection>

                        <CardSection title={t('Phone')}>
                            <Formatting phone>
                                {isStore ? asp.cellPhone : asp.phone}
                            </Formatting>
                        </CardSection>
                        <CardSection
                            title={t(
                                'cardHeaderPrivBenefit',
                                'Privilege benefit',
                            )}
                        >
                            {bothTrips ? (
                                <div>{t('Both trips requested')}</div>
                            ) : (
                                asp.privilegeBenefit
                            )}
                        </CardSection>
                        <CardSection title={t('Sales rep')}>
                            {asp.ossName}
                        </CardSection>
                        {asp.pointsGoTo &&
                            asp.pointsGoTo !== asp.privilegeNavisionId && (
                                <CardSection title={t('Points pooling')}>
                                    {asp.pointsGoToAccount.installerNavisionId}
                                </CardSection>
                            )}
                        <CardSection title={t('Enrolled')}>
                            <FormatDate format="DATE_FULL">
                                {DateTime.fromMillis(asp.enteredDate)}
                            </FormatDate>
                        </CardSection>
                        <CardSection title={t('Status')}>
                            <Pill>{parseStagingStatus(asp.status)}</Pill>
                        </CardSection>
                    </Card>

                    {/* <div style={{ display: 'flex', gap: '2rem', marginBottom: '2rem', marginTop: '4rem' }}>
                        <button type='button' className={`tabButton ${ tab && tab === 'EARNINGS' ? 'tabButton--selected' : '' }`} onClick={() => setTab('EARNINGS')}>{ t('Earnings') }</button>
                        <button type='button' className={`tabButton ${ tab && tab === 'REWARDS' ? 'tabButton--selected' : '' }`}  onClick={() => setTab('REWARDS')}>{ t('Rewards') }</button>
                    </div> */}

                    {tab === 'EARNINGS' && (
                        <>
                            <h2>{t('Earnings')}</h2>
                            <NoData>
                                {t(
                                    'noEarningsActivity',
                                    'No earnings to display',
                                )}
                            </NoData>
                        </>
                    )}

                    {tab === 'REWARDS' && (
                        <>
                            <h2>{t('Rewards')}</h2>
                            <div
                                style={{
                                    position: 'relative',
                                    height: '320px',
                                }}
                            >
                                <Img
                                    src={`https://cdn.tueri.io/68719476737/napaprivilege/TRIP/florida/iStock-1001603158.jpg`}
                                    alt="Florida"
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    )
}
