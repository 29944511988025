import React, { useEffect } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useGlobalState } from '../../hooks/useGlobalState'

import { OAUTH_AUTHENTICATE } from '../../operations/Auth'
import Loader from '../../components/Loader'
import { useTranslation } from 'react-i18next'

export default function OAUTH({ match, history }) {

    const { i18n } = useTranslation()

    const { setAuthenticated } = useAuthentication()
    const { resetGlobalState } = useGlobalState()
    const client = useApolloClient()

    useEffect(() => {
        // DESTROY ANY PREVIOUS SESSIONS
        setAuthenticated(false)
        resetGlobalState()
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        client.resetStore()
        // eslint-disable-next-line
    }, [])    

    const { language, token } = match.params

    useEffect(() => {
		if (i18n.language !== language) i18n.changeLanguage(language)
	},[i18n, language])

    const { error, loading, data } = useQuery(OAUTH_AUTHENTICATE, { variables: { payload: { token } } })

    if (error || loading) return <Loader />

    const { oauthAuthenticate: authenticate } = data

    localStorage.setItem('accessToken', authenticate.accessToken)
    localStorage.setItem('refreshToken', authenticate.refreshToken)
    setAuthenticated(true)
    history.push('/')

    return null

}