import { useQuery } from '@apollo/client'
import { GET_MAJOR_ACCOUNT } from 'operations/MajorAccount'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useGlobalState } from './useGlobalState'

const MajorAccountContext = createContext()

export default function MajorAccountProvider({ children }) {
    const { majorAccount } = useGlobalState()

    const [state, setState] = useState()

    const { data, refetch } = useQuery(GET_MAJOR_ACCOUNT, {
        variables: { account: majorAccount?.account },
        skip: !majorAccount?.account,
        fetchPolicy: 'cache-and-network',
    })

    useEffect(() => {
        if (data) {
            setState(data.majorAccount)
        }
    }, [data])

    return (
        <MajorAccountContext.Provider
            value={{
                majorAccount: majorAccount ? state : null,
                refetch: () => refetch(),
            }}
        >
            {children}
        </MajorAccountContext.Provider>
    )
}

export function useMajorAccount() {
    return useContext(MajorAccountContext)
}
