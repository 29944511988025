import React from 'react'
import { useForm } from './FormProvider'

// NOTE: The type = "text" declaration below serves as a default value for that component property
export default function Submit({
    children = 'Submit',
    value,
    className = '',
    context,
    fullWidth,
    disabled = false,
    onClick,
    style,
}) {
    const { isSubmitted } = useForm()

    return (
        <button
            style={style}
            className={`submitButton ${
                fullWidth ? 'submitButton--fullWidth' : ''
            } ${isSubmitted ? 'submitButton--loading' : ''} ${
                context ? 'submitButton--' + context : ''
            } ${className}`}
            disabled={isSubmitted || disabled}
            onClick={(e) => {
                if (onClick) {
                    onClick(e)
                }
            }}
            value={value}
        >
            {children}
            {isSubmitted && <div className="formLoader" />}
        </button>
    )
}
