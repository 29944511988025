import './installer.scss'
import { useTranslation } from 'react-i18next'

export default function Installer({
    installer,
    onClear,
    alternate,
    noClose = false,
}) {
    const { t } = useTranslation()

    return (
        <div
            className={`aspContainer ${
                alternate ? 'aspContainer--alternate' : ''
            }`}
        >
            <div className="asp__businessName">
                {installer.businessName ? installer.businessName : t('New ASP')}{' '}
                {installer.installerNavisionId && (
                    <>({installer.installerNavisionId})</>
                )}
            </div>
            {installer.businessName && (
                <div className="asp__address">
                    {installer.address}, {installer.city}, {installer.province}{' '}
                    {installer.postal}{' '}
                    {installer.navisionId && `(${installer.navisionId})`}
                </div>
            )}
            {!noClose && (
                <button
                    type="button"
                    className="clearAsp"
                    onClick={() => onClear && onClear()}
                >
                    <i className="material-icons">close</i>
                </button>
            )}
        </div>
    )
}
