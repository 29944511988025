import { useEffect, useState } from 'react'

export function ProgressBarSimple({ percent: percentage, color, target }) {
    const [rendered, setRendered] = useState(false)
    const [percent, setPercent] = useState(0)

    useEffect(() => {
        setPercent(percentage)
        setRendered(true)
    }, [percentage])

    return (
        <div style={{ height: '4px', position: 'relative', width: '100%' }}>
            <div
                style={{
                    height: '100%',
                    borderRadius: '9999px',
                    background: '#ccc',
                    marginTop: '4px',
                    border: '1px solid #ddd',
                    overflow: 'hidden',
                }}
            >
                <div
                    style={{
                        borderRadius: '9999px',
                        height: '100%',
                        transition: 'width 300ms',
                        width: `${percent < 1 && rendered ? 100 : percent}%`,
                        background: color,
                    }}
                    // className={`rounded-r-full h-full transition-width duration-300 ${
                    //     disabled
                    //         ? 'bg-slate-50'
                    //         : percent < 25
                    //         ? 'bg-priority-high'
                    //         : percent < 50
                    //         ? 'bg-priority-medium'
                    //         : percent < 75
                    //         ? 'bg-priority-low'
                    //         : percent < 100
                    //         ? 'bg-primary'
                    //         : 'bg-green-500'
                    // }`}
                />
            </div>
            <div
                style={{
                    width: '3px',
                    height: '16px',
                    top: '-5px',
                    background: 'rgba(0,0,0,0.5)',
                    position: 'absolute',
                    left: `calc(${target}% - 1px)`,
                    border: '1px solid rgba(241, 245, 249, 1)',
                    boxSizing: 'border-box',
                }}
            />
            {/* <div
        className="w-[1px] bg-black/30 absolute top-0 left-[75%]"
        style={{ height: "calc(100% + 4px)", marginTop: "-2px" }}
      /> */}
        </div>
    )
}
