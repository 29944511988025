import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import './styles/index.css'
import './styles/index.scss'
// import * as Sentry from '@sentry/browser'

import Routes from './Routes'
import GlobalStateProvider from './hooks/useGlobalState'
import AuthenticationProvider from './hooks/useAuthentication'
import { ApolloProvider } from '@apollo/client'
import client from './Apollo'
import * as serviceWorker from './serviceWorker'
import TueriProvider from './components/Tueri'

import HealthCheck from './components/HealthCheck'
import RefreshProvider from './hooks/useRefresh'
import Loader from './components/Loader'

import './i18n'
import CompanyProvider from 'hooks/company'
import SalesRepProvider from 'hooks/salesRep'
import ModalProvider from 'hooks/modal'
import PrivilegeProvider from 'hooks/privilege'
import MajorAccountProvider from 'hooks/majorAccount'
import StoreProvider from 'hooks/store'
import CartProvider from 'hooks/cart'
import CampaignProvider from 'hooks/campaign'

// Sentry.init({
//     dsn: "https://ae5edf8d405e4b1d9fbddb86d7731b0a@o126375.ingest.sentry.io/5285705",
//     environment: process.env.NODE_ENV
// })

ReactDOM.render(
    <CompanyProvider>
        <RefreshProvider>
            <TueriProvider>
                <ApolloProvider client={client}>
                    {/* <ApolloHooksProvider client={client}> */}
                    <GlobalStateProvider>
                        <AuthenticationProvider>
                            <CampaignProvider>
                                <MajorAccountProvider>
                                    <StoreProvider>
                                        <SalesRepProvider>
                                            <CartProvider>
                                                <Suspense fallback={<Loader />}>
                                                    <HealthCheck />
                                                    <ModalProvider>
                                                        <PrivilegeProvider>
                                                            <Routes />
                                                        </PrivilegeProvider>
                                                    </ModalProvider>
                                                </Suspense>
                                            </CartProvider>
                                        </SalesRepProvider>
                                    </StoreProvider>
                                </MajorAccountProvider>
                            </CampaignProvider>
                        </AuthenticationProvider>
                    </GlobalStateProvider>
                    {/* </ApolloHooksProvider> */}
                </ApolloProvider>
            </TueriProvider>
        </RefreshProvider>
    </CompanyProvider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
