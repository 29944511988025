import { useMutation, useQuery } from '@apollo/client'
import Card, { CardIcon, CardSection } from 'components/Card'
import Loader from 'components/Loader'
import Pill from 'components/Pill'
import useInfiniteScroll from 'hooks/useInfiniteScroll'
import { GET_CURRENT_MEMBERS } from 'operations/Members'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { useAuthentication } from 'hooks/useAuthentication'
import { useGlobalState } from 'hooks/useGlobalState'
import { FormatCurrency, FormatDate } from 'components/Format'
import NoData from 'components/NoData'
import { useMajorAccount } from 'hooks/majorAccount'
import Form, { Field, Submit } from 'components/Form'
import { redisReplace } from 'components/redisReplace'

import { PrivilegeBenefitLogo } from 'components/InstallerDashboard'
import currency from 'currency.js'
import { ProgressBarSimple } from 'components/ProgressBar/ProgressBarSimple'
import { RESEND_INSTALLER_INVITATION } from 'operations/Installer'

const limit = 50
const sortBy = 'businessName'
const sortDirection = 'ASC'

export default function Installers() {
    const { t } = useTranslation()

    const { user } = useAuthentication()
    const { store, bds } = useGlobalState()

    const { majorAccount } = useMajorAccount()

    let searchString = ''
    if (user.userType === 'STORE')
        searchString = `@storeNavisionId:{${user.navisionId}}`
    if (
        [
            'AGENT',
            'HEAD_OFFICE',
            'SALES_REP',
            'TERRITORY_SALES_MANAGER',
            'DC',
        ].includes(user.userType) &&
        store
    )
        searchString = `@storeNavisionId:{${store.navisionId}}`
    if (['DIRECTOR'].includes(user.userType) && bds) {
        searchString = `@bdsId:{${bds.id}}`
    }

    if (['BUSINESS_DEVELOPMENT_SPECIALIST'].includes(user.userType)) {
        searchString = `@bdsId:{${user.linkId}}`
    }

    const [filter, setFilter] = useState({})

    const [search, setSearch] = useState(
        searchString + ` @lastUpdatedAt:[-inf +inf]`,
    )

    const [input, setInput] = useState('')
    let storeNavisionId = ''
    if (user.userType === 'STORE') storeNavisionId = user?.navisionId
    if (
        [
            'AGENT',
            'HEAD_OFFICE',
            'SALES_REP',
            'TERRITORY_SALES_MANAGER',
            'DC',
        ].includes(user.userType) &&
        !majorAccount &&
        store
    ) {
        storeNavisionId = store.navisionId
    }
    let groupName = ''
    if (majorAccount) groupName = majorAccount.account

    // const [search] = useState(`@storeNavisionId:{${storeNavisionId}}`)

    const [isFetching, setIsFetching] = useInfiniteScroll(loadMore)

    const filterString = Object.keys(filter)
        ?.map((key) => `@${key}:{${filter[key]}}`)
        .join('')

    const {
        // error,
        loading,
        data,
        fetchMore,
        refetch,
    } = useQuery(GET_CURRENT_MEMBERS, {
        variables: {
            filter: {
                search: input + ' ' + search + ' ' + filterString,
                storeNavisionId,
                groupName,
                sortBy,
                sortDirection,
            },
            offset: 0,
            limit: limit,
        },
        notifyOnNetworkStatusChange: true,
    })

    // My NAPA Programs Invitation Resend
    const [MNPInvitations, setMNPInvitations] = useState([])
    const [resendInstallerInvitation] = useMutation(RESEND_INSTALLER_INVITATION)

    useEffect(() => {
        if (majorAccount && majorAccount.account) {
            const searchString = `@groupName:{${majorAccount?.account
                ?.replace(/-/g, '\\-')
                .replace(/'/g, "\\'")}}`
            setSearch(searchString)
        }
    }, [majorAccount])

    useEffect(() => {
        refetch()
    }, [refetch, search, input, filter])

    // const BENEFITS = {
    //     GIFT_CARD: t('Gift Card/Certificates'),
    //     TRIP: t('Trip')
    // }

    function parseStagingStatus(value) {
        switch (value) {
            case 'PENDING':
                return t('enrollmentStatusPending', 'Pending')
            case 'ACCEPTED':
                return t('enrollmentStatusAccepted', 'Accepted')
            case 'REJECTED':
                return t('enrollmentStatusRejected', 'Rejected')
            case 'CHECKING':
                return t('enrollmentStatusChecking', 'Checking')
            case 'ACTIVE':
                return t('enrollmentStatusActive', 'Active')
            case 'INACTIVE':
                return t('enrollmentStatusInactive', 'Inactive')
            default:
                return t('enrollmentStatusPending', 'Pending')
        }
    }

    async function loadMore() {
        if (
            data &&
            data.privilege_members &&
            data.privilege_members.cursor < data.privilege_members.count
        ) {
            await fetchMore({
                variables: {
                    filter: {
                        search: input + ' ' + search + ' ' + filterString,
                        storeNavisionId,
                        groupName,
                        sortBy,
                        sortDirection,
                    },
                    offset: data.privilege_members.cursor,
                    limit: limit,
                },
            })

            setIsFetching(false)
        }
    }

    console.log({ data })

    return (
        <>
            <Form>
                {() => (
                    <Field
                        name="search"
                        label={t(
                            'aspSearchLabel',
                            'Search for an ASP by business name, address, NT#, etc.',
                        )}
                        noOptional
                        onChange={(e) =>
                            setInput(
                                e.target.value.length > 0
                                    ? `${redisReplace(e.target.value)}*`
                                    : e.target.value,
                            )
                        }
                        defaultValue={input}
                    />
                )}
            </Form>
            {loading && !isFetching && <Loader />}

            <div className="filterGrid">
                Filter results:
                <button
                    type="button"
                    onClick={() =>
                        setFilter((filter) => ({
                            ...filterToggle(filter, 'isTrip', 'true'),
                        }))
                    }
                    className={`${filter?.isTrip === 'true' ? 'active' : ''}`}
                >
                    Passport
                </button>
                <button
                    type="button"
                    onClick={() =>
                        setFilter((filter) => ({
                            ...filterToggle(filter, 'isTrip', 'false'),
                        }))
                    }
                    className={`${filter?.isTrip === 'false' ? 'active' : ''}`}
                >
                    Rewards
                </button>
                <div
                    style={{
                        background: 'rgba(0,0,0,0.2)',
                        width: '1px',
                        height: '12px',
                    }}
                />
                <button
                    type="button"
                    onClick={() =>
                        setFilter((filter) => ({
                            ...filterToggle(filter, 'status', 'ACTIVE'),
                        }))
                    }
                    className={`${filter?.status === 'ACTIVE' ? 'active' : ''}`}
                >
                    Active
                </button>
                <button
                    type="button"
                    onClick={() =>
                        setFilter((filter) => ({
                            ...filterToggle(filter, 'status', 'ACCEPTED'),
                        }))
                    }
                    className={`${
                        filter?.status === 'ACCEPTED' ? 'active' : ''
                    }`}
                >
                    Accepted
                </button>
                <button
                    type="button"
                    onClick={() =>
                        setFilter((filter) => ({
                            ...filterToggle(filter, 'status', 'REJECTED'),
                        }))
                    }
                    className={`${
                        filter?.status === 'REJECTED' ? 'active' : ''
                    }`}
                >
                    Rejected
                </button>
                <button
                    type="button"
                    onClick={() =>
                        setFilter((filter) => ({
                            ...filterToggle(filter, 'status', 'CHECKING'),
                        }))
                    }
                    className={`${
                        filter?.status === 'CHECKING' ? 'active' : ''
                    }`}
                >
                    Checking
                </button>
                <button
                    type="button"
                    onClick={() => setFilter('')}
                    className="clear"
                >
                    Clear
                </button>
            </div>

            {data &&
                data.privilege_members &&
                data.privilege_members.results.length < 1 && (
                    <NoData>
                        {Object.keys(filter)?.length > 0
                            ? t(
                                  'noCurrentMembersFiltered',
                                  `Looks like you don't have any customers matching that filter.`,
                              )
                            : t(
                                  'noCurrentMembers',
                                  `Looks like you don't have any customers enrolled yet.`,
                              )}
                    </NoData>
                )}

            {data &&
                data.privilege_members &&
                data.privilege_members.results.map((member) => {
                    return (
                        <div
                            key={member.id}
                            style={{
                                marginBottom: '4rem',
                                position: 'relative',
                            }}
                        >
                            <Card
                                to={`/enrollment/${
                                    member.isMember ? 'member' : 'staging'
                                }/${member.id}`}
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns:
                                        '40px 1fr 1.5fr 1fr .3fr',
                                    gap: '2rem',
                                }}
                                className="customerCard"
                            >
                                {/* <CardStatus status={ member.status } /> */}
                                {/* <CardIcon
                                icon={
                                    member.isTrip ? 'flight' : 'card_giftcard'
                                }
                            /> */}
                                <div className="benefitLogoOffsetContainer">
                                    <PrivilegeBenefitLogo
                                        membership={member}
                                        type="sm"
                                    />
                                </div>

                                <CardSection
                                    title={
                                        <>
                                            {t('asp-colon', 'ASP:')}{' '}
                                            {member.installerNavisionId}{' '}
                                            <Pill
                                                // style={{
                                                //     position: 'absolute',
                                                //     top: '-8px',
                                                //     right: '1rem',
                                                //     boxShadow: `0 1px 1px 0 rgba(60, 64, 67, 0.08),
                                                //     0 1px 3px 1px rgba(60, 64, 67, 0.16)`,
                                                // }}
                                                pillType="status"
                                                variant={
                                                    ['ACTIVE'].includes(
                                                        member.status,
                                                    )
                                                        ? 'success'
                                                        : ['ACCEPTED'].includes(
                                                              member.status,
                                                          )
                                                        ? 'secondary'
                                                        : ['REJECTED'].includes(
                                                              member.status,
                                                          )
                                                        ? 'rejected'
                                                        : ['CHECKING'].includes(
                                                              member.status,
                                                          )
                                                        ? 'checking'
                                                        : ''
                                                }
                                            >
                                                {parseStagingStatus(
                                                    member.status,
                                                )}
                                            </Pill>
                                        </>
                                    }
                                >
                                    {member.businessName}
                                    {/* <br />
                                <div style={{ marginTop: '.5rem' }}>
                                    <Pill>
                                        {member.installerNavisionId ||
                                            t(
                                                'enrollmentStatusPending',
                                                'PENDING',
                                            )}
                                    </Pill>
                                </div> */}
                                </CardSection>
                                <CardSection title={t('Address')}>
                                    {member.address} {member.city},{' '}
                                    {member.province}
                                    {/* <strong>Phone:</strong> <Formatting phone>{ member.phone }</Formatting><br/>
                                <strong>Email:</strong> { member.email } */}
                                </CardSection>
                                {member.privilegeNavisionId ===
                                member.pointsGoTo ? (
                                    <CardSection
                                        title={t(
                                            'cardHeaderPrivBenefit',
                                            'Privilege Benefit',
                                        )}
                                    >
                                        <Pill variant="secondary">
                                            {member.bothTrips
                                                ? t('bothTrips', 'Both Trips')
                                                : member.privilegeBenefit}
                                        </Pill>
                                    </CardSection>
                                ) : (
                                    <CardSection
                                        title={t(
                                            'cardHeaderPointsPooling',
                                            'Points pooling',
                                        )}
                                    >
                                        <Pill>
                                            Pooling to{' '}
                                            {
                                                member.pointsGoToAccount
                                                    ?.installerNavisionId
                                            }
                                        </Pill>
                                    </CardSection>
                                )}

                                <CardSection title={t('Enrolled')}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '1rem',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {/* {DateTime.fromMillis(
                                        member.enteredDate,
                                    ).toLocaleString(DateTime.DATE_MED)} */}
                                        <FormatDate format="DATE_MED">
                                            {DateTime.fromMillis(
                                                member.enteredDate,
                                            )}
                                        </FormatDate>
                                    </div>
                                </CardSection>
                            </Card>
                            <div className="subCardSection">
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: '1rem',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <strong title="My NAPA Programs Account">
                                            MNP Account:
                                        </strong>{' '}
                                        {member?.installer?.user?.isActive &&
                                            member?.installer?.user
                                                ?.isValidEmail && (
                                                <Pill>Active</Pill>
                                            )}
                                        {member?.installer?.user?.id &&
                                            member.installer.user.isActive &&
                                            !member?.installer?.user
                                                ?.isValidEmail && (
                                                <>
                                                    {MNPInvitations.includes(
                                                        member?.installer?.user
                                                            ?.id,
                                                    ) ? (
                                                        <Pill>Invite sent</Pill>
                                                    ) : (
                                                        <Form
                                                            onSubmit={async () => {
                                                                await resendInstallerInvitation(
                                                                    {
                                                                        variables:
                                                                            {
                                                                                payload:
                                                                                    {
                                                                                        navisionId:
                                                                                            member
                                                                                                ?.installer
                                                                                                ?.user
                                                                                                ?.username,
                                                                                    },
                                                                            },
                                                                    },
                                                                )

                                                                setMNPInvitations(
                                                                    (
                                                                        MNPInvitations,
                                                                    ) => {
                                                                        return [
                                                                            ...MNPInvitations,
                                                                            member
                                                                                ?.installer
                                                                                ?.user
                                                                                ?.id,
                                                                        ]
                                                                    },
                                                                )
                                                            }}
                                                        >
                                                            {() => (
                                                                <Submit
                                                                    style={{
                                                                        padding:
                                                                            '.5rem 1rem',
                                                                        fontSize:
                                                                            '1.2rem',
                                                                    }}
                                                                    disabled={
                                                                        member
                                                                            ?.installer
                                                                            ?.user
                                                                            ?.validationEmailResends >=
                                                                        3
                                                                    }
                                                                >
                                                                    {member
                                                                        ?.installer
                                                                        ?.user
                                                                        ?.validationEmailResends >=
                                                                    3
                                                                        ? `Invite limit reached`
                                                                        : `Re-send
                                                                        invite`}
                                                                </Submit>
                                                            )}
                                                        </Form>
                                                    )}
                                                </>
                                            )}
                                        {!member?.installer?.user?.id && (
                                            <Pill>Pending</Pill>
                                        )}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div>
                                        <strong>Sales:</strong>{' '}
                                        <FormatCurrency>
                                            {member.totalSales}
                                        </FormatCurrency>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div>
                                        <strong>Earnings:</strong>{' '}
                                        <FormatCurrency>
                                            {member.totalEarnings}
                                        </FormatCurrency>
                                    </div>
                                </div>
                                {!member.isTrip && (
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: '1fr 1fr',
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>
                                                <strong>Redemptions:</strong>{' '}
                                                <FormatCurrency>
                                                    {member.totalRedemptions}
                                                </FormatCurrency>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <div>
                                                <strong>Balance:</strong>{' '}
                                                <FormatCurrency>
                                                    {member.totalEarnings -
                                                        member.totalRedemptions}
                                                </FormatCurrency>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {member.isTrip &&
                                    member.privilegeNavisionId ===
                                        member.pointsGoTo &&
                                    member.pointsGoTo &&
                                    member.isMember && (
                                        <div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <div>
                                                    <strong>
                                                        Trip progress:
                                                    </strong>{' '}
                                                    {Math.round(
                                                        (member.totalEarnings /
                                                            member.maxEarningCap) *
                                                            100,
                                                    )}
                                                    %
                                                </div>
                                                <div>
                                                    Target earnings:{' '}
                                                    <FormatCurrency>
                                                        {member.targetEarnings}
                                                    </FormatCurrency>
                                                </div>
                                            </div>
                                            <ProgressBarSimple
                                                color={
                                                    member.totalEarnings >=
                                                    member.maxEarningCap
                                                        ? 'green'
                                                        : member.totalEarnings >=
                                                          member.targetEarnings
                                                        ? 'blue'
                                                        : 'red'
                                                }
                                                percent={
                                                    member.totalEarnings > 0
                                                        ? (member.totalEarnings /
                                                              member.maxEarningCap) *
                                                          100
                                                        : 100
                                                }
                                                target={
                                                    (member.targetEarnings /
                                                        member.maxEarningCap) *
                                                    100
                                                }
                                            />
                                        </div>
                                    )}
                            </div>
                        </div>
                    )
                })}
            {isFetching &&
                data &&
                data.privilege_members &&
                data.privilege_members.cursor <
                    data.privilege_members.count && (
                    <div
                        style={{
                            position: 'relative',
                            height: '200px',
                        }}
                    >
                        <Loader />
                    </div>
                )}
        </>
    )
}

function filterToggle(filter, property, value) {
    console.log({ filter })
    const nextFilter = { ...filter }
    if (filter[property] === value) {
        delete nextFilter[property]
    } else {
        nextFilter[property] = value
    }
    return nextFilter
}
