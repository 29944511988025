import { useTranslation } from 'react-i18next'
import { FormatCurrency } from 'components/Format'

import './progressBar.scss'
import { DateTime } from 'luxon'

export default function ProgressBar({ trip, earnings }) {
    const { t } = useTranslation()

    const {
        stops,
        // extensions
    } = trip

    const maxStop = stops[stops.length - 1]

    const percentEarned = (earnings * 100) / maxStop

    let barStatus = 'unearned'
    if (earnings >= stops[0]) barStatus = 'partial'
    if (earnings >= stops[stops.length - 1]) barStatus = 'full'

    return (
        <div className="privilege__progressBarWrapper">
            <div className="privilege__progressBarTitle">
                {t('Projected trip earnings')}{' '}
                <span className="helper">
                    {t('singleDouble', 'single / double occupancy')}
                </span>
            </div>
            <div className="privilege__progressBarContainer">
                <div
                    className={`privilege__progressBar privilege__progressBar--${barStatus}`}
                    style={{
                        width: `${percentEarned > 100 ? 100 : percentEarned}%`,
                    }}
                />
                {stops.map((stop, i) => {
                    const stopPercentage = (stop * 100) / maxStop
                    return (
                        <div
                            key={i}
                            className={`progressBarStop ${
                                earnings >= stop
                                    ? 'progressBarStop--earned'
                                    : ''
                            }`}
                            style={{ left: stopPercentage + '%' }}
                        >
                            <FormatCurrency>{stop}</FormatCurrency>
                            <i className="material-icons">
                                {i === 0 ? 'person' : 'group'}
                            </i>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export function InstallerProgressBar({
    trip,
    earnings,
    earningsStartDate,
    monthsRemaining,
}) {
    const { t } = useTranslation()

    const {
        stops,
        // extensions
    } = trip

    const maxStop = stops[stops.length - 1]

    const percentEarned = (earnings * 100) / maxStop

    let barStatus = 'unearned'
    if (earnings >= stops[0]) barStatus = 'partial'
    if (earnings >= stops[stops.length - 1]) barStatus = 'full'

    const stopPercentage = (stops[0] * 100) / maxStop

    return (
        <div className="privilege__progressBarWrapper">
            <div className="privilege__progressBarTitle">
                {t('Earnings')} <FormatCurrency>{earnings}</FormatCurrency>
                {/* <span className="helper">
                    {t('singleDouble', 'single / double occupancy')}
                </span> */}
                <div style={{ display: 'flex', gap: '2rem' }}>
                    {/* <span>
                        Target: <FormatCurrency>{stops[0]}</FormatCurrency>
                    </span> */}
                    <span>
                        {t('progressBarTripCost', 'Total trip:')}{' '}
                        <FormatCurrency>{stops[1]}</FormatCurrency>
                    </span>
                </div>
            </div>
            <div className="privilege__progressBarContainer">
                <div
                    className={`privilege__progressBar privilege__progressBar--${barStatus}`}
                    style={{
                        width: `${percentEarned > 100 ? 100 : percentEarned}%`,
                    }}
                />
                <div
                    className={`progressBarStop ${
                        earnings >= stops[0] ? 'progressBarStop--earned' : ''
                    } ${
                        stopPercentage > 50
                            ? 'progressBarStop--right'
                            : 'progressBarStop--left'
                    }`}
                    style={{
                        left: stopPercentage + '%',
                        fontSize: '1.2rem',
                    }}
                >
                    {t('progressBarTarget', 'Target:')}{' '}
                    <FormatCurrency>{stops[0]}</FormatCurrency>*
                    {/* <i className="material-icons">
                                {i === 0 ? 'adjust' : 'flight'}
                            </i> */}
                </div>
            </div>
            <div
                style={{
                    marginTop: '4rem',
                    background: '#eee',
                    borderRadius: '4px',
                    padding: '2rem',
                    fontStyle: 'italic',
                }}
            >
                {t(
                    'progressBarHelperSegment1',
                    '* Based on your earnings start date',
                )}{' '}
                ({earningsStartDate.toLocaleString(DateTime.DATE_FULL)}){' '}
                {t(
                    'progressBarHelperSegment2',
                    'and the number of months remaining',
                )}{' '}
                ({Math.ceil(monthsRemaining)}){' '}
                {t(
                    'progressBarHelperSegment3',
                    'in the Privilege Passport program, your earnings should meet or exceed the current target amount.',
                )}
            </div>
        </div>
    )
}
