import { useState } from 'react'
import { useQuery } from '@apollo/client'
import Card, { CardSection } from 'components/Card'
import Formatting from 'components/Formatting'
import Installer from 'components/Installer'
import Loader from 'components/Loader'
import NoData from 'components/NoData'
import Pill from 'components/Pill'
import { GET_STAGING_RECORD } from 'operations/Staging'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router'
import Modal, { ModalClose } from 'components/Modal'
import { DateTime } from 'luxon'
import { Img } from 'components/Tueri'
import Button from 'components/Button'
import { FormatCurrency, FormatDate } from 'components/Format'
import { GET_MEMBER_RECORD } from 'operations/Members'
import validator from 'validator'
import InstallerDashboard, {
    PrivilegeBenefitLogo,
} from 'components/InstallerDashboard'
import InstallerEarnings from 'pages/Earnings'
import InstallerPage from 'pages/Installer'
import { useCampaign } from 'hooks/campaign'
import { Link } from 'react-router-dom'
import './enrollment.scss'

export default function Enrollment() {
    const { t, i18n } = useTranslation()

    const { id } = useParams()

    const location = useLocation()

    const [tab, setTab] = useState('EARNINGS')

    const [complete, setComplete] = useState(
        location.state && location.state.complete ? true : false,
    )

    const { error, loading, data } = useQuery(GET_MEMBER_RECORD, {
        variables: { id: id },
    })

    const { monthsRemaining } = useCampaign()

    if (error) return 'Error loading enrollment.'

    const member = data?.privilege_record
    const asp = member?.installer

    function parseMemberStatus(value) {
        switch (value) {
            case 'ACTIVE':
                return t('enrollmentStatusActive', 'Active')
            case 'INACTIVE':
                return t('enrollmentStatusInactive', 'Inactive')
            default:
                return t('enrollmentStatusPending', 'Pending')
        }
    }

    if (loading) return <Loader />

    console.log({ data })

    return (
        <div className="contentContainer">
            {complete && (
                <Modal>
                    <ModalClose onClick={() => setComplete()} />
                    {/* <ModalTitle> */}
                    <h2>
                        {t(
                            'Thank you for submitting your Privilege enrollment!',
                        )}
                    </h2>
                    {/* </ModalTitle> */}
                </Modal>
            )}

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <h1>{t('Customer')}</h1>
                <div className="installerBalance">
                    {t('privilegeBalance', 'Balance:')}{' '}
                    <FormatCurrency>{member.balance}</FormatCurrency>
                </div>
            </div>

            {loading && <Loader />}

            {!loading && data && !asp && (
                <NoData>{t('No enrollment to display')}</NoData>
            )}

            {!loading && data && asp && (
                <>
                    <Installer installer={asp} noClose />

                    <Card
                        style={{
                            display: 'flex',
                            gap: '2rem',
                            justifyContent: 'space-between',
                        }}
                    >
                        <CardSection title={t('Owner')}>
                            {asp.ownerName}
                            <br />
                            {validator.isEmail(asp.privilegeEmail)
                                ? asp.privilegeEmail
                                : validator.isEmail(asp.email)
                                ? asp.email
                                : 'N/A'}
                        </CardSection>

                        <CardSection title={t('Phone')}>
                            <Formatting phone>{asp.phone}</Formatting>
                        </CardSection>
                        <CardSection
                            title={t(
                                'cardHeaderPrivBenefit',
                                'Privilege benefit',
                            )}
                        >
                            {member.hasBothTrips
                                ? t('bothTrips', 'Both Trips')
                                : member.privilegeBenefit}
                        </CardSection>
                        <CardSection title={t('Sales rep')}>
                            {member.ossName}
                        </CardSection>
                        {member.pointsGoTo &&
                            member.pointsGoTo !==
                                member.privilegeNavisionId && (
                                <CardSection title={t('Points pooling')}>
                                    {
                                        member.pointsGoToAccount
                                            .installerNavisionId
                                    }
                                </CardSection>
                            )}
                        <CardSection title={t('Enrolled')}>
                            <FormatDate format="DATE_FULL">
                                {DateTime.fromMillis(member.dateReceived)}
                            </FormatDate>
                        </CardSection>
                        <CardSection title={t('Status')}>
                            <Pill
                                variant={
                                    member.status === 'ACTIVE'
                                        ? 'success'
                                        : 'rejected'
                                }
                            >
                                {parseMemberStatus(member.status)}
                            </Pill>
                        </CardSection>
                    </Card>

                    <h1>{t('Privilege benefit')}</h1>

                    <Card className="installerDashboardCard">
                        <div className="installerDashboardCard--logo">
                            <PrivilegeBenefitLogo membership={member} />
                        </div>
                        <div className="installerDashboardCard--inner">
                            <CardSection
                                title={t(
                                    'cardHeaderEarningStart',
                                    'Earnings start',
                                )}
                            >
                                {DateTime.fromMillis(
                                    member.privilegeEarningStart || 0,
                                )
                                    .setLocale(i18n.language)
                                    .toLocaleString(DateTime.DATE_FULL)}
                            </CardSection>
                            <CardSection
                                title={t(
                                    'cardHeaderEarnMonthsRemaining',
                                    'Earning months remaining',
                                )}
                            >
                                {monthsRemaining}
                            </CardSection>
                            <CardSection title={t('cardHeaderSales', 'Sales')}>
                                <FormatCurrency>
                                    {member.totalSales}
                                </FormatCurrency>
                            </CardSection>
                            <CardSection
                                title={t('cardHeaderEarnings', 'Earnings')}
                            >
                                <FormatCurrency>
                                    {member.totalEarnings}
                                </FormatCurrency>
                            </CardSection>
                            <CardSection
                                title={t(
                                    'cardHeaderRedemptions',
                                    'Redemptions',
                                )}
                            >
                                <FormatCurrency>
                                    {member.totalRedemptions}
                                </FormatCurrency>
                            </CardSection>
                            <CardSection
                                title={t('cardHeaderBalance', 'Balance')}
                            >
                                <FormatCurrency>
                                    {member.balance}
                                </FormatCurrency>
                            </CardSection>
                        </div>
                    </Card>

                    {member.privilegeNavisionId === member.pointsGoTo ? (
                        <>
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '2rem',
                                    marginBottom: '2rem',
                                    marginTop: '4rem',
                                }}
                            >
                                <button
                                    type="button"
                                    className={`tabButton ${
                                        tab && tab === 'EARNINGS'
                                            ? 'tabButton--selected'
                                            : ''
                                    }`}
                                    onClick={() => setTab('EARNINGS')}
                                >
                                    {t('Earnings')}
                                </button>
                                <button
                                    type="button"
                                    className={`tabButton ${
                                        tab && tab === 'REWARDS'
                                            ? 'tabButton--selected'
                                            : ''
                                    }`}
                                    onClick={() => setTab('REWARDS')}
                                >
                                    {t('Reward')}
                                </button>
                            </div>
                            {tab === 'EARNINGS' && (
                                <>
                                    <InstallerEarnings member={member} />
                                </>
                            )}

                            {tab === 'REWARDS' && (
                                <>
                                    <InstallerPage member={member} />
                                </>
                            )}
                        </>
                    ) : member.pointsGoToAccount ? (
                        <>
                            <h1>
                                {t('pointsPoolingMessage', `Pooling points to`)}
                            </h1>
                            {member.storeNavisionId ===
                            member.pointsGoToAccount?.storeNavisionId ? (
                                <InstallerAlternate
                                    to={`/enrollment/${
                                        member.pointsGoToAccount.isMember
                                            ? 'member'
                                            : 'staging'
                                    }/${
                                        member.pointsGoToAccount.isMember
                                            ? member.pointsGoToAccount.memberId
                                            : member.pointsGoToAccount.stagingId
                                    }`}
                                    installer={member.pointsGoToAccount}
                                    noClose
                                />
                            ) : (
                                <Installer
                                    installer={member.pointsGoToAccount}
                                    noClose
                                />
                            )}
                        </>
                    ) : null}
                </>
            )}
        </div>
    )
}

function InstallerAlternate({ to, installer }) {
    return (
        <Link to={to} className={`aspContainer`}>
            <div className="asp__businessName">
                {installer.businessName}
                {installer.installerNavisionId && (
                    <>({installer.installerNavisionId})</>
                )}
            </div>
            {installer.businessName && (
                <div className="asp__address">
                    {installer.address}, {installer.city}, {installer.province}{' '}
                    {installer.postal}{' '}
                    {installer.navisionId && `(${installer.navisionId})`}
                </div>
            )}
        </Link>
    )
}
