import './pill.scss'

export default function Pill({ children, pillType, variant, style }) {
    return (
        <div
            className={`pill ${pillType ? `pillType--${pillType}` : ''} ${
                variant ? `pillVariant--${variant}` : ''
            }`}
            style={style}
        >
            {children}
        </div>
    )
}
