import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
// import Button from '../Button'
import { useModal } from '../../hooks/modal'
import { Link } from 'react-router-dom'
import './modal.scss'

const modalRoot = document.getElementById('modal-root')

export default function Modal({ children, fullscreen }) {

    const [mounted, setMounted] = useState(false)
    
    const el = document.createElement('div')
    el.setAttribute('class', 'modalContainer')

    const ref = useRef(null)

    useEffect(() => {

        modalRoot.appendChild(el)
        setMounted(true)

        return () => {
            modalRoot.removeChild(el)
        }
    }, [el])

    if (!mounted) return null

    return ReactDOM.createPortal(
        <div ref={ref} className={`modal ${ fullscreen ? 'modal--fullscreen' : '' }`}>{ children } </div>,
        el
    )

}

export function ModalHeader({ children }) {

    return (
        <div className='modalHeader'>
            { children }
        </div>
    )

}

export function ModalTitle({ title, children }) {

    return (
        <div className='modalTitleContainer'>
            <div className='modalTitle'>{ title }</div>
            { children }
        </div>
    )

}

export function ModalClose({ onClick }) {

    const { setModal } = useModal()

    return (
        <button
            type='button'
            className='modalClose'
            onClick={(e) => {
                e.stopPropagation()
                setModal()
                onClick && onClick()
            }}
        ><i className='material-icons'>close</i></button>
    )

}

export function ModalButton({ modal: modalId, hoverText, onClick, to }) {
    const { modal, setModal } = useModal()

    if (to) return (
        <Link
            to={ to }
            className={`modalButton ${ modal ? 'modalButton--close' : 'modalButton--open' }`}
        >
            <div className='modalButton__hoverText'>{ hoverText }</div><div className='modalButton__background'><i className='material-icons'>close</i></div>
        </Link>
    )

    return (
        <button
            type='button'
            className={`modalButton ${ modal ? 'modalButton--close' : 'modalButton--open' }`}
            onClick={(e) => {
                e.stopPropagation()
                setModal(modal ? false : modalId)
                onClick && onClick()
            }}
        ><div className='modalButton__hoverText'>{ hoverText }</div><div className='modalButton__background'><i className='material-icons'>close</i></div></button>
    )

}