import { useTranslation } from 'react-i18next'

import { useQuery } from '@apollo/client'
import { GET_MAJOR_ACCOUNTS } from 'operations/MajorAccount'

import Card, { CardSection } from 'components/Card'
import { useGlobalState } from 'hooks/useGlobalState'
import { useState } from 'react'
import { useAuthentication } from 'hooks/useAuthentication'

export function MajorAccounts({ history }) {
    const { t, i18n } = useTranslation()
    const { user } = useAuthentication()

    const { data } = useQuery(GET_MAJOR_ACCOUNTS)
    const [open, setOpen] = useState(true)

    const { setMajorAccount } = useGlobalState()

    if (data && data.majorAccounts && data.majorAccounts.length > 0) {
        return (
            <>
                <h1>{t('groupsListingHeading', 'Privilege Groups')}</h1>
                {['AGENT', 'HEAD_OFFICE'].includes(user.userType) && (
                    <button type="button" onClick={() => setOpen(!open)}>
                        {open ? 'Hide groups' : 'Show groups'}
                    </button>
                )}
                {open &&
                    data.majorAccounts.map((majorAccount) => {
                        const { account, accountName } = majorAccount

                        return (
                            <div className="cardContainer" key={account}>
                                <Card
                                    onClick={() =>
                                        setMajorAccount(majorAccount)
                                    }
                                >
                                    <div
                                        className="grid"
                                        style={{
                                            gridTemplateColumns: '1fr 1fr 1fr',
                                        }}
                                    >
                                        <CardSection
                                            title={t(
                                                'groupsListingGroupNameTitle',
                                                'Group name',
                                            )}
                                        >
                                            {accountName[i18n.language]}
                                        </CardSection>

                                        {/* <CardSection title={t('Primary ASP')}>
                                        {primaryInstallerNavisionId}
                                    </CardSection> */}
                                    </div>
                                </Card>
                            </div>
                        )
                    })}
            </>
        )
    }

    return null
}
