import React, { useState, useEffect } from 'react'
import { withRouter, Link, NavLink, useLocation } from 'react-router-dom'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useAuthentication } from '../../hooks/useAuthentication'
import { useCompany } from '../../hooks/company'
import BackButton from '../BackButton'
import { useTranslation } from 'react-i18next'
import { OAUTH_GENERATE } from '../../operations/Auth'
import napaLogoEN from '../../assets/napaPrivilegeLogo_en.svg'
import napaLogoFR from '../../assets/napaPrivilegeLogo_fr.svg'
import altromLogo from '../../assets/altromPrivilegeLogo.svg'
import altromLogoFr from '../../assets/altromPrivilegeLogo_fr.svg'

import myNapaProgramsLogo from 'assets/programs/napaLogo.svg'
import myAagProgramsLogo from 'assets/programs/altromLogo.svg'

import { usePrevious } from 'hooks/previous'

import { usePrivilege } from 'hooks/privilege'
// import { useGlobalState } from '../../hooks/useGlobalState'
import './nav.scss'
import { GET_STORE } from 'operations/Store'
import { useCart } from 'hooks/cart'

function Nav({ history }) {
    const [, setPrivilege] = usePrivilege()

    const { quantity: cartQuantity } = useCart()

    const [expanded, setExpanded] = useState(false)
    const { isAuthenticated, setAuthenticated, user } = useAuthentication()
    const company = useCompany()
    const client = useApolloClient()
    const { t, i18n } = useTranslation()
    const [oauthGenerate] = useMutation(OAUTH_GENERATE)
    // const { resetGlobalState } = useGlobalState()

    const location = useLocation()
    const previousLocation = usePrevious(location)
    useEffect(() => {
        if (previousLocation && location.pathname !== previousLocation.pathname)
            setExpanded(false)
    }, [location, previousLocation])

    const { userType } = user

    const logos = {
        NAPA: {
            en: napaLogoEN,
            fr: napaLogoFR,
        },
        AAG: {
            en: altromLogo,
            fr: altromLogoFr,
        },
    }

    const logo = logos[company][i18n.language]

    if (isAuthenticated) {
        return (
            <>
                <div className="logo">
                    <BackButton />
                    <Link to="/" className="logoLink">
                        <img
                            className="logo"
                            src={logo}
                            alt={`${
                                company === 'NAPA' ? 'NAPA' : 'Altrom'
                            } Privilege`}
                        />
                    </Link>
                    <div className="navRight">
                        <Link to="/cart" className="cartButton">
                            <i className="material-icons">shopping_bag</i>
                            {cartQuantity > 0 && (
                                <div className="cartQuantity">
                                    {cartQuantity}
                                </div>
                            )}
                        </Link>
                        <button
                            className="nav__toggle"
                            onClick={() => setExpanded(!expanded)}
                        >
                            <i className="material-icons">
                                {expanded ? 'close' : 'menu'}
                            </i>
                        </button>
                    </div>
                </div>
                <nav
                    className={`nav__container ${
                        expanded ? '' : 'nav__container--hidden'
                    }`}
                >
                    <div>
                        {/* <div className='navDivider'>Navigation</div> */}
                        <ul>
                            <li>
                                <NavLink exact to="/">
                                    <i className="material-icons">home</i>
                                    {t('Home')}
                                </NavLink>
                            </li>
                            {['INSTALLER'].includes(userType) && (
                                <>
                                    <li>
                                        <NavLink to="/earnings">
                                            <i className="material-icons">
                                                loyalty
                                            </i>
                                            {t(
                                                'navASPEarningsLink',
                                                'Earnings',
                                            )}
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/redemptions">
                                            <i className="material-icons">
                                                shopping_bag
                                            </i>
                                            {t(
                                                'navRedemptionsLink',
                                                'Redemptions',
                                            )}
                                        </NavLink>
                                    </li>
                                </>
                            )}

                            {user.userType === 'STORE' &&
                                company === 'NAPA' && <StoreRegistrationLink />}
                        </ul>
                        {user.userType !== 'INSTALLER' && (
                            <>
                                <div className="navDivider">Resources</div>
                                <ul>
                                    {!['INSTALLER', 'EMPLOYEE'].includes(
                                        userType,
                                    ) && (
                                        <li>
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setPrivilege(true)
                                                    setExpanded(false)
                                                }}
                                            >
                                                <i className="material-icons">
                                                    calculate
                                                </i>
                                                {t(
                                                    'Projected Earnings Calculator',
                                                )}
                                            </button>
                                        </li>
                                    )}
                                    <li>
                                        <NavLink to="/resources">
                                            <i className="material-icons">
                                                description
                                            </i>
                                            {t('Resources')}
                                        </NavLink>
                                    </li>
                                    {/* <li><NavLink to='/frequently-asked-questions'><i className='material-icons'>help</i>{ t('Frequently Asked Questions') }</NavLink></li> */}
                                </ul>
                            </>
                        )}
                    </div>

                    <div style={{ padding: '0 2rem' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '2rem',
                            }}
                        >
                            <button
                                onClick={() => {
                                    i18n.changeLanguage(
                                        i18n.language === 'en' ? 'fr' : 'en',
                                    )
                                    setExpanded(false)
                                }}
                            >
                                <i className="material-icons">translate</i>
                                {i18n.language === 'fr'
                                    ? 'English'
                                    : 'Français'}
                            </button>
                            <button
                                onClick={() => {
                                    setAuthenticated(false)
                                    setExpanded(false)
                                    // resetGlobalState()
                                    localStorage.removeItem('accessToken')
                                    localStorage.removeItem('refreshToken')
                                    client.resetStore()
                                    history.replace('/')
                                }}
                            >
                                <i className="material-icons">logout</i>
                                {t('logout', 'Logout')}
                            </button>
                        </div>
                        <button
                            className="myProgramsButton"
                            onClick={async () => {
                                const { data } = await oauthGenerate()
                                const { oauthToken, oauthRedirect } =
                                    data.oauthGenerate
                                const uri = `${oauthRedirect}/oauth/${i18n.language}/${oauthToken}`
                                window.location.replace(uri)
                            }}
                        >
                            <div>
                                <img
                                    src={
                                        company === 'AAG'
                                            ? myAagProgramsLogo
                                            : myNapaProgramsLogo
                                    }
                                    alt="Programs Logo"
                                />
                            </div>
                            <div>
                                {company === 'NAPA'
                                    ? t('My NAPA Programs')
                                    : t('My AAG Programs')}
                            </div>
                        </button>
                    </div>
                </nav>
            </>
        )
    }

    return null
}

export default withRouter(Nav)

function StoreRegistrationLink() {
    const { t } = useTranslation()

    const { data } = useQuery(GET_STORE)

    if (data && data.store && data.store.relationshipType === 'ASSOCIATE')
        return (
            <li>
                <NavLink to="/enrollment/staging/store">
                    <i className="material-icons">card_travel</i>
                    {t('My Privilege Passport')}
                </NavLink>
            </li>
        )

    return null
}
