import React from 'react'
import { useCompany } from 'hooks/company'

import './loader.scss'
import './ball-beat.scss'

export default function Loader({ type, text }) {

    const company = useCompany()

    if (type === 'ball-beat') {
        return (
            <div className='loader loader--text'>
                <div className='loaderText'>{text}</div>
                <div className='loaderContainer'>
                    <div className='la-ball-beat'>
                        <div/>
                        <div/>
                        <div/>
                    </div>
                </div>
            </div>
        )
    }

    if (company === 'AAG') return (
        <div className='loader'>
            <svg className='loader--foreground' viewBox="0 0 50 41" xmlns="http://www.w3.org/2000/svg"><path d="M46.5788 0L49.0303 40.45H34.3212V35.547H19.6121L14.7091 40.45H0L30.6439 0H46.5788ZM33.0954 24.5151V15.9348L26.9666 24.5151H33.0954Z"/></svg>
            <svg className='loader--background' viewBox="0 0 50 41" xmlns="http://www.w3.org/2000/svg"><path d="M46.5788 0L49.0303 40.45H34.3212V35.547H19.6121L14.7091 40.45H0L30.6439 0H46.5788ZM33.0954 24.5151V15.9348L26.9666 24.5151H33.0954Z"/></svg>
        </div>
    )

    return (
        <div className='loader'>
            <svg className='loader--foreground' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1017.97 827.17"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M750,827.17H264.07a31.14,31.14,0,0,0-3.38-.78c-21.9-2.64-40.07-12.28-53.2-30-10-13.52-17.39-28.73-25.76-43.29q-34.48-59.94-69.14-119.77Q63.87,548.94,15.18,464.57C6.44,449.38.45,433.13,0,415.44c-.43-18.11,5.24-34.84,14.09-50.38,21.16-37.16,42.6-74.16,63.94-111.22q36-62.58,72.08-125.15c15.81-27.38,31.6-54.77,47.64-82C215.42,16.69,241.13.09,276.6.09q225.47,0,450.93,0c10,0,20-.44,29.91,1,23.49,3.48,42.87,14.15,55.27,34.56,18,29.72,34.64,60.31,52.44,90.19,24.19,40.61,46.13,82.51,70.67,122.94,19.07,31.43,36.83,63.65,55.33,95.43,11.75,20.18,23.71,40.23,26.82,64v9c-2.79,15.27-6.5,30.25-14.27,43.9-9.7,17.05-19.35,34.13-29.19,51.1-17.43,30-35.09,60-52.45,90.08-18.61,32.27-36.9,64.73-55.47,97-15.74,27.39-31.44,54.8-47.6,81.94-10,16.7-22.9,30.56-41.11,38.69C769,823.92,759.48,825.45,750,827.17Z"/></g></g></svg>
            <svg className='loader--background' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1017.97 827.17"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path className="cls-1" d="M750,827.17H264.07a31.14,31.14,0,0,0-3.38-.78c-21.9-2.64-40.07-12.28-53.2-30-10-13.52-17.39-28.73-25.76-43.29q-34.48-59.94-69.14-119.77Q63.87,548.94,15.18,464.57C6.44,449.38.45,433.13,0,415.44c-.43-18.11,5.24-34.84,14.09-50.38,21.16-37.16,42.6-74.16,63.94-111.22q36-62.58,72.08-125.15c15.81-27.38,31.6-54.77,47.64-82C215.42,16.69,241.13.09,276.6.09q225.47,0,450.93,0c10,0,20-.44,29.91,1,23.49,3.48,42.87,14.15,55.27,34.56,18,29.72,34.64,60.31,52.44,90.19,24.19,40.61,46.13,82.51,70.67,122.94,19.07,31.43,36.83,63.65,55.33,95.43,11.75,20.18,23.71,40.23,26.82,64v9c-2.79,15.27-6.5,30.25-14.27,43.9-9.7,17.05-19.35,34.13-29.19,51.1-17.43,30-35.09,60-52.45,90.08-18.61,32.27-36.9,64.73-55.47,97-15.74,27.39-31.44,54.8-47.6,81.94-10,16.7-22.9,30.56-41.11,38.69C769,823.92,759.48,825.45,750,827.17Z"/></g></g></svg>
        </div>
    )
}