import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'

export default function Format({
    format,
    children,
    number,
    points,
    totalPoints,
    noAbbr,
    currency,
    decimalPlaces = 2,
    className,
}) {
    const { t, i18n } = useTranslation()

    if (format === 'CURRENCY' || currency)
        return typeof children === 'number'
            ? new Intl.NumberFormat('en', {
                  style: 'currency',
                  currency: 'USD',
              })
                  .format(children)
                  .replace(/US/g, '')
            : 'N/A'

    // if (format === 'DATE_SHORT') return moment(children).format('ll')

    if (number)
        return new Intl.NumberFormat('en', {
            maximumFractionDigits: decimalPlaces,
        }).format(children)

    if (points)
        return (
            <span className={className}>
                {!noAbbr && t('pointsAbbreviation', 'Pts.')}{' '}
                {new Intl.NumberFormat(i18n.language.substr(0, 2), {
                    maximumFractionDigits: decimalPlaces,
                }).format(children)}
            </span>
        )

    if (totalPoints)
        return (
            <span>
                {t('totalPointsAbbreviation', 'Total Pts.')}{' '}
                {new Intl.NumberFormat(i18n.language.substr(0, 2), {
                    maximumFractionDigits: decimalPlaces,
                }).format(children)}
            </span>
        )

    if (!children) return null

    return children
}

export function FormatCurrency({ children, noDecimals }) {
    const { i18n } = useTranslation()
    const formattedNumber = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'USD',
    })
        .format(children)
        .replace(/US/g, '')
    if (noDecimals) return formattedNumber.substr(0, formattedNumber.length - 3)
    return formattedNumber
}

export function FormatDate({ children, format = 'DATE_MED' }) {
    const { i18n } = useTranslation()
    return children.setLocale(i18n.language).toLocaleString(DateTime[format])
}
