import gql from 'graphql-tag'

export const GET_MAJOR_ACCOUNTS = gql`
    query MajorAccounts {
        majorAccounts {
            account
            accountName {
                en
                fr
            }
        }
    }
`

export const GET_MAJOR_ACCOUNT = gql`
    query MajorAccount($account: ID!) {
        majorAccount(account: $account) {
            account
            requirePrimaryEnrollment
            primaryConfirmed
            primaryInstallerNavisionId
            primaryEnrolled
            primaryBenefitSelected
            primaryBothTripsSelected
            requireSecondaryEnrollment
            secondaryConfirmed
            secondaryInstallerNavisionId
            secondaryEnrolled
            secondaryBenefitSelected
            accountName {
                en
                fr
            }
            holdEnrollments
            benefitsAllowed
            allowBothBenefits
            benefitsConfirmed
            allowStoreEnrollments
            storeBenefitsAllowed
            allowGroupEnrollmentOverride
            primaryInstaller {
                id
                navisionId
                ownerName
                businessName
                legalName
                address
                city
                province
                language
                email
                postal
                privilegeNavisionId
            }
            secondaryInstaller {
                id
                navisionId
                ownerName
                businessName
                legalName
                address
                city
                province
                language
                email
                postal
                privilegeNavisionId
            }
        }
    }
`

export const GET_STORE_MAJOR_ACCOUNT = gql`
    query StoreMajorAccount($navisionId: ID!) {
        storeMajorAccount(navisionId: $navisionId) {
            account
            requirePrimaryEnrollment
            primaryConfirmed
            primaryInstallerNavisionId
            primaryEnrolled
            primaryBenefitSelected
            primaryBothTripsSelected
            requireSecondaryEnrollment
            secondaryConfirmed
            secondaryInstallerNavisionId
            secondaryEnrolled
            secondaryBenefitSelected
            accountName {
                en
                fr
            }
            holdEnrollments
            benefitsAllowed
            allowBothBenefits
            benefitsConfirmed
            allowStoreEnrollments
            storeBenefitsAllowed
            allowGroupEnrollmentOverride
            primaryInstaller {
                id
                navisionId
                ownerName
                businessName
                legalName
                address
                city
                province
                language
                email
                postal
                privilegeNavisionId
            }
            secondaryInstaller {
                id
                navisionId
                ownerName
                businessName
                legalName
                address
                city
                province
                language
                email
                postal
                privilegeNavisionId
            }
        }
    }
`

export const MAJOR_ACCOUNT_SET_INSTALLER_NAVISION_ID = gql`
    mutation MajorAccount_SetInstallerNavisionId(
        $account: ID!
        $type: String!
        $installerNavisionId: ID
    ) {
        majorAccount_setInstallerNavisionId(
            account: $account
            type: $type
            installerNavisionId: $installerNavisionId
        ) {
            account
            requirePrimaryEnrollment
            primaryConfirmed
            primaryInstallerNavisionId
            requireSecondaryEnrollment
            secondaryConfirmed
            secondaryInstallerNavisionId
            allowBothBenefits
            benefitsConfirmed
        }
    }
`

export const MAJOR_ACCOUNT_ENROLL_BOTH_BENEFITS_QUESTION = gql`
    mutation majorAccount_enrollBothBenefits(
        $account: ID!
        $enrollBothBenefits: Boolean
    ) {
        majorAccount_enrollBothBenefits(
            account: $account
            enrollBothBenefits: $enrollBothBenefits
        ) {
            account
            requireSecondaryEnrollment
            secondaryConfirmed
            secondaryInstallerNavisionId
            allowBothBenefits
        }
    }
`
