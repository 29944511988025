import { useQuery } from '@apollo/client'

import { GET_PRIVILEGE_PRODUCT } from 'operations/Activity'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import Loader from 'components/Loader'
import { useCompany } from 'hooks/company'
import { Img } from 'components/Tueri'
import './reward.scss'
import { useEffect, useReducer, useState } from 'react'
import { useCart } from 'hooks/cart'
import { FormatCurrency } from 'components/Format'
import currency from 'currency.js'
import { GET_INSTALLER } from 'operations/Installer'
import certificateNapaEn from 'components/CertificateImage/certificate_napa_en.svg'
import certificateNapaFr from 'components/CertificateImage/certificate_napa_fr.svg'
import certificateAltromEn from 'components/CertificateImage/certificate_altrom_en.svg'
import certificateAltromFr from 'components/CertificateImage/certificate_altrom_fr.svg'
import VisaCard from 'components/VisaCard'

const CERTIFICATE_IMAGES = {
    NAPA: {
        en: certificateNapaEn,
        fr: certificateNapaFr,
    },
    AAG: {
        en: certificateAltromEn,
        fr: certificateAltromFr,
    },
}

function quantityReducer(state, action) {
    switch (action.type) {
        case 'increment':
            return (state += 1)
        case 'decrement':
            return state > 1 ? (state += -1) : state
        default:
            throw new Error('Invalid quantity')
    }
}

export default function Reward() {
    const company = useCompany()
    const { product: slug } = useParams()

    const { t, i18n } = useTranslation()

    const { data: installerData } = useQuery(GET_INSTALLER)

    const { error, loading, data } = useQuery(GET_PRIVILEGE_PRODUCT, {
        variables: {
            slug: `${company.toLowerCase()}-${slug}-${i18n.language}`,
        },
    })

    const [quantity, setQuantity] = useReducer(quantityReducer, 1)
    const [amount, setAmount] = useState(slug === 'visa-giftcard' ? 25 : 100)

    const [focused, setIsFocused] = useState(false)

    const { total, subtotal, cart, addToCart } = useCart()

    const balance = installerData?.installer?.privilege?.member?.balance
        ? currency(installerData?.installer?.privilege?.member?.balance).value
        : 0

    const redeemableBalance = currency(balance).divide(1.06).value

    if (loading) return <Loader />

    if (error) return 'Error loading product'

    const product = data?.privilege_product ?? {}

    return (
        <div className="contentContainer">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                }}
            >
                <h1>{t('rewardHeader', 'Reward')}</h1>
                <div className="balanceAmount">
                    {t('balanceHeader', 'Balance:')}{' '}
                    <FormatCurrency>
                        {currency(balance).subtract(total).value}
                    </FormatCurrency>
                </div>
            </div>
            {data?.privilege_product && (
                <div className="productContainer">
                    <div>
                        {/* <Img
                            src={product.imageUrl.replace(
                                'https://napa-privilege.nyc3.digitaloceanspaces.com/',
                                'https://cdn.tueri.io/68719476737/napaprivilege/',
                                )}
                                alt={product.name}
                            /> */}
                        {slug === 'visa-giftcard' && (
                            <div
                                style={{
                                    position: 'relative',
                                    boxShadow: `0 1px 1px 0 rgba(60, 64, 67, 0.08),
                            0 1px 3px 1px rgba(60, 64, 67, 0.16)`,
                                    borderRadius: '20px',
                                    overflow: 'hidden',
                                }}
                            >
                                <VisaCard noAmount noMax />
                            </div>
                        )}
                        {slug === 'toolcertificate' && (
                            <div className="certificateContainer">
                                {[
                                    // 10,
                                    // 9,
                                    // 8,
                                    // 7,
                                    // 6,
                                    // 5,
                                    4, 3, 2, 1, 0,
                                ].map((num, i) => (
                                    <div
                                        className="certificate"
                                        style={{
                                            position: 'absolute',
                                            top: `${2 * i}px`,
                                            left: `${2 * i}px`,
                                            width: '100%',
                                            height: '100%',
                                            zIndex: num,
                                        }}
                                    />
                                ))}
                                <div
                                    // className="visaCard"
                                    className="certificate"
                                    style={{
                                        position: 'relative',
                                        zIndex: 10,
                                    }}
                                >
                                    {/* <CertificateImage
                                    amount={certificateBookCount * 110}
                                /> */}
                                    <img
                                        style={{
                                            width: '100%',
                                        }}
                                        src={
                                            CERTIFICATE_IMAGES[company][
                                                i18n.language
                                            ]
                                        }
                                        alt="Certificate"
                                    />
                                </div>
                                {/* <div className="quantity">
                                x{certificateBookCount}
                            </div> */}
                            </div>
                        )}
                    </div>
                    <div>
                        <h2>{product.name}</h2>
                        {slug === 'toolcertificate' && (
                            <h4>
                                {t('cartAmount', 'Amount:')}{' '}
                                <FormatCurrency>{amount}</FormatCurrency>
                            </h4>
                        )}
                        {slug === 'visa-giftcard' && (
                            <div>
                                <h4>
                                    {t('cartAmount', 'Amount:')}{' '}
                                    <FormatCurrency>{amount}</FormatCurrency>
                                </h4>

                                <div style={{ display: 'flex', gap: '1rem' }}>
                                    <VisaCardAmount
                                        value={25}
                                        amount={amount}
                                        onClick={(e) => setAmount(e)}
                                    />
                                    <VisaCardAmount
                                        value={50}
                                        amount={amount}
                                        onClick={(e) => setAmount(e)}
                                    />
                                    <VisaCardAmount
                                        value={75}
                                        amount={amount}
                                        onClick={(e) => setAmount(e)}
                                    />
                                    <VisaCardAmount
                                        value={100}
                                        amount={amount}
                                        onClick={(e) => setAmount(e)}
                                    />
                                    <VisaCardAmount
                                        value={250}
                                        amount={amount}
                                        onClick={(e) => setAmount(e)}
                                    />
                                    <VisaCardAmount
                                        value={500}
                                        amount={amount}
                                        onClick={(e) => setAmount(e)}
                                    />
                                </div>
                                <div style={{ marginTop: '1rem' }}>
                                    <label
                                        className={`visaCardAmountOther ${
                                            focused
                                                ? 'visaCardAmountOther--focus'
                                                : ''
                                        }`}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '.5rem',
                                                alignItems: 'center',
                                                height: '3rem',
                                            }}
                                        >
                                            {t(
                                                'cardAmountOptionOther',
                                                'Other',
                                            )}{' '}
                                            $
                                            <input
                                                type="number"
                                                inputMode="decimal"
                                                // value={amount}
                                                onChange={(e) =>
                                                    setAmount(e.target.value)
                                                }
                                                onFocus={() =>
                                                    setIsFocused(true)
                                                }
                                                onBlur={() =>
                                                    setIsFocused(false)
                                                }
                                                min={25}
                                                max={999}
                                            />
                                        </div>
                                    </label>
                                    {amount < 25 && (
                                        <span
                                            style={{
                                                color: 'red',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            {t(
                                                'errorMinVisaCardAmount',
                                                'Minimum $25 Visa Card',
                                            )}
                                        </span>
                                    )}
                                    {amount > 999 && (
                                        <span
                                            style={{
                                                color: 'red',
                                                marginLeft: '1rem',
                                            }}
                                        >
                                            {t(
                                                'errorMaxVisaCardAmount',
                                                'Maximum $999 Visa Card',
                                            )}
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}
                        <div>
                            <h4>{t('cartHeaderQuantity', 'Quantity:')}</h4>
                            <div className="productQuantityContainer">
                                <button
                                    type="button"
                                    className="productQuantityButton"
                                    onClick={() =>
                                        setQuantity({ type: 'decrement' })
                                    }
                                >
                                    -
                                </button>
                                <div>{quantity}</div>
                                <button
                                    type="button"
                                    className="productQuantityButton"
                                    onClick={() =>
                                        setQuantity({ type: 'increment' })
                                    }
                                >
                                    +
                                </button>
                            </div>
                        </div>
                        <div
                            className={`redeemableBalance ${
                                currency(subtotal).add(
                                    currency(amount).multiply(quantity),
                                ).value > redeemableBalance
                                    ? 'redeemableBalance--error'
                                    : ''
                            }`}
                        >
                            <h4>
                                {t('redeemableBalance', 'Redeemable balance:')}{' '}
                                <FormatCurrency>
                                    {
                                        currency(redeemableBalance).subtract(
                                            subtotal,
                                        ).value
                                    }
                                </FormatCurrency>
                                *
                            </h4>
                        </div>
                        <button
                            type="button"
                            className="addToCart"
                            disabled={
                                Number(amount) < 25 ||
                                Number(amount) > 999 ||
                                currency(subtotal).add(
                                    currency(amount).multiply(quantity),
                                ).value > redeemableBalance
                            }
                            onClick={() =>
                                addToCart({
                                    ...product,
                                    quantity: Number(quantity),
                                    amount: Number(amount),
                                })
                            }
                        >
                            {t('addToCartButton', 'Add to cart')}
                        </button>
                        <h3>{t('productDescription', 'Description:')}</h3>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: product.description,
                            }}
                        />
                    </div>
                </div>
            )}
            {/* <pre>{JSON.stringify(cart, null, 2)}</pre> */}
            {/* Total:{total} */}
            {/* {JSON.stringify(data)} */}
            {/* {JSON.stringify(cart)}
            <div></div>Total:{total} */}
            {/* {product === 'gift-card' && <></>} */}
            <div
                style={{
                    marginTop: '4rem',
                    background: '#eee',
                    borderRadius: '4px',
                    padding: '2rem',
                    fontStyle: 'italic',
                    ...(currency(subtotal).add(
                        currency(amount).multiply(quantity),
                    ).value > redeemableBalance && { color: 'red' }),
                }}
            >
                {t('rewardTermsSegment1', '* Visa gift cards and/or')} {company}{' '}
                {t(
                    'rewardTermsSegment2',
                    'Tools & Equipment certificates are subject to a 6% shipping/handling charge. The "Redeemable Balance" is your balance minus the 6% shipping/handling charge.',
                )}
            </div>
        </div>
    )
}

function VisaCardAmount({ amount, value, onClick }) {
    return (
        <label
            className={`visaCardAmountButton ${
                amount + '' === value + ''
                    ? 'visaCardAmountButton--selected'
                    : ''
            }`}
        >
            <input
                type="radio"
                onClick={() => onClick(value + '')}
                name="amount"
                checked={amount + '' === value + ''}
            />
            <FormatCurrency noDecimals>{value}</FormatCurrency>
        </label>
    )
}
