import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
// import Button from '../Button'
// import { useModal } from '../../hooks/modal'
// import { Link } from 'react-router-dom'
import './privilege.scss'
import { usePrivilege } from 'hooks/privilege'

import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

import { nanoid } from 'nanoid'

import Form, { Field, Submit } from 'components/Form'
// import Formatting from 'components/Formatting'
import { FormatCurrency, FormatDate } from 'components/Format'
// import { Img } from 'components/Tueri'
import VisaCard from 'components/VisaCard'
import giftCardImage from './assets/toolcertificate-en-transparent.png'
import { useCompany } from 'hooks/company'

import altromLogo from './assets/altromLogoWhite.svg'

import ProgressBar from 'components/ProgressBar'

const privilegeRoot = document.getElementById('privilege-root')

const PASSPORT = 'PASSPORT'
const REWARDS = 'REWARDS'

const LOGOS = {
    privilege_rectangle: {
        en: 'PAP_NPA2019006_LogoPrivilege_HOR_RGB_EN.png',
        fr: 'PAP_NPA2019006_LogoPrivilege_HOR_RGB_FR.png'
    },
    privilege_square: {
        en: 'NPA_2019_006_Logo_NAPA-Privilege_RGB_EN.jpg',
        fr: 'NPA_2019_006_Logo_NAPA-Privilege_RGB_FR.jpg'
    },
    PASSPORT: {
        en: 'NPA_2019_006_Logo_NAPA-Passport_RGB.jpg',
        fr: 'NPA_2019_006_Logo_NAPA-Passeport_F_RGB.jpg'
    },
    REWARDS: {
        en: 'NPA_2019_006_Logo_NAPA-Rewards_RGB.jpg',
        fr: 'NPA_2019_006_Logo_NAPA-Recompense_RGB.jpg'
    }
}

const ALTROM_LOGOS = {
    PASSPORT: {
        en: 'altromPassport_en.png',
        fr: 'altromPassport_fr.png'
    },
    REWARDS: {
        en: 'altromRewards_en.png',
        fr: 'altromRewards_fr.png'
    }
}

const LOGO_BASE_PATH = 'https://cdn.tueri.io/68719476737/napaprivilege/assets/logo/'


export default function PrivilegeHandler() {

    const [ privilege ] = usePrivilege()

    if (privilege) return (
        <PrivilegeModal>
            <PrivilegeSelection fieldName={ nanoid() } />
        </PrivilegeModal>
    )
    return null
}

const TRIPS = {
    'florida-2024': {
        stops: [
            6300,
            9900
        ],
    },
    'prague-vienna-2024': {
        stops: [
            9000,
            12900
        ],
        extensions: {
            budapest: {
                stops: [
                    3300,
                    4500
                ]
            }
        }
    }
}

export function PrivilegeSelection({ fieldName }) {

    const company = useCompany()

    const [ , setPrivilege ] = usePrivilege()

    const { t, i18n } = useTranslation()

    const [ benefit, setBenefit ] = useState(PASSPORT)

    const [ purchasesSet, setPurchasesSet ] = useState()
    const [ lastYearPurchases, setLastYearPurchases ] = useState()

    const language = i18n.language

    const monthlyAverage = Number(lastYearPurchases) / 12

    const EARNING_START = DateTime.fromObject({ year: 2021, month: 12, day: 1 }, { zone: 'America/Toronto' })
    const DEADLINE = DateTime.fromObject({ year: 2023, month: 11, day: 30, hour: 11, minute: 59, second: 59 }, { zone: 'America/Toronto' })
    const BACKDATE_DEADLINE = DateTime.fromObject({ year: 2022, month: 9, day: 30, hour: 11, minute: 59, second: 59 }, { zone: 'America/Toronto' })
    const CURRENT_TIME = DateTime.now()

    const isAfterTenthDayOfMonth = CURRENT_TIME.day > 10

    const earningsStartDate = 
        CURRENT_TIME > BACKDATE_DEADLINE ? (
            isAfterTenthDayOfMonth ? (
                // Set to beginning of next month
                CURRENT_TIME.plus({ months: 1 }).startOf('month') 
            ) : ( 
                // Backdate to beginning of current month
                CURRENT_TIME.startOf('month')
            )
        ) : ( 
            EARNING_START 
        )

    const { months } = DEADLINE.diff(earningsStartDate, 'months').toObject()

    const monthsRemaining = months > 24 ? 24 : Math.ceil(months)

    const passportEarnings = monthlyAverage * monthsRemaining * 0.03
    const rewardsEarnings = (monthlyAverage * monthsRemaining * 0.01) / 1.06

    return (
        <>
            <button type='button' className='privilegeOverlay__close' onClick={() => setPrivilege()}><i className='material-icons'>close</i></button>
            
            <div className='privilege__logo'>
                { company === 'NAPA' && <img src={LOGO_BASE_PATH + LOGOS['privilege_rectangle'][i18n.language]} alt='Privilege logo' /> }
                { company === 'AAG' && <img src={ altromLogo } alt='AAG Privilege'/> }
            </div>
            <section>

                <div>

                {
                    !purchasesSet  && (
                        <Form
                            onSubmit={({ enableSubmit }) => {
                                enableSubmit()
                                setPurchasesSet(true)
                            }}
                        >
                            {() => (
                                <div className='earningsCalculator__containers' style={{ maxWidth: '400px', width: '100%' }}>
                                    <h1>{ t('Projected earnings calculator') }</h1>
                                    <Field autoComplete={ fieldName } name={ fieldName } type="decimal" label={ t('Total annual purchase amount') } helper='$' noOptional onChange={(e) => setLastYearPurchases(e.target.value)} defaultValue={ lastYearPurchases } />
                                    <Submit>{ t('Calculate earnings') }</Submit>
                                </div>
                            )}
                        </Form>
                    )
                }

                {
                    lastYearPurchases && purchasesSet && (
                        <div>
                            <div style={{ gap: '4rem', margin: '6rem 0', display: 'flex', justifyContent: 'space-evenly' }}>
                                <div className='privilegePurchaseCalculatorResult'>
                                    <h2>{ t('annualPurchases', 'Annual purchases') }</h2>
                                    <FormatCurrency>{ lastYearPurchases  }</FormatCurrency>
                                </div>
                                <div className='privilegePurchaseCalculatorResult'>
                                    <h2>{ t('avgMonthlyPurchases', 'Avg. monthly purchases') }</h2>
                                    <FormatCurrency>{ parseFloat(monthlyAverage).toFixed() }</FormatCurrency>
                                </div>
                                <div className='privilegePurchaseCalculatorResult'>
                                    <h2>{ t('earningsStart', 'Earnings start') }</h2>
                                    {/* { monthsRemaining } */}
                                    { <FormatDate>{ earningsStartDate }</FormatDate> }
                                </div>
                                <div className='privilegePurchaseCalculatorResult'>
                                    <h2>{ t('qualifyingMonths', 'Qualifying months') }</h2>
                                    { monthsRemaining }
                                    {/* { startOfMonth.toLocaleString(DateTime.DATE_MED) } */}
                                </div>
                            </div>
                            <button type='button' className='privilegeCalculator__reset' onClick={() => {
                                setPurchasesSet()
                                setLastYearPurchases()
                            }}>{ t('Reset earnings calculator') }</button>
                        </div>
                    )
                }

                    <div className='privilegeBenefit__container'>
                        <h2>{ t('Select a privilege benefit') }</h2>
                        <button type='button' className={`privilege__benefitButton privilege__benefitButton--left ${ benefit === PASSPORT ? 'privilege__benefitButton--selected' : ''}`} onClick={() => setBenefit(PASSPORT)}>{ t('privilegePassport', 'Privilege Passport') }</button>
                        <button type='button' className={`privilege__benefitButton privilege__benefitButton--right ${ benefit === REWARDS ? 'privilege__benefitButton--selected' : ''}`} onClick={() => setBenefit(REWARDS)}>{ t('privilegeRewards', 'Privilege Rewards') }</button>
                    </div>

                </div>


            </section>

            {
                benefit === PASSPORT && (
                    <section className='benefit'>
                        { company === 'NAPA' && <div className='sectionHeading__image'><img src={ LOGO_BASE_PATH + LOGOS[PASSPORT][language] + '?w=200' } alt='Privilege Passport' /></div> }
                        { company === 'AAG' && <div className='sectionHeading__image'><img src={ LOGO_BASE_PATH + ALTROM_LOGOS[PASSPORT][language] + '?w=200' } alt='Privilege Passport' /></div> }
                        <div>
                            <h2>{ t('2024 Privilege Passport Program') }</h2>
                            <h3>{ t('3% of qualifying purchases') }</h3>

                            <p>{ t('Sign up for a trip to Orlando, Florida OR Prague & Vienna and EARN YOUR ENTIRE TRIP by receiving 3% of your qualifying purchases towards your travel selection!') }</p>

                            <div className='projectedEarnings'>
                                <h4 className='title'>{ t('Projected earnings') }</h4>
                                { passportEarnings ? (<FormatCurrency>{ passportEarnings }</FormatCurrency> ) : <span>{ t('Enter an annual purchase amount to see earning projections') }</span> }
                            </div>

                            <div className='privilegeTripGrid'>

                                <div className='privilegeCardContainer'>
                                    <div className='privilegeCardImage'><img src={`https://cdn.tueri.io/68719476737/napaprivilege/TRIP/florida/iStock-910999760.jpg?w=600&h=350`} alt='Orlando Florida' /></div>
                                    <div className='privilegeTripHeading'>{ t('Orlando Florida') }</div>
                                </div>

                                <div className='or'><span>or</span></div>

                                <div className='privilegeCardContainer'>
                                    <div className='privilegeCardImage'><img src={`https://cdn.tueri.io/68719476737/napaprivilege/TRIP/prague-vienna/2-Charles_Bridge_sunset.jpg?w=600&h=350`} alt='Prague Vienna' /></div>
                                    <div className='privilegeTripHeading'>{ t('Prague & Vienna') }</div>
                                </div>

                            </div>

                            <div className='privilegeTripGrid'>

                                <div>
                                    
                                    <ProgressBar trip={ TRIPS['florida-2024'] } earnings={ passportEarnings } />

                                    <div className='tripDetails'>

                                        <h3>{ t('travelDates1', 'Travel dates: February 25–March 3, 2024') }</h3>
                                        <ul>
                                            <li>{ t('roundTripAirfair', 'ROUND-TRIP ECONOMY AIRFARE(S) TO ORLANDO, FLORIDA FROM MAJOR CANADIAN GATEWAY CITIES') }</li>
                                            <li>{ t('ARRIVAL ASSISTANCE') }</li>
                                            <li>{ t('ROUND-TRIP BAGGAGE HANDLING') }</li>
                                            <li>{ t('TRANSFERS VIA DELUXE MOTORCOACH') }</li>
                                            <li>{ t('PRIVATE HOTEL CHECK-IN WITH PRE-REGISTERED ROOMS') }</li>
                                            <li>{ t('sevenNights', 'SEVEN (7) NIGHTS AT WALT DISNEY WORLD SWAN RESORT OR WALT DISNEY WORLD DOLPHIN RESORT') }</li>
                                            <li>{ t('WELCOME RECEPTION AT THE HOTEL, INCLUDING OPEN BAR AND FOOD STATIONS') }</li>
                                            <li>{ t('napaStarWarsEvent', 'NAPA EVENING AT STAR WARS: GALAXY’S EDGE AT DISNEY’S HOLLYWOOD STUDIOS, INCLUDING OPEN BAR AND FOOD STATIONS') }</li>
                                            <li>{ t('DISNEY WORLD OR UNIVERSAL STUDIOS PARK TICKET') }</li>
                                            <li>{ t('streetParty', 'NEW YORK STREET PARTY AT UNIVERSAL STUDIOS, INCLUDING OPEN BAR AND FOOD STATIONS') }</li>
                                            <li>{ t('taxesFees', 'ALL TAXES, SERVICE FEES, AND GRATUITIES') }</li>
                                            <li>{ t('exclusiveBusinessMeetings', 'EXCLUSIVE NAPA BUSINESS MEETINGS WHERE YOU’LL HEAR THE LATEST IN ADVANCED BUSINESS SOLUTIONS!') }</li>
                                            <li>{ t('tripOccupancy1', '$9,900 FOR DOUBLE OCCUPANCY, $6,300 FOR SINGLE OCCUPANCY.') }</li>
                                        </ul>

                                    </div>

                                </div>
                                
                                <div/>

                                <div>
                                    <ProgressBar trip={ TRIPS['prague-vienna-2024'] } earnings={ passportEarnings } />

                                    <div className='tripDetails'>

                                        <h3>{ t('travelDates2', 'Travel dates: May 31–June 9, June 4–13, June 8–17 and June 12–21, 2024') }</h3>
                                        <ul>
                                            <li>{ t('outboundAirfare', 'OUTBOUND ECONOMY AIRFARE(S) TO PRAGUE FROM MAJOR CANADIAN GATEWAY CITIES') }</li>
                                            <li>{ t('TRANSFERS BETWEEN THE AIRPORT AND HOTEL ON GROUP ARRIVAL DATES') }</li>
                                            <li>{ t('fourNightsDeluxe', 'FOUR (4) NIGHTS AT A DELUXE 5-STAR HOTEL: AUGUSTINE HOTEL OR CARLO IV') }</li>
                                            <li>{ t('welcomeReception', 'WELCOME RECEPTION AT THE CONVENT OF ST. AGNES, INCLUDING OPEN BAR AND FOOD STATIONS') }</li>
                                            <li>{ t('napaDay', 'NAPA DAY: PRAGUE CITY TOUR') }</li>
                                            <li>{ t('VLTAVA RIVER CRUISE WITH DINNER AND OPEN BAR') }</li>
                                            <li>{ t('exclusiveBusinessMeetings','EXCLUSIVE NAPA BUSINESS MEETINGS WHERE YOU’LL HEAR THE LATEST IN ADVANCED BUSINESS SOLUTIONS!') }</li>
                                            <li>{ t('TRANSPORTATION TO VIENNA VIA TRAIN') }</li>
                                            <li>{ t('fourNights', 'FOUR (4) NIGHTS AT A DELUXE 5-STAR HOTEL: THE RITZ-CARLTON, VIENNA OR THE IMPERIAL HOTEL') }</li>
                                            <li>{ t('museum', 'WELCOME RECEPTION AT THE MUSEUM OF FINE ARTS, INCLUDING OPEN BAR AND FOOD STATIONS') }</li>
                                            <li>{ t('napaDayVienna', 'NAPA DAY: VIENNA CITY TOUR') }</li>
                                            <li>{ t('NAPA FAREWELL GALA AT THE PALAIS LIECHTENSTEIN') }</li>
                                            <li>{ t('TRANSFER TO THE AIRPORT FOR FLIGHTS HOME ON GROUP DEPARTURE DATES OR TRANSFER TO BUDAPEST VIA DELUXE MOTORCOACH') }</li>
                                            <li>{ t('inboundFlights', 'INBOUND ECONOMY FLIGHT(S) FROM VIENNA TO MAJOR CANADIAN GATEWAY CITIES') }</li>
                                            <li>{ t('exclusiveBusinessMeetings', 'EXCLUSIVE NAPA BUSINESS MEETINGS WHERE YOU’LL HEAR THE LATEST IN ADVANCED BUSINESS SOLUTIONS!') }</li>
                                            <li>{ t('tripOccupancy2', '$12,900 FOR DOUBLE OCCUPANCY, $9,000 FOR SINGLE OCCUPANCY.') }</li>
                                        </ul>

                                    </div>

                                </div>

                            </div>

                            
                        </div>

                    </section>
                )
            }

            {
                benefit === REWARDS && (
                    <section className='benefit'>
                        
                        { company === 'NAPA' && <div className='sectionHeading__image'><img src={ LOGO_BASE_PATH + LOGOS[REWARDS][language] + '?w=200' } alt='Privilege Rewards' /></div> }
                        { company === 'AAG' && <div className='sectionHeading__image'><img src={ LOGO_BASE_PATH + ALTROM_LOGOS[REWARDS][language] + '?w=200' } alt='Privilege Passport' /></div> }

                        <div>
                            <h2>{ t('Privilege Rewards program') }</h2>
                            <h3>{ t('1% of qualifying purchases') }</h3>

                            <p>{ t('Sign up for our NAPA Privilege Rewards Program and earn 1% of your qualifying purchases for Visa gift cards or NAPA Tools & Equipment certificates!') }</p>

                            <div className='projectedEarnings'>
                                <h4 className='title'>{ t('Projected earnings') }</h4>
                                { rewardsEarnings ? (<FormatCurrency>{ rewardsEarnings }</FormatCurrency> ) : <span>{ t('Enter an annual purchase amount to see earning projections') }</span> }
                            </div>

                            <div className='privilegeTripGrid'>

                                <div><VisaCard amount={ parseFloat(rewardsEarnings || 0).toFixed(2) } /></div>
                                <div></div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                    <img src={ giftCardImage } alt='GiftCard'/>
                                    <div className='quantity'>
                                        x{ Math.floor(parseFloat(rewardsEarnings || 0).toFixed() / 10) }
                                        {/* {Math.floor((Math.floor(parseFloat(rewardsEarnings || 0).toFixed() / 10) / 10)) > 0 && <span>Bonus {Math.floor((Math.floor(parseFloat(rewardsEarnings || 0).toFixed() / 10) / 10))}</span>} */}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </section>
                )
            }

            
        </>
    )
}

function PrivilegeModal({ children }) {

    const company = useCompany()

    const [mounted, setMounted] = useState(false)
    
    const el = document.createElement('div')
    el.setAttribute('class', 'privilegeContainer')

    const ref = useRef(null)

    useEffect(() => {

        privilegeRoot.appendChild(el)
        setMounted(true)

        return () => {
            privilegeRoot.removeChild(el)
        }
    }, [el])

    useEffect(() => {
        document.body.classList.add('no-scroll')
        return () => document.body.classList.remove('no-scroll')
    })

    if (!mounted) return null

    return ReactDOM.createPortal(
        <div ref={ref} className={`privilege privilegeCompany--${ company }`}>{ children } </div>,
        el
    )

}