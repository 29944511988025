import { useQuery } from '@apollo/client'
import Card, { CardSection } from 'components/Card'
import Format from 'components/Format'
import currency from 'currency.js'
import { GET_ACCESS_LEVEL_STATS } from 'operations/Stats'
import { useTranslation } from 'react-i18next'
import './stats.scss'

export default function AccessLevelStats({ level, selector }) {
    const { t } = useTranslation()

    const { error, loading, data } = useQuery(GET_ACCESS_LEVEL_STATS, {
        variables: { level, selector },
    })

    const titles = {
        HEAD_OFFICE: t(
            'statsHeadOfficeLevelHeading',
            'National level enrollments',
        ),
        TERRITORY_SALES_MANAGER: t(
            'statsTerritoryLevelHeading',
            'Territory level enrollments',
        ),
        DC: t('statsDCLevelHeading', 'DC level enrollments'),
        STORE: t('statsStoreLevelHeading', 'Store level enrollments'),
        DIRECTOR: t('statsDirectorLevelHeading', 'Director level enrollments'),
        MAJOR_ACCOUNT: t(
            'statsMajorAccountLevelHeading',
            'Group level enrollments',
        ),
        BUSINESS_DEVELOPMENT_SPECIALIST: t(
            'statsBdsLevelHeading',
            'BDS level enrollments',
        ),
    }

    const stats = data?.privilege_accessLevelStats?.stats

    if (error || loading) return null

    console.log({ stats })

    return (
        <>
            <Card type="alternate" style={{ margin: '6rem 0' }}>
                <h1>{titles[level]}</h1>
                <div
                    style={{ display: 'flex', justifyContent: 'space-evenly' }}
                >
                    <div>
                        <CardSection
                            title={t(
                                'statsCardTotalEnrollmentsTitle',
                                'Total enrollments',
                            )}
                            style={{ textAlign: 'center' }}
                        >
                            <Format number>{stats['ALL'] || 0}</Format>
                        </CardSection>
                        <CardSection
                            title={'ASP sales'}
                            style={{ textAlign: 'center' }}
                        >
                            <Format currency>
                                {currency(stats.details?.totalSales, {
                                    fromCents: true,
                                }).value || 0}
                            </Format>
                        </CardSection>
                    </div>
                    <div>
                        <CardSection
                            title={t(
                                'statsCardPassportTitle',
                                'Privilege passport',
                            )}
                            style={{ textAlign: 'center' }}
                        >
                            <Format number>{stats['TRIP'] || 0}</Format>
                        </CardSection>
                        <CardSection
                            title={'ASP earnings'}
                            style={{ textAlign: 'center' }}
                        >
                            <Format currency>
                                {currency(stats.details?.totalEarnings, {
                                    fromCents: true,
                                }).value || 0}
                            </Format>
                        </CardSection>
                    </div>
                    <div>
                        <CardSection
                            title={t(
                                'statsCardRewardsTitle',
                                'Privilege rewards',
                            )}
                            style={{ textAlign: 'center' }}
                        >
                            <Format number>{stats['GIFT_CARD'] || 0}</Format>
                        </CardSection>
                        <CardSection
                            title={
                                <>
                                    ASP redemptions (
                                    {
                                        <Format number>
                                            {stats.details?.redemptionCount ||
                                                0}
                                        </Format>
                                    }
                                    )
                                </>
                            }
                            style={{ textAlign: 'center' }}
                        >
                            <Format currency>
                                {currency(stats.details?.totalRedemptions, {
                                    fromCents: true,
                                }).value || 0}
                            </Format>
                        </CardSection>
                    </div>
                </div>
            </Card>
        </>
    )
}
