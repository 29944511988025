import { useQuery } from '@apollo/client'
import { GET_DCS } from 'operations/DC'
import { useGlobalState } from 'hooks/useGlobalState'
import Card, { CardSection } from 'components/Card'
import NoData from 'components/NoData'
import { useAuthentication } from 'hooks/useAuthentication'
import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader'
import { MajorAccounts } from 'pages/MajorAccounts'
import AccessLevelStats from 'components/AccessLevelStats'
import { ME } from 'operations/Auth'

export default function Installers() {
    const { t } = useTranslation()

    const { user } = useAuthentication()

    // if (userType === 'DC') {
    //     queryVars.filter = {
    //         dcNum: {
    //             eq: dc.dcNum
    //         }
    //     }
    //     queryVars.limit = 200
    // }

    const { error, loading, data } = useQuery(ME)

    if (error) return `Error: ${error.message}`
    if (loading) return <Loader />

    return <DCList me={data?.me} />
}

function DCList({ me }) {
    const { t } = useTranslation()

    const { user } = useAuthentication()

    const { approvedDcList } = me

    const { error, loading, data } = useQuery(GET_DCS, {
        variables: {
            ...(user.userType === 'TERRITORY_SALES_MANAGER' && {
                filter: {
                    dcNum: {
                        in: [...approvedDcList.split(',')],
                    },
                },
            }),
        },
    })

    const { setDC } = useGlobalState()

    if (error) return `Error: ${error.message}`

    if (error || loading) return <Loader />

    const { dcs } = data

    console.log('TEST---------', user.userType, approvedDcList)

    return (
        <div className="contentContainer">
            <AccessLevelStats
                level="TERRITORY_SALES_MANAGER"
                selector={approvedDcList}
            />
            <MajorAccounts />

            <h1>{t('dcsHeading', 'NAPA DCs')}</h1>
            <div className="installer-list">
                {dcs.length > 0 ? (
                    dcs.map((dc) => {
                        const {
                            id,
                            dcNum,
                            name,
                            address,
                            city,
                            province,
                            postal,
                            privilegeStats,
                        } = dc

                        let totalEnrollments = 0
                        if (privilegeStats?.ALL)
                            totalEnrollments = privilegeStats.ALL

                        return (
                            <div className="cardContainer" key={id}>
                                <Card onClick={() => setDC(dc)}>
                                    <div
                                        className="grid"
                                        style={{
                                            gridTemplateColumns:
                                                '1fr 1fr 1fr .5fr',
                                        }}
                                    >
                                        <CardSection
                                            title={t('dcNumber', 'DC Number')}
                                        >
                                            {dcNum}
                                        </CardSection>

                                        <CardSection
                                            title={t('dcName', 'DC Name')}
                                        >
                                            {name}
                                        </CardSection>

                                        <CardSection
                                            title={t('address', 'Address')}
                                        >
                                            {address}
                                            <br />
                                            {city}, {province} {postal}
                                        </CardSection>
                                        <CardSection
                                            style={{ textAlign: 'right' }}
                                            title={t(
                                                'totalEnrollments',
                                                'Total enrollments',
                                            )}
                                        >
                                            {totalEnrollments}
                                        </CardSection>
                                    </div>
                                </Card>
                            </div>
                        )
                    })
                ) : (
                    <NoData>
                        {t(
                            'dcListErrorMsg',
                            'Sorry, we are having trouble displaying a DC list.',
                        )}
                    </NoData>
                )}
            </div>
        </div>
    )
}
