import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import Card, { CardIcon, CardSection } from 'components/Card'
import Loader from 'components/Loader'
import NoData from 'components/NoData'
import Pill from 'components/Pill'
import { useAuthentication } from 'hooks/useAuthentication'
import { useGlobalState } from 'hooks/useGlobalState'
import useInfiniteScroll from 'hooks/useInfiniteScroll'
import { GET_PREVIOUS_MEMBERS, GET_TEMP_MEMBERS } from 'operations/Members'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Autocomplete } from 'components/Form/Autocomplete'
import Form, { Field } from 'components/Form'
import Installer from 'components/Installer'
import {
    MAJOR_ACCOUNT_ENROLL_BOTH_BENEFITS_QUESTION,
    MAJOR_ACCOUNT_SET_INSTALLER_NAVISION_ID,
} from 'operations/MajorAccount'
import { useMajorAccount } from 'hooks/majorAccount'
import { useStore } from 'hooks/store'
import Button from 'components/Button'
// import { useCompany } from 'hooks/company'

const sortBy = 'businessName'
const sortDirection = 'ASC'

const DISPLAY_INSTALLERS = 'DISPLAY_INSTALLERS'
const BOTH_BENEFITS = 'BOTH_BENEFITS'
const CONFIRM_PRIMARY = 'CONFIRM_PRIMARY'
const CONFIRM_SECONDARY = 'CONFIRM_SECONDARY'

export default function PreviousInstallers() {
    const { t } = useTranslation()

    const { user } = useAuthentication()
    const { store, bds } = useGlobalState()
    const { majorAccount } = useMajorAccount()

    const limit = majorAccount?.account === 'FT' ? 150 : 50

    const [step, setStep] = useState(DISPLAY_INSTALLERS)

    let searchString = ''
    if (user.userType === 'STORE')
        searchString = `@storeNavisionId:{${user.navisionId}}`
    if (
        [
            'AGENT',
            'HEAD_OFFICE',
            'SALES_REP',
            'TERRITORY_SALES_MANAGER',
            'DC',
        ].includes(user.userType) &&
        store &&
        !majorAccount
    )
        searchString = `@storeNavisionId:{${store.navisionId}}`
    if (['DIRECTOR'].includes(user.userType) && bds) {
        searchString = `@bdsId:{${bds.id}}`
    }

    if (['BUSINESS_DEVELOPMENT_SPECIALIST'].includes(user.userType)) {
        searchString = `@bdsId:{${user.linkId}}`
    }

    const [search, setSearch] = useState(searchString)

    const [input, setInput] = useState('')

    const [isFetching, setIsFetching] = useInfiniteScroll(loadMore)

    const {
        // error,
        loading,
        data,
        fetchMore,
        refetch,
    } = useQuery(GET_PREVIOUS_MEMBERS, {
        variables: {
            filter: {
                search: input + ' ' + search,
                offset: 0,
                limit: limit,
                sortBy,
                sortDirection,
            },
        },
        notifyOnNetworkStatusChange: true,
    })

    useEffect(() => {
        if (majorAccount && majorAccount.account) {
            const searchString = `@groupName:{${majorAccount?.account
                ?.replace(/-/g, '\\-')
                .replace(/'/g, "\\'")}}`
            setSearch(searchString)
        }
    }, [majorAccount])

    useEffect(() => {
        refetch()
    }, [refetch, search, input])

    useEffect(() => {
        if (majorAccount) {
            if (
                majorAccount.allowBothBenefits &&
                !majorAccount.benefitsConfirmed
            ) {
                return setStep(BOTH_BENEFITS)
            }
            if (
                majorAccount.requirePrimaryEnrollment &&
                !majorAccount.primaryConfirmed
            ) {
                return setStep(CONFIRM_PRIMARY)
            }
            if (
                majorAccount.requireSecondaryEnrollment &&
                !majorAccount.secondaryConfirmed
            ) {
                return setStep(CONFIRM_SECONDARY)
            }
            if (
                majorAccount.requirePrimaryEnrollment &&
                majorAccount.primaryConfirmed &&
                (!majorAccount.requireSecondaryEnrollment ||
                    (majorAccount.requireSecondaryEnrollment &&
                        majorAccount.secondaryConfirmed))
            ) {
                return setStep(DISPLAY_INSTALLERS)
            }
        }
    }, [majorAccount])

    async function loadMore() {
        if (
            data &&
            data.privilege_tempMembers &&
            data.privilege_tempMembers.cursor < data.privilege_tempMembers.count
        ) {
            await fetchMore({
                variables: {
                    filter: {
                        search: input + ' ' + search,
                        offset: data.privilege_tempMembers.cursor,
                        limit: limit,
                        sortBy,
                        sortDirection,
                    },
                },
            })

            setIsFetching(false)
        }
    }

    const primaryASP =
        majorAccount &&
        data &&
        data.privilege_tempMembers &&
        data.privilege_tempMembers.results.find(
            (member) =>
                member.installerNavisionId ===
                majorAccount.primaryInstallerNavisionId,
        )

    const secondaryASP =
        majorAccount &&
        majorAccount.secondaryInstallerNavisionId &&
        data &&
        data.privilege_tempMembers &&
        data.privilege_tempMembers.results.find(
            (member) =>
                member.installerNavisionId ===
                majorAccount.secondaryInstallerNavisionId,
        )

    let allowNewEnrollment = false
    if (majorAccount) {
        if (
            !majorAccount?.requirePrimaryEnrollment &&
            !majorAccount?.requireSecondaryEnrollment
        )
            allowNewEnrollment = true
        if (!majorAccount?.requireSecondaryEnrollment) {
            if (majorAccount?.primaryEnrolled) allowNewEnrollment = true
        }
        if (
            majorAccount?.requireSecondaryEnrollment &&
            majorAccount?.secondaryEnrolled
        )
            allowNewEnrollment = true
    }

    return (
        <>
            {loading && <Loader />}
            {data &&
                data.privilege_tempMembers &&
                data.privilege_tempMembers.results.length < 1 && (
                    <NoData>
                        {t(
                            'noPreviousMembers',
                            `Looks like you didn't have any customers enrolled in the last campaign.`,
                        )}
                    </NoData>
                )}

            {step === BOTH_BENEFITS && <AskBothBenefits />}

            {step === CONFIRM_PRIMARY && <SelectPointsPooling />}

            {step === CONFIRM_SECONDARY && <SelectPointsPooling secondary />}

            {step === DISPLAY_INSTALLERS && (
                <>
                    {majorAccount && allowNewEnrollment && (
                        <Button context="primary" to="/register">
                            {t(
                                'noASP',
                                `Don't see your ASP below? Enroll a new ASP`,
                            )}
                        </Button>
                    )}
                    {primaryASP && <Member member={primaryASP} primary />}
                    {secondaryASP && <Member member={secondaryASP} secondary />}
                    <Form>
                        {() => (
                            <Field
                                name="search"
                                label={t(
                                    'aspSearchLabel',
                                    'Search for an ASP by business name, address, NT#, etc.',
                                )}
                                noOptional
                                onChange={(e) => setInput(e.target.value)}
                                defaultValue={input}
                            />
                        )}
                    </Form>
                    {data &&
                        data.privilege_tempMembers &&
                        data.privilege_tempMembers.results.map((member) => {
                            return <Member key={member.id} member={member} />
                        })}
                    {isFetching &&
                        data &&
                        data.privilege_tempMembers &&
                        data.privilege_tempMembers.cursor <
                            data.privilege_tempMembers.count && (
                            <div
                                style={{
                                    position: 'relative',
                                    height: '200px',
                                }}
                            >
                                <Loader />
                            </div>
                        )}
                </>
            )}

            {/* {majorAccount &&
            !majorAccount.primaryConfirmed &&
            majorAccount.requirePrimaryEnrollment ? (
                <SelectPrimary />
            ) : (
                <>
                    {majorAccount &&
                    majorAccount.secondaryInstallerNavisionId &&
                    !majorAccount.secondaryConfirmed ? (
                        <AskSecondaryQuestion />
                    ) : (
                        
                    )}
                </>
            )} */}
        </>
    )
}

function Member({ member, primary, secondary }) {
    const { t } = useTranslation()

    const { user } = useAuthentication()

    const { majorAccount } = useMajorAccount()
    const { storeMajorAccount } = useStore()

    const BENEFITS = {
        GIFT_CARD: t('Gift Card/Certificates'),
        TRIP: t('Trip'),
    }

    if (
        majorAccount &&
        majorAccount.primaryInstallerNavisionId ===
            member.installerNavisionId &&
        !primary
    )
        return null

    if (
        majorAccount &&
        majorAccount.secondaryInstallerNavisionId ===
            member.installerNavisionId &&
        !secondary
    )
        return null

    let isRegistered = member.isRegistered
    let isStoreRegistered = member.isRegistered
    let allowEnrollment = false
    let disallowedEnrollmentMessage = member.isGroupMember
        ? t('Group account')
        : t('Individual account')
    if (
        !member.isRegistered &&
        ['STORE', 'SALES_REP'].includes(user.userType)
    ) {
        if (
            member.isGroupMember &&
            storeMajorAccount?.allowStoreEnrollments &&
            storeMajorAccount?.account === member.groupName
        )
            allowEnrollment = true
        if (!member.isGroupMember) allowEnrollment = true
    }

    if (majorAccount && member.isGroupMember) {
        if (primary) allowEnrollment = true
        // if (secondary && majorAccount?) allowEnrollment = true
        if (!majorAccount?.primaryInstallerNavisionId) allowEnrollment = true
        if (!primary && majorAccount?.primaryEnrolled) allowEnrollment = true
        if (!primary && !majorAccount?.primaryEnrolled)
            disallowedEnrollmentMessage = t(
                'disallowedEnrollmentMsg',
                'Waiting for primary enrollment',
            )

        if (allowEnrollment === true) {
            if (!primary && !secondary) {
                if (
                    majorAccount?.secondaryInstallerNavisionId &&
                    !majorAccount?.secondaryEnrolled
                ) {
                    allowEnrollment = false
                    disallowedEnrollmentMessage = t(
                        'Waiting for secondary enrollment',
                    )
                }
            }
        }

        if (
            majorAccount?.allowGroupEnrollmentOverride &&
            !member.isGroupRegistered
        )
            isRegistered = false
    }
    if (
        !majorAccount &&
        !['STORE', 'SALES_REP', 'MAJOR_ACCOUNT'].includes(user.userType)
    )
        disallowedEnrollmentMessage = t('Not enrolled')

    allowEnrollment = false

    return (
        <Card
            style={{
                display: 'grid',
                gridTemplateColumns: '80px 1fr 1fr 1fr .5fr',
                gap: '1rem',
            }}
        >
            <CardIcon icon={member.isTrip ? 'flight' : 'card_giftcard'} />
            <CardSection title={t('Customer')}>
                {member.businessName}
                <div
                    style={{
                        marginTop: '.5rem',
                        display: 'flex',
                        gap: '.5rem',
                    }}
                >
                    {primary && (
                        <Pill variant="success">
                            {majorAccount?.secondaryInstallerNavisionId
                                ? t(
                                      'passportAccountHighlight',
                                      'Passport account',
                                  )
                                : t(
                                      'primaryAccountHighlight',
                                      'Primary account',
                                  )}
                        </Pill>
                    )}
                    {secondary && (
                        <Pill variant="secondary">
                            {t('rewardsAccountHighlight', 'Rewards account')}
                        </Pill>
                    )}
                    <Pill>
                        {member.installerNavisionId ||
                            t('enrollmentStatusPending', 'PENDING')}
                    </Pill>
                </div>
            </CardSection>
            <CardSection title={t('Address')}>
                {member.address}
                <br />
                {member.city}, {member.province}
                <br />
                {member.postal}
                {/* <strong>Phone:</strong> <Formatting phone>{ member.phone }</Formatting><br/>
                <strong>Email:</strong> { member.email } */}
            </CardSection>
            <CardSection title={<>2020/2021 {t('Privilege Benefit(s)')}</>}>
                {member.privilegeBenefit === 'TRIP'
                    ? member.tripLocations.map((tripLocation, i) => (
                          <React.Fragment key={i}>
                              {BENEFITS[member.privilegeBenefit]} {tripLocation}
                              <br />
                          </React.Fragment>
                      ))
                    : BENEFITS[member.privilegeBenefit]}
            </CardSection>
            <CardSection title={t('Status')}>
                {isRegistered ? (
                    <Pill variant="success">{t('Enrolled')}</Pill>
                ) : (
                    <>
                        {allowEnrollment ? (
                            ['STORE', 'SALES_REP'].includes(user.userType) ||
                            majorAccount ? (
                                <>
                                    <Link
                                        className="button enrollButton"
                                        to={`/register/${member.id}`}
                                    >
                                        {isStoreRegistered
                                            ? t('Change 2022-2023 enrollment')
                                            : t('Enroll in 2022-2023')}
                                    </Link>
                                </>
                            ) : (
                                <Pill>{t('Not enrolled')}</Pill>
                            )
                        ) : (
                            <Pill>{disallowedEnrollmentMessage}</Pill>
                        )}
                    </>
                )}
            </CardSection>
        </Card>
    )
}

function SelectPointsPooling({ secondary }) {
    const { t } = useTranslation()

    const { majorAccount, refetch } = useMajorAccount()

    const [ignorePreviousPointsPooling, setIgnorePreviousPointsPooling] =
        useState(false)

    const [notFound, setNotFound] = useState(false)

    const [asp, setAsp] = useState()

    const {
        // loading: aspIdLoading,
        data,
    } = useQuery(GET_TEMP_MEMBERS, {
        fetchPolicy: 'network-only',
        skip: secondary
            ? !majorAccount?.secondaryInstallerNavisionId
            : !majorAccount?.primaryInstallerNavisionId,
        variables: {
            search: `@groupName:{${majorAccount?.account
                .replace(/-/g, '\\-')
                .replace(/'/g, "\\'")}} @installerNavisionId:${
                !secondary
                    ? majorAccount?.primaryInstallerNavisionId
                    : majorAccount?.secondaryInstallerNavisionId
            }`,
        },
    })

    const [aspSearch, { loading: aspLoading, data: aspData }] = useLazyQuery(
        GET_TEMP_MEMBERS,
        { fetchPolicy: 'network-only' },
    )

    const [setInstallerNavisionId] = useMutation(
        MAJOR_ACCOUNT_SET_INSTALLER_NAVISION_ID,
    )

    if (notFound)
        return (
            <>
                {t(
                    'aspNotFoundCallNAPA',
                    'Please call 1-866-GET-NAPA to update the primary points pooling location for this group.',
                )}
            </>
        )

    if (
        !ignorePreviousPointsPooling &&
        data &&
        data.privilege_tempMemberSearch &&
        data.privilege_tempMemberSearch.length > 0 &&
        (!secondary ||
            asp ||
            (secondary &&
                data.privilege_tempMemberSearch[0].installerNavisionId !==
                    majorAccount.primaryInstallerNavisionId))
    )
        return (
            <>
                <h1>{t('Points pooling')}</h1>
                <h2>
                    {majorAccount.allowBothBenefits && (
                        <>
                            {secondary ? (
                                <>
                                    {t(
                                        'lastCampaignBothPointsPoolingRewards',
                                        'Last campaign you pooled your "Privilege Rewards" points to this location:',
                                    )}
                                </>
                            ) : (
                                <>
                                    {t(
                                        'lastCampaignBothPointsPoolingPassport',
                                        'Last campaign you pooled your "Privilege Passport" points to this location:',
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {!majorAccount.allowBothBenefits && (
                        <>
                            {t(
                                'lastCampaignSinglePointsPooling',
                                'Last campaign you pooled points to this location:',
                            )}
                        </>
                    )}
                </h2>
                <Installer
                    installer={asp ? asp : data.privilege_tempMemberSearch[0]}
                    alternate
                    noClose /*onClear={() => { setAsp(); return setAspNotFound() }} */
                />
                <h3>
                    {t(
                        'lastCampaignSinglePointsPoolingConfirmation',
                        'Would you like to pool points to this location?',
                    )}{' '}
                    {t(
                        'pointsPoolingSelectDifferentPrimary',
                        'Click "NO" to select a different points pooling location.',
                    )}
                </h3>
                <div style={{ display: 'flex', marginTop: '2rem' }}>
                    <button
                        type="button"
                        className="pointsPooling pointsPooling--no"
                        onClick={() => setIgnorePreviousPointsPooling(true)}
                    >
                        {t('pointsPoolingConfirmationNo', 'NO')}
                    </button>
                    <button
                        type="button"
                        className="pointsPooling pointsPooling--yes"
                        onClick={async () => {
                            setIgnorePreviousPointsPooling(true)
                            // setPointsPoolingAsp(asp.previousPointsGoTo)
                            await setInstallerNavisionId({
                                variables: {
                                    account: majorAccount.account,
                                    type: secondary ? 'secondary' : 'primary',
                                    installerNavisionId: asp
                                        ? asp.installerNavisionId
                                        : data.privilege_tempMemberSearch[0]
                                              .installerNavisionId,
                                },
                            })

                            refetch()
                        }}
                    >
                        {t('pointsPoolingConfirmationYes', 'YES')}
                    </button>
                </div>
            </>
        )

    return (
        <>
            <h1>{t('Points pooling')}</h1>
            <h2>
                {majorAccount.allowBothBenefits && (
                    <>
                        {secondary ? (
                            <>
                                {t(
                                    'lastCampaignBothPointsPoolingRewardsSearch',
                                    'Which location would you like to pool your "Privilege Rewards" points to?',
                                )}
                            </>
                        ) : (
                            <>
                                {t(
                                    'lastCampaignBothPointsPoolingPassportSearch',
                                    'Which location would you like to pool your "Privilege Passport" points to?',
                                )}
                            </>
                        )}
                    </>
                )}
                {!majorAccount.allowBothBenefits && (
                    <>
                        {t(
                            'lastCampaignSinglePointsPoolingSearch',
                            'Which location would you like to pool points to?',
                        )}
                    </>
                )}
            </h2>
            <Form>
                {() => (
                    <Autocomplete>
                        {({ index, setIndex, isOpen, setIsOpen }) => {
                            function selectAsp(index) {
                                setAsp(
                                    aspData.privilege_tempMemberSearch[index],
                                )
                                setIgnorePreviousPointsPooling(false)
                                refetch()

                                setIndex(index)
                                setIsOpen()
                            }

                            return (
                                <div className="autocomplete__wrapper">
                                    <Field
                                        name="asp"
                                        label={t(
                                            'Search business name, address, owner name, phone number, etc.',
                                        )}
                                        autoComplete="off"
                                        required
                                        // error={ errors.asp }
                                        errorMessage={t(
                                            'pointsPoolingSearchErrorMsg',
                                            `Which location would you like to pool points to?`,
                                        )}
                                        onFocus={() => setIsOpen(true)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter')
                                                e.preventDefault()
                                            if (
                                                aspData &&
                                                aspData.privilege_tempMemberSearch
                                            ) {
                                                if (e.key === 'ArrowDown')
                                                    setIndex((index) =>
                                                        index ===
                                                        aspData
                                                            .privilege_tempMemberSearch
                                                            .length -
                                                            1
                                                            ? aspData
                                                                  .privilege_tempMemberSearch
                                                                  .length - 1
                                                            : index + 1,
                                                    )
                                                if (e.key === 'ArrowUp')
                                                    setIndex((index) =>
                                                        index === 0
                                                            ? 0
                                                            : index - 1,
                                                    )
                                                if (e.key === 'Enter') {
                                                    // disable keypress event if a registration lock is in place
                                                    // const installer = aspData.privilege_tempMemberSearch[index]
                                                    // if (installer.registrationLock || (['STORE', 'SALES_REP'].includes(userType) && installer.isGroupMember) || (installer.isRegistered)) return false
                                                    selectAsp(index)
                                                }
                                                if (e.key !== 'Enter') {
                                                    setIsOpen(true)
                                                }
                                            }
                                        }}
                                        onChange={(e) => {
                                            // if (e.target.value.length > 3) {
                                            const search = e.target.value
                                                .split(' ')
                                                .map((i) =>
                                                    i.length > 1 ? i : '',
                                                )
                                                .join(' ')
                                                .replace(/[^a-zA-Z0-9 ]+/g, '')
                                            aspSearch({
                                                variables: {
                                                    search: `${(
                                                        search || ''
                                                    ).trim()}* @groupName:{${majorAccount.account.replace(
                                                        /-/g,
                                                        '\\-',
                                                    )}}`,
                                                },
                                            })
                                            setIndex(-1)
                                            // }
                                        }}
                                    />
                                    {(aspLoading ||
                                        (aspData &&
                                            aspData.privilege_tempMemberSearch &&
                                            isOpen)) && (
                                        <div className="autocompleteList__container">
                                            {aspLoading && (
                                                <div className="autocompleteList__loading">
                                                    <Loader
                                                        type="ball-beat"
                                                        text={t(
                                                            'autocompleteLoaderText',
                                                            'Loading customers',
                                                        )}
                                                    />
                                                </div>
                                            )}
                                            {aspData &&
                                                aspData.privilege_tempMemberSearch && (
                                                    <ul>
                                                        {aspData.privilege_tempMemberSearch.map(
                                                            (installer, i) => (
                                                                <li
                                                                    key={
                                                                        installer.id
                                                                    }
                                                                    className={`hasIcon zeroPadding ${
                                                                        secondary &&
                                                                        majorAccount?.primaryInstallerNavisionId ===
                                                                            installer.installerNavisionId
                                                                            ? 'autocomplete--disabled'
                                                                            : ''
                                                                    } ${
                                                                        index ===
                                                                        i
                                                                            ? 'selected'
                                                                            : ''
                                                                    }`}
                                                                    onClick={() => {
                                                                        setIndex(
                                                                            i,
                                                                        )
                                                                        selectAsp(
                                                                            i,
                                                                        )
                                                                    }}
                                                                >
                                                                    <div className="autocomplete__icon">
                                                                        <i className="material-icons">
                                                                            {installer.isGroupMember
                                                                                ? 'groups'
                                                                                : installer.isRegistered
                                                                                ? 'how_to_reg'
                                                                                : 'person'}
                                                                        </i>
                                                                    </div>
                                                                    <div className="autocomplete__infoContainer">
                                                                        <div className="autocomplete__info">
                                                                            {
                                                                                installer.businessName
                                                                            }
                                                                            <div className="aspAddress">
                                                                                {
                                                                                    installer.address
                                                                                }{' '}
                                                                                {
                                                                                    installer.city
                                                                                }

                                                                                ,{' '}
                                                                                {
                                                                                    installer.province
                                                                                }{' '}
                                                                                {
                                                                                    installer.postal
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="autocomplete__description">
                                                                            {secondary &&
                                                                                majorAccount?.primaryInstallerNavisionId ===
                                                                                    installer.installerNavisionId &&
                                                                                t(
                                                                                    'privilegePassportLocation',
                                                                                    'Privilege Passport Location',
                                                                                )}
                                                                            {/* {installer.isRegistered &&
                                                                                t(
                                                                                    `Enrolled`,
                                                                                )}
                                                                            {!installer.isRegistered &&
                                                                                installer.isGroupMember &&
                                                                                t(
                                                                                    `Major account`,
                                                                                )}
                                                                            {!installer.isRegistered &&
                                                                                installer.registrationLock &&
                                                                                t(
                                                                                    `In progress`,
                                                                                )} */}
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ),
                                                        )}
                                                        <li
                                                            className="notFound"
                                                            onClick={() => {
                                                                setNotFound(
                                                                    true,
                                                                )
                                                            }}
                                                        >
                                                            {t(
                                                                `Can't find your customer?`,
                                                            )}
                                                        </li>
                                                    </ul>
                                                )}
                                        </div>
                                    )}
                                </div>
                            )
                        }}
                    </Autocomplete>
                )}
            </Form>
        </>
    )
}
function AskBothBenefits() {
    const { t } = useTranslation()

    const { majorAccount, refetch } = useMajorAccount()

    const [enrollBothBenefits] = useMutation(
        MAJOR_ACCOUNT_ENROLL_BOTH_BENEFITS_QUESTION,
    )

    return (
        <>
            <h1>{t('privilegeBenefitsHeading', 'Privilege benefits')}</h1>
            <h3>
                {t(
                    'enrollBothBenefitsQuestion',
                    'Last campaign you pooled points to both "Privilege Passport" and "Privilege Rewards", would you like to do this again? Click "NO" to pool all your points to a single Privilege benefit.',
                )}
            </h3>
            <div style={{ display: 'flex' }}>
                <button
                    type="button"
                    className="pointsPooling pointsPooling--no"
                    onClick={async () => {
                        await enrollBothBenefits({
                            variables: {
                                account: majorAccount.account,
                                enrollBothBenefits: false,
                            },
                        })
                        return refetch()
                    }}
                >
                    {t('NO')}
                </button>
                <button
                    type="button"
                    className="pointsPooling pointsPooling--yes"
                    onClick={async () => {
                        await enrollBothBenefits({
                            variables: {
                                account: majorAccount.account,
                                enrollBothBenefits: true,
                            },
                        })
                        return refetch()
                    }}
                >
                    {t('YES')}
                </button>
            </div>
        </>
    )
}
