import React, { createContext, useContext, useState } from 'react'

import PrivilegeHandler from 'components/Privilege'

const PrivilegeContext = createContext()

export default function PrivilegeProvider({ children }) {

    const [state, setState] = useState()

    return (
        <PrivilegeContext.Provider
            value={[
                state,
                setState
            ]}
        >
            <PrivilegeHandler/>
            { children }
        </PrivilegeContext.Provider>
    )

}

export const usePrivilege = () => useContext(PrivilegeContext)