import React from 'react'
import { useGlobalState } from '../../hooks/useGlobalState'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './breadcrumbs.scss'

function Breadcrumbs({ history, location }) {
    const { t, i18n } = useTranslation()
    const {
        dc,
        store,
        setStore,
        bds,
        installer,
        setInstaller,
        majorAccount,
        resetGlobalState,
    } = useGlobalState()

    if (!dc && !store && !bds && !installer && !majorAccount) return null

    const allowClear =
        location.pathname.split('/')[1] === 'register' ||
        location.pathname.split('/')[1] === 'enroll'
            ? false
            : true

    return (
        <div className="contentContainer">
            <div className="breadcrumbContainer">
                {majorAccount && (
                    <div className="breadcrumb">
                        <div className="breadcrumbTitle">
                            {t('breadcrumbMajorAccount', 'Privilege Group')}
                        </div>
                        {majorAccount.accountName[i18n.language]}
                    </div>
                )}

                {dc && (
                    <div className="breadcrumb">
                        <div className="breadcrumbTitle">
                            {t('breadcrumbDC', 'DC')}: {dc.dcNum}
                        </div>
                        {dc.name}
                    </div>
                )}

                {store && (
                    <div
                        className={`breadcrumb${
                            dc ? ' breadcrumb--leftPad' : ''
                        }`}
                    >
                        {dc && <div className="breadcrumbArrow" />}
                        <div className="breadcrumbTitle">
                            {t('breadcrumbStore', 'Store')}: {store.navisionId}
                        </div>
                        {store.businessName}
                    </div>
                )}

                {bds && (
                    <div className="breadcrumb">
                        <div className="breadcrumbTitle">
                            {t('breadcrumbBds', 'BDS')}
                        </div>
                        {bds.firstName} {bds.lastName}
                    </div>
                )}

                {installer && (
                    <div
                        className={`breadcrumb${
                            store || bds ? ' breadcrumb--leftPad' : ''
                        }`}
                    >
                        {(store || bds) && <div className="breadcrumbArrow" />}
                        <div className="breadcrumbTitle">
                            {t('breadcrumbInstaller', 'Installer')}:{' '}
                            {installer.navisionId}
                        </div>
                        {installer.businessName}
                    </div>
                )}

                {allowClear && (
                    <button
                        type="button"
                        className="breadcrumbClose"
                        onClick={() => {
                            history.replace('/')
                            if (installer && store && dc) {
                                return setInstaller()
                            }
                            if (store && dc) {
                                return setStore()
                            }
                            if (installer && bds) {
                                return setInstaller()
                            }
                            resetGlobalState()
                        }}
                    >
                        <i className="material-icons">keyboard_arrow_left</i>
                    </button>
                )}
            </div>
        </div>
    )
}

export default withRouter(Breadcrumbs)
