import React, { useState, useEffect } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import Card, { CardSection } from 'components/Card'
import { DateTime } from 'luxon'
import { FormatCurrency, FormatDate } from 'components/Format'
import PointsPooling, { PointsPoolingTable } from 'components/PointsPooling'
import Loader from 'components/Loader'
import { GET_INSTALLER } from 'operations/Installer'

import { useCompany } from 'hooks/company'

import './installerEarnings.scss'
import { useQuery } from '@apollo/client'
import { GET_ACTIVITIES } from 'operations/Activity'
import { PrivilegeBenefitLogo } from 'components/InstallerDashboard'
import { useCampaign } from 'hooks/campaign'
import currency from 'currency.js'
import NoData from 'components/NoData'

export default function InstallerEarnings({ member: privilegeMember }) {
    const company = useCompany()
    const { deadline, monthsRemaining } = useCampaign()
    const location = useLocation()

    const { installerNavisionId } = useParams()

    const [sectionOpen, setSectionOpen] = useState()

    // const [installersPoolingPoints, setInstallersPoolingPoints] = useState()

    const { t, i18n } = useTranslation()

    const { error, loading, data } = useQuery(GET_ACTIVITIES, {
        skip: privilegeMember ? true : false,
    })

    useEffect(() => {
        setSectionOpen()
    }, [location.pathname])

    if (loading) return <Loader />

    const member = privilegeMember
        ? privilegeMember
        : data?.installer?.privilege?.member

    const installersPoolingPoints = [member.installerNavisionId]
    const sales = []

    const salesCompanies = new Set()

    member?.sales.map((activity) => {
        // console.log(activity)
        salesCompanies.add(activity.salesCompany)
        if (!installersPoolingPoints.includes(activity.installerNavisionId))
            installersPoolingPoints.push(activity.installerNavisionId)

        const aspIndex = installersPoolingPoints.findIndex(
            (installerNavisionId) =>
                installerNavisionId === activity.installerNavisionId,
        )
        if (!sales[aspIndex]) sales[aspIndex] = []
        sales[aspIndex].push(activity)
    })

    const isBothSalesCompanies =
        salesCompanies.has('NAPA') && salesCompanies.has('AAG')

    const isPoolingPoints = installersPoolingPoints.length > 1

    const selectedAspIndex = installerNavisionId
        ? installersPoolingPoints.findIndex(
              (currentInstallerNavisionId) =>
                  installerNavisionId === currentInstallerNavisionId,
          )
        : 0

    const sortedSales = sales[selectedAspIndex]?.sort((a, b) =>
        DateTime.fromFormat(`${a.year} ${a.month}`, 'yyyy LLLL').toMillis() >
        DateTime.fromFormat(`${b.year} ${b.month}`, 'yyyy LLLL').toMillis()
            ? -1
            : 1,
    )

    const months = []
    const salesByMonth = []
    const memberSalesCompanies = {
        NAPA: 0,
        AAG: 0,
    }
    sortedSales?.map((record) => {
        if (!months.includes(`${record.year}${record.month}`))
            months.push(`${record.year}${record.month}`)
        const monthIndex = months.findIndex(
            (month) => month === `${record.year}${record.month}`,
        )
        if (!salesByMonth[monthIndex])
            salesByMonth[monthIndex] = {
                total: {
                    month: record.month,
                    year: record.year,
                    totalPoints: 0,
                    monthlySales: 0,
                    salesCompanies: {
                        NAPA: 0,
                        AAG: 0,
                    },
                },
                details: [],
            }
        salesByMonth[monthIndex].total.totalPoints = currency(
            salesByMonth[monthIndex].total.totalPoints,
        ).add(record.totalPoints)
        salesByMonth[monthIndex].total.salesCompanies[record.salesCompany] =
            currency(
                salesByMonth[monthIndex].total.salesCompanies[
                    record.salesCompany
                ],
            ).add(record.monthlySales)
        // Add to total
        memberSalesCompanies[record.salesCompany] = currency(
            memberSalesCompanies[record.salesCompany],
        ).add(record.monthlySales)
        salesByMonth[monthIndex].total.monthlySales = currency(
            salesByMonth[monthIndex].total.monthlySales,
        ).add(record.monthlySales)
        salesByMonth[monthIndex].details.push(record)
    })

    const ntUrl = privilegeMember
        ? `enrollment/member/${privilegeMember?.id}`
        : 'earnings'

    return (
        <div className="contentContainer">
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                    }}
                >
                    <h1>{t('headerEarnings', 'Earnings')}</h1>
                    {/* {membership && !membership?.isTrip && ( */}

                    {/* NOTE: This condition is being checked to prevent duplicate balance display within individual installer views from store & higher level accounts */}
                    {ntUrl === 'earnings' && (
                        <div className="balanceAmount">
                            {t('balanceHeader', 'Balance:')}{' '}
                            <FormatCurrency>{member.balance}</FormatCurrency>
                        </div>
                    )}
                    {/* )} */}
                </div>
                {!privilegeMember && (
                    <Card className="installerDashboardCard">
                        <div className="installerDashboardCard--logo">
                            <PrivilegeBenefitLogo membership={member} />
                        </div>
                        <div className="installerDashboardCard--inner">
                            <CardSection
                                title={t(
                                    'cardHeaderEarningStart',
                                    'Earnings start',
                                )}
                            >
                                {DateTime.fromMillis(
                                    member.privilegeEarningStart || 0,
                                )
                                    .setLocale(i18n.language)
                                    .toLocaleString(DateTime.DATE_FULL)}
                            </CardSection>
                            <CardSection
                                title={t(
                                    'cardHeaderEarnMonthsRemaining',
                                    'Earning months remaining',
                                )}
                            >
                                {monthsRemaining}
                            </CardSection>
                            {isBothSalesCompanies && (
                                <>
                                    <CardSection
                                        title={t(
                                            'cardHeaderSalesNAPA',
                                            'NAPA sales',
                                        )}
                                    >
                                        <FormatCurrency>
                                            {memberSalesCompanies.NAPA}
                                        </FormatCurrency>
                                    </CardSection>
                                    <CardSection
                                        title={t(
                                            'cardHeaderSalesAAG',
                                            'AAG sales',
                                        )}
                                    >
                                        <FormatCurrency>
                                            {memberSalesCompanies.AAG}
                                        </FormatCurrency>
                                    </CardSection>
                                </>
                            )}
                            <CardSection
                                title={t('cardHeaderTotalSales', 'Total sales')}
                            >
                                <FormatCurrency>
                                    {member.totalSales}
                                </FormatCurrency>
                            </CardSection>
                            <CardSection
                                title={t(
                                    'cardHeaderTotalEarnings',
                                    'Total earnings',
                                )}
                            >
                                <FormatCurrency>
                                    {member.totalEarnings}
                                </FormatCurrency>
                            </CardSection>
                        </div>
                    </Card>
                )}

                {isPoolingPoints && (
                    <>
                        {i18n.language.substring(0, 2) === 'en' ? (
                            <h3>
                                Pooling points from{' '}
                                {installersPoolingPoints.length} accounts
                            </h3>
                        ) : (
                            <h3>
                                Compte principal accumulant les points{' '}
                                {installersPoolingPoints.length}
                            </h3>
                        )}
                        <div className="pointsPoolingToggleContainer">
                            {installersPoolingPoints?.map((asp, i) => {
                                return (
                                    <Link
                                        key={asp}
                                        to={
                                            i === 0
                                                ? `/${ntUrl}`
                                                : `/${ntUrl}/${asp}`
                                        }
                                        className={`installerPointsToggle ${
                                            selectedAspIndex === i
                                                ? 'installerPointsToggle--selected'
                                                : ''
                                        }`}
                                    >
                                        {i === 0
                                            ? t(
                                                  'ptsPoolingPrimaryLabel',
                                                  'Primary ASP',
                                              )
                                            : asp}
                                    </Link>
                                )
                            })}
                        </div>
                    </>
                )}
                {!sortedSales && (
                    <div>
                        <NoData>
                            {t('noEarningsActivity', 'No earnings to display')}
                        </NoData>
                    </div>
                )}
            </div>
            <div>
                {/* {sortedSales.map((sales, i) => {
                    return (
                        <>
                            {i === 0 ||
                            sortedSales[i - 1].year !== sales.year ? (
                                <h2>{sales.year}</h2>
                            ) : null}
                            <Card key={i}>
                                {sales.month} {sales.year} {sales.monthlySales}{' '}
                                {sales.totalPoints}
                            </Card>
                        </>
                    )
                })} */}
                {salesByMonth?.map((section, i) => {
                    // console.log(section.total)
                    return (
                        <div key={i}>
                            {i === 0 ||
                            section.total.year !==
                                salesByMonth[i - 1].total.year ? (
                                <h2>{section.total.year}</h2>
                            ) : null}
                            <Card
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: isBothSalesCompanies
                                        ? ' 1fr 1fr 1fr 1fr 1fr'
                                        : '1fr 1fr 1fr',
                                    gap: '4rem',
                                    background: '#fff',
                                }}
                            >
                                <CardSection
                                    title={t('cardHeaderMonth', 'Month')}
                                >
                                    {DateTime.fromFormat(
                                        section.total.month,
                                        'LLLL',
                                    )
                                        .setLocale(i18n.language)
                                        .toLocaleString({ month: 'long' })}
                                </CardSection>
                                {isBothSalesCompanies && (
                                    <>
                                        <CardSection
                                            title={t(
                                                'cardHeaderSalesNAPA',
                                                'NAPA Sales',
                                            )}
                                        >
                                            <FormatCurrency>
                                                {section.total.salesCompanies
                                                    ?.NAPA?.value || 0}
                                            </FormatCurrency>
                                        </CardSection>
                                        <CardSection
                                            title={t(
                                                'cardHeaderSalesAAG',
                                                'AAG Sales',
                                            )}
                                        >
                                            <FormatCurrency>
                                                {section.total.salesCompanies
                                                    ?.AAG?.value || 0}
                                            </FormatCurrency>
                                        </CardSection>
                                    </>
                                )}
                                <CardSection
                                    title={
                                        isBothSalesCompanies
                                            ? t(
                                                  'cardHeaderSalesBoth',
                                                  'Total Sales',
                                              )
                                            : t('cardHeaderSales', 'Sales')
                                    }
                                >
                                    <FormatCurrency>
                                        {section.total.monthlySales.value}
                                    </FormatCurrency>
                                </CardSection>
                                <CardSection
                                    title={t('cardHeaderEarnings', 'Earnings')}
                                >
                                    <FormatCurrency>
                                        {section.total.totalPoints.value}
                                    </FormatCurrency>
                                </CardSection>
                                {/* <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <button
                                        type="button"
                                        className="sectionOpenButton"
                                        onClick={() => {
                                            if (sectionOpen === i) {
                                                setSectionOpen()
                                            } else {
                                                setSectionOpen(i)
                                            }
                                        }}
                                    >
                                        {sectionOpen === i
                                            ? t(
                                                  'earningsToggleHideDetails',
                                                  'Hide details',
                                              )
                                            : t(
                                                  'earningsToggleShowDetails',
                                                  'Show details',
                                              )}
                                        <i
                                            className={`material-icons ${
                                                sectionOpen === i
                                                    ? 'rotate'
                                                    : ''
                                            }`}
                                        >
                                            expand_more
                                        </i>
                                    </button>
                                </div> */}
                            </Card>
                            {sectionOpen === i && (
                                <div className="subCardThing">
                                    <div className="subCardThing__grid subCardThing__header">
                                        <div>
                                            {t('subCardHeaderStore', 'Store')}
                                        </div>
                                        <div>
                                            {t(
                                                'subCardHeaderARNumber',
                                                'AR Number',
                                            )}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            {t('subCardHeaderSales', 'Sales')}
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            {t(
                                                'subCardHeaderEarnings',
                                                'Earnings',
                                            )}
                                        </div>
                                    </div>
                                    {section.details?.map((detail, i) => {
                                        return (
                                            <div
                                                key={i}
                                                className="subCardThingLine subCardThing__grid"
                                            >
                                                <div>
                                                    {detail.storeNapaNumber}
                                                </div>
                                                <div>#{detail.arNumber}</div>
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    <FormatCurrency>
                                                        {detail.monthlySales}
                                                    </FormatCurrency>
                                                </div>
                                                <div
                                                    style={{
                                                        textAlign: 'right',
                                                    }}
                                                >
                                                    <FormatCurrency>
                                                        {detail.totalPoints}
                                                    </FormatCurrency>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
