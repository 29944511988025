import { useQuery } from '@apollo/client'
import { GET_STORE_MAJOR_ACCOUNT } from 'operations/MajorAccount'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuthentication } from './useAuthentication'
import { useGlobalState } from './useGlobalState'

const StoreContext = createContext()

export default function StoreProvider({ children }) {
    const { store } = useGlobalState()
    const { user } = useAuthentication()

    const [state, setState] = useState()

    let storeNavisionId = null
    if (user.userType === 'STORE') storeNavisionId = user.navisionId
    if (store) storeNavisionId = store.navisionId

    const { data, refetch } = useQuery(GET_STORE_MAJOR_ACCOUNT, {
        variables: { navisionId: storeNavisionId },
        skip: !storeNavisionId,
    })

    useEffect(() => {
        if (data) {
            setState(data.storeMajorAccount)
        }
    }, [data])

    return (
        <StoreContext.Provider
            value={{
                storeMajorAccount: state,
                refetch: () => refetch(),
            }}
        >
            {children}
        </StoreContext.Provider>
    )
}

export function useStore() {
    return useContext(StoreContext)
}
